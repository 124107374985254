unit Auth.Service;

interface

uses
  JS;

type

  TSphinxTokenType = (sttAccess, sttId);

  TAuthService = class
  private
    FAccessPayload: TJSObject;
    FIdPayload: TJSObject;

    function DecodePayload(const AToken: string): string;
    function GetAccessPayloadObject: TJSObject;
    function GetIdPayloadObject: TJSObject;

  public
    function Authenticated: Boolean;
    function GetAccessToken: string;
    function GetClaimValue(const AName: string; const ATokenType: TSphinxTokenType = sttAccess): string;
    function GetClaimValueInt(const AName: string; const ATokenType: TSphinxTokenType = sttAccess): NativeInt;

    function IsAdministrator: Boolean;
    function IsSuperUser: Boolean;
    function UserId: string;
    function PersonId: Integer;
    function EmailAddress: string;
    function UserScope: string;
    function UserFullName: string;
    function Expiration: TDateTime;
    function TimeToLiveInMinutes: Integer;
    function TimeToLiveInSeconds: Integer;
  end;

function AuthService: TAuthService;

implementation

uses
  System.SysUtils,
  System.DateUtils,
  MainDataModule,
  SMX.Auth.Shared;

var
  _AuthService: TAuthService;

function AuthService: TAuthService;
begin
  if not Assigned(_AuthService) then
  begin
    _AuthService := TAuthService.Create;
  end;
  Result := _AuthService;
end;

{ TAuthService }

function TAuthService.Authenticated: Boolean;
begin
  Result := MainData.SphinxLogin.IsLoggedIn;
end;

function TAuthService.DecodePayload(const AToken: string): string;
begin
  if Trim(AToken) = '' then
    Exit('');
  Result := '';
{$IFDEF PAS2JS}
  asm
    var Token = AToken.split('.');
    if (Token.length = 3) {
    Result = Token[1];
    Result = atob(Result);
     }
  end;
{$ENDIF}
end;

function TAuthService.GetClaimValue(const AName: string; const ATokenType: TSphinxTokenType = sttAccess): string;
var
  Obj: TJSObject;
begin
  Result := '';
  if ATokenType = sttAccess then
    Obj := GetAccessPayloadObject
  else
    Obj := GetIdPayloadObject;

  if Obj.hasOwnProperty(AName) then
    Result := JS.toString(Obj.Properties[AName]);
end;

function TAuthService.GetClaimValueInt(const AName: string; const ATokenType: TSphinxTokenType): NativeInt;
var
  lValue: string;
begin

  lValue := GetClaimValue(AName, ATokenType);
  if lValue = '' then
     Result := 0
  else
     Result := StrToIntDef(lValue, 0);
end;

function TAuthService.GetAccessPayloadObject: TJSObject;
var
  AToken, Payload: string;
begin
  if not Assigned(FAccessPayload) then
  begin
    AToken := GetAccessToken;
    Payload := DecodePayload(AToken);
    FAccessPayload := TJSObject(TJSJSON.parse(Payload));
  end;

  Result := FAccessPayload;
end;

function TAuthService.GetAccessToken: string;
begin
  Result := MainData.SphinxLogin.AuthResult.AccessToken;
end;

function TAuthService.GetIdPayloadObject: TJSObject;
var
  AToken, Payload: string;
begin
  if not Assigned(FIdPayload) then
  begin
    AToken := MainData.SphinxLogin.AuthResult.IdToken;
    Payload := DecodePayload(AToken);
    FIdPayload := TJSObject(TJSJSON.parse(Payload));
  end;

  Result := FIdPayload;

end;

function TAuthService.UserFullName: string;
begin
  Result := GetClaimValue(CLAIM_FULLNAME);
end;

function TAuthService.UserId: string;
var
  lValue: string;
begin
  // Note this is the InternalId of the user
  lValue := GetClaimValue(CLAIM_USER_ID, TSphinxTokenType.sttId); //although this is in the access token too
  if lValue <> '' then
    Result := JS.toString(lValue)
  else
    Result := '';
end;

function TAuthService.IsAdministrator: Boolean;
var
  lLevel: string;
begin
  lLevel := GetClaimValue(CLAIM_SCOPE);
  Result := (lLevel = SCOPE_ADMIN) or (lLevel = SCOPE_SUPERUSER);
end;

function TAuthService.IsSuperUser: Boolean;
var
  lLevel: string;
begin
  lLevel := GetClaimValue(CLAIM_SCOPE);
  Result := (lLevel = SCOPE_SUPERUSER);
end;

function TAuthService.PersonId: Integer;
begin
  Result := GetClaimValueInt(CLAIM_PERSONID);
end;

function TAuthService.TimeToLiveInMinutes: Integer;
var
  ExpDate: TDateTime;
begin
  Result := 0;
  ExpDate := Expiration;
  if ExpDate < Now then
    Exit;
  Result := MinutesBetween(Now, ExpDate);
end;

function TAuthService.TimeToLiveInSeconds: Integer;
var
  ExpDate: TDateTime;
begin
  Result := 0;
  ExpDate := Expiration;
  if ExpDate < Now then
    Exit;
  Result := SecondsBetween(Now, ExpDate);
end;

function TAuthService.EmailAddress: string;
begin
  Result := GetClaimValue(CLAIM_EMAIL, TSphinxTokenType.sttId);
end;

function TAuthService.Expiration: TDateTime;
var
  ExpirationDate: TJSDate;
  Obj: TJSObject;
  Epoch: NativeInt;
begin
  if not Authenticated then
    Exit(Now);

  Epoch := GetClaimValueInt('exp');
  if Epoch = 0 then
    Exit(Now);

  ExpirationDate := TJSDate.New(Epoch * 1000);

  Result := EncodeDate(ExpirationDate.FullYear, ExpirationDate.Month + 1, ExpirationDate.Date) +
    EncodeTime(ExpirationDate.Hours, ExpirationDate.Minutes, ExpirationDate.Seconds, 0);

end;

function TAuthService.UserScope: string;
begin
  Result := GetClaimValue(CLAIM_SCOPE);
end;

end.
