Unit Cyrus.Enumerations;

Interface

{$SCOPEDENUMS ON}
{$IFNDEF PAS2JS}

Uses
  System.SysUtils,
  Aurelius.Mapping.Attributes;
{$ENDIF}

Type

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Web,App,Server')]
{$ENDIF}
  TSessionKind = (Web, App, Server);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Active,Suspended,Deleted')]
{$ENDIF}
  TBasicStatus = (Active, Suspended, Deleted);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,Contact,Primary,Secondary')]
{$ENDIF}
  TCustomerType = (None, Contact, Primary, Secondary);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Painter,NonPainter')]
{$ENDIF}
  TGuestType = (Painter, NonPainter);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString,
    'Location,Simply Sketch & Paint,Sketching & Painting,Sketching & Walking,Studio')]
{$ENDIF}
  THolidayType = (Location, SimplySketchPaint, SketchingPainting, SketchingWalking, Studio);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emInteger, '0,1,2')]
{$ENDIF}
  THolidayStatus = (NotActive, Active, Cancelled);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'PerStay, PerNight')]
{$ENDIF}
  TCostPeriod = (PerStay, PerNight);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,Watercolours,Oils,Acrylics,Pastels')]
{$ENDIF}
  TMediaType = (None, Watercolours, Oils, Acrylics, Pastels);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString,
    'Unknown,New,HolidaySelected,CustomerIdentified,WaitingList,GuestIdentified,Guest1Entered,Guest2Entered,GuestsEntered,OptionalExtrasComplete,NotChangeable,Confirmed,DepositPaid,PartPaid,PaidInFull,PaymentFailed,FullyBooked,CancelledByUser,CancelledByAdmin,HolidayCancelled,DeletedByCustomer')
    ]
{$ENDIF}
  TBookingState = (Unknown, New, HolidaySelected, CustomerIdentified, WaitingList, GuestIdentified, Guest1Entered,
    Guest2Entered, GuestsEntered, OptionalExtrasComplete, NotChangeable, Confirmed, DepositPaid, PartPaid, PaidInFull,
    PaymentFailed, FullyBooked, CancelledByUser, CancelledByAdmin, HolidayCancelled, DeletedByCustomer);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,PayPal,Revolut,BACS,Cheque')]
{$ENDIF}
  TPaymentProvider = (None, PayPal, Revolut, BACS, Cheque);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'New,Success,Failed,Cancelled')]
{$ENDIF}
  TPaymentState = (New, Success, Failed, Cancelled);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Payment,Refund')]
{$ENDIF}
  TPaymentType = (Payment, Refund);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Other,Deposit,Balance')]
{$ENDIF}
  TPaymentLevel = (Other, Deposit, Balance);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Available, UnAvailable, OnRequest')]
{$ENDIF}
  TExtraNightOption = (Available, UnAvailable, OnRequest);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emInteger, '0,1,2,3,7')]
{$ENDIF}
  TRoomType = (None, Single, Double, Twin, NonResidential);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emInteger, '0,1,2,3,4')]
{$ENDIF}
  TTransportType = (None, Car, Train, Bus, Plane);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Available,LimitedAvailability,Full')]
{$ENDIF}
  THolidayAvailability = (Available, LimitedAvailability, Full);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Painter,NPP,ExtraNights,Upgrade,Dog,Adjustment,Other')]
{$ENDIF}
  TCostItemType = (Painter, NPP, ExtraNights, Upgrade, Dog, Adjustment, Other);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Standard,Shared,SingleOccupier')]
{$ENDIF}
  TCostSubType = (Standard, Shared, SingleOccupier);

Const
  Balance_Due_Days = 90;
  //These are states that need updates to Room Allocations and Holidays. TBookingState.HolidayCancelled does not need any updates
  Cancelled_Booking_States = [TBookingState.CancelledByUser, TBookingState.CancelledByAdmin];
  All_Cancelled_Booking_States = [TBookingState.CancelledByUser, TBookingState.CancelledByAdmin, TBookingState.HolidayCancelled];
  Deleted_Booking_States = [TBookingState.DeletedByCustomer];

  Pending_Booking_States = [TBookingState.HolidaySelected, TBookingState.CustomerIdentified,
    TBookingState.GuestIdentified .. TBookingState.OptionalExtrasComplete];

  Finalised_Booking_States = [TBookingState.NotChangeable .. TBookingState.FullyBooked];

  Earliest_Save_State = TBookingState.CustomerIdentified;

  No_Chair_Holiday_Types = [THolidayType.SketchingWalking, THolidayType.Studio];

  Latest_Change_State = TBookingState.OptionalExtrasComplete;

  Booking_State: Array [TBookingState] Of String = ('Unknown', 'New', 'HolidaySelected', 'CustomerIdentified',
    'WaitingList', 'GuestIdentified', 'Guest1Entered', 'Guest2Entered', 'GuestsEntered', 'OptionalExtrasComplete',
    'NotChangeable', 'Confirmed', 'DepositPaid', 'PartPaid', 'PaidInFull', 'PaymentFailed', 'FullyBooked',
    'CancelledByUser', 'CancelledByAdmin', 'HolidayCancelled', 'DeletedByCustomer');

  Cost_Period_PerNight = 'PerNight';
  Cost_Period_PerStay = 'PerStay';
  Yes_No: Array [boolean] Of String = ('No', 'Yes');

  Cost_Item_Type: Array [TCostItemType] Of String = ('Painter', 'NPP', 'ExtraNights', 'Upgrade', 'Dog',
    'Adjustment', 'Other');
  Cost_Sub_Type: Array [TCostSubType] Of String = ('Standard', 'Shared', 'SingleOccupier');

  //TPaymentProvider = (None, PayPal, Revolut, BACS, Cheque);
  Payment_Provider: Array [TPaymentProvider] Of String = ('None', 'PayPal', 'Revolut', 'BACS', 'Cheque');
  Payment_State: Array [TPaymentState] Of String = ('New', 'Success', 'Failed', 'Cancelled');
  Payment_Type: Array [TPaymentType] Of String = ('Payment', 'Refund');
  Payment_Level: Array [TPaymentLevel] Of String = ('Other', 'Deposit', 'Balance');
  Payment_Level_Description: Array [TPaymentLevel] Of String = ('Payment', 'Deposit Payment', 'Balance Payment');

  Room_Type_Int: Array [TRoomType] Of Integer = (0, 1, 2, 3, 7);
  Room_Type_Description: Array [TRoomType] Of String = ('Unspecified', 'Single Room', 'Double Room', 'Twin Room',
    'Non Residential');

  Extra_Night_Option: Array [TExtraNightOption] Of String = ('Available', 'UnAvailable', 'OnRequest');

  Media_Types: Array [TMediaType] Of String = ('None', 'Watercolours', 'Oils', 'Acrylics', 'Pastels');
  Media_Type_File_Name: Array [TMediaType] Of String = ('None', 'WCol', 'Oil', 'Acrylic', 'Pastel');

  Transport_Type: Array [TTransportType] Of String = ('None', 'Car', 'Train', 'Bus', 'Plane');
  Transport_Type_Idx: Array [0 .. 4] Of String = ('None', 'Car', 'Train', 'Bus', 'Plane');

  guest_type_bool: Array [boolean] Of String = ('Painter', 'NonPainter');

  Guest_Type: Array [TGuestType] Of String = ('Painter', 'NonPainter');

Implementation

{$SCOPEDENUMS OFF}

End.
