Unit AccountCustomerDetails;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.ExtCtrls,
  WEBLib.Country;

Type
  TMyDetails = Class(TCoreWebForm)
    CustomerDetails: TXDataWebDataSet;
    CustomerDetailsCustomerId: TIntegerField;
    CustomerDetailsTitle: TStringField;
    CustomerDetailsFirstName: TStringField;
    CustomerDetailsMiddleName: TStringField;
    CustomerDetailsLastName: TStringField;
    CustomerDetailsHomeTelephoneNumber: TStringField;
    CustomerDetailsMobileNumber: TStringField;
    CustomerDetailsEmail: TStringField;
    CustomerDetailsAddressLine1: TStringField;
    CustomerDetailsAddressLine2: TStringField;
    CustomerDetailsAddressLine3: TStringField;
    CustomerDetailsTown: TStringField;
    CustomerDetailsCounty: TStringField;
    CustomerDetailsPostCode: TStringField;
    CustomerDetailsDetailsComplete: TBooleanField;
    CustomerDetailsFullyVaccinated: TIntegerField;
    CustomerDetailsDateOfLastVaccination: TDateTimeField;
    CustomerDetailsCountryCode: TStringField;
    CustomerDetailsDiscountId: TIntegerField;
    CustomerDetailsUserId: TStringField;
    CustomerDetailsCustomerCode: TStringField;
    CustomerDetailsCustomerType: TStringField;
    CustomerDetailsParentCustomerId: TIntegerField;
    CustomerDetailsAddressSameAsParent: TBooleanField;
    CustomerDetailsContactDetailId: TIntegerField;
    CustomerDetailsDateCreated: TDateTimeField;
    CustomerSource: TDataSource;
    LastVaccinatedPanel: TPanel;
    LastVaccinationEdit: TDBDateTimePicker;
    VaccinatedEdit: TDBLookupComboBox;
    EmailEdit: TDBEdit;
    MobileEdit: TDBEdit;
    PhoneEdit: TDBEdit;
    LastnameEdit: TDBEdit;
    FirstnameEdit: TDBEdit;
    TitleEdit: TDBEdit;
    CountryEdit: TCountryComboBox;
    PostcodeEdit: TDBEdit;
    CountyEdit: TDBEdit;
    TownEdit: TDBEdit;
    Add3Edit: TDBEdit;
    Add2Edit: TDBEdit;
    Add1Edit: TDBEdit;
    MyDetailsCancelButton: TButton;
    MyDetailsSaveButton: TButton;
    CustomerDetailsHearAboutUs: TIntegerField;
    Procedure Add1EditExit(Sender: TObject);
    Procedure CountryEditExit(Sender: TObject);
    Procedure CustomerDetailsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure CustomerDetailsAfterOpen(Dataset: TDataSet);
    Procedure CustomerSourceDataChange(Sender: TObject; Field: TField);
    Procedure CustomerSourceStateChange(Sender: TObject);
    Procedure EmailEditExit(Sender: TObject);
    Procedure FirstnameEditExit(Sender: TObject);
    Procedure LastnameEditExit(Sender: TObject);
    Procedure LastVaccinationEditChange(Sender: TObject);
    Procedure MobileEditExit(Sender: TObject);
    Procedure MyDetailsCancelButtonClick(Sender: TObject);
    Procedure MyDetailsSaveButtonClick(Sender: TObject);
    Procedure PhoneEditExit(Sender: TObject);
    Procedure PhoneEditKeyPress(Sender: TObject; Var Key: Char);
    Procedure PostcodeEditExit(Sender: TObject);
    Procedure TownEditExit(Sender: TObject);
    Procedure VaccinatedEditChange(Sender: TObject);
    Procedure VaccinatedEditExit(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
  Private
    { Private declarations }
    FMinVaccinatedDate: TDate;
    FDataChanged: Boolean;
    FLoading: Boolean;

    [async]
    Procedure HandleCancel; async;
    Function IsValid(Const HighlightControls: Boolean = True): Boolean;
  Public
    { Public declarations }
  protected procedure LoadDFMValues; override; End;

Var
  MyDetails: TMyDetails;

Implementation

{$R *.dfm}

Uses
  System.DateUtils,
  MainDataModule,
  XData.Dataset.Utils,
  SMX.CRM.Utils,
  SMX.Web.Document.Utils,
  SMX.Web.Utils;

Procedure TMyDetails.Add1EditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := Trim(Add1Edit.Text) <> '';
  TDocUtils.setControlValidity(Add1Edit.ElementID, Alt_Valid_Check[ControlValid]);
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.CountryEditExit(Sender: TObject);
Begin
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.CustomerDetailsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Begin
  MainData.CustomerDetailsUpdated := True;
  CustomerDetails.Close;
  ModalResult := mrOK;
End;

Procedure TMyDetails.CustomerDetailsAfterOpen(Dataset: TDataSet);
Begin
  LastVaccinatedPanel.Visible := CustomerDetailsFullyVaccinated.Value = 1;
  EmailEdit.ReadOnly := CustomerDetailsEmail.Value <> '';
  CountryEdit.Text := GetCountryByCode(Dataset.FieldByName('CountryCode').AsString);
  MyDetailsSaveButton.Enabled := IsValid(False);
  FDataChanged := False;
  CustomerDetails.Edit;
  FLoading := False;
End;

Procedure TMyDetails.CustomerSourceDataChange(Sender: TObject; Field: TField);
Begin
  If FLoading Then
    Exit;
  FDataChanged := True;
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.CustomerSourceStateChange(Sender: TObject);
Begin
  If CustomerDetails.State = TDataSetState.dsEdit Then
  Begin
    MyDetailsCancelButton.Caption := 'Cancel';
    MyDetailsSaveButton.Enabled := IsValid(False) And FDataChanged;
  End;
End;

Procedure TMyDetails.EmailEditExit(Sender: TObject);
Begin
  TDocUtils.setControlValidity(EmailEdit.ElementID, Alt_Valid_Check[TCRMUtils.IsEmailValid(EmailEdit.Text)]);
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.FirstnameEditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := Trim(FirstnameEdit.Text) <> '';
  TDocUtils.setControlValidity(FirstnameEdit.ElementID, Alt_Valid_Check[ControlValid]);
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.HandleCancel;
Var
  lIsValid: Boolean;
  Retval: TModalResult;
  lMessage: String;
Begin
  If MainData.IsFirstRegistration Then
  Begin
    lIsValid := IsValid(True);
    If Not lIsValid Then
    Begin
      lMessage :=
        'Are you sure that you want to cancel? Your account is not complete until your details are entered and saved';
      Retval := Await(ConfirmationRequest(lMessage, TMsgDlgType.mtConfirmation, [mbYes, mbNo]));
      If Retval = mrNo Then
        Exit;
    End
    Else
    Begin
      lMessage :=
        'Are you sure that you want to cancel and lose your changes? Your account is not complete until your details are entered and saved';
      Retval := Await(ConfirmationRequest(lMessage, TMsgDlgType.mtConfirmation, [mbYes, mbNo]));
      If Retval = mrNo Then
        Exit;
    End;
  End
  Else If FDataChanged Then
  Begin
    lMessage := 'Are you sure that you want to cancel and lose your changes?';
    Retval := Await(ConfirmationRequest(lMessage, TMsgDlgType.mtConfirmation, [mbYes, mbNo]));
    If Retval = mrNo Then
      Exit;
  End;

  If CustomerDetails.State In dsEditModes Then
    CustomerDetails.Cancel;
  CustomerDetails.Close;
  ModalResult := mrCancel;

End;

Function TMyDetails.IsValid(Const HighlightControls: Boolean): Boolean;
Var
  ControlValid: Boolean;
Begin
  Result := True;

  ControlValid := Trim(FirstnameEdit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(FirstnameEdit.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := Trim(LastnameEdit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(LastnameEdit.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := Trim(Add1Edit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(Add1Edit.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := Trim(TownEdit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(TownEdit.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := Trim(PostcodeEdit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(PostcodeEdit.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := (Trim(PhoneEdit.Text) <> '') Or (Trim(MobileEdit.Text) <> '');
  Result := Result And ControlValid;

  If Not ControlValid Then
  Begin
    If HighlightControls Then
    Begin
      TDocUtils.setControlValidity(PhoneEdit.ElementID, Alt_Valid_Check[ControlValid]);
      TDocUtils.setControlValidity(MobileEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;
  End
  Else
  Begin
    If (Trim(PhoneEdit.Text) <> '') Then
    Begin
      ControlValid := TUtils.IsPhoneNumberValid(PhoneEdit.Text);
      Result := Result And ControlValid;
      If HighlightControls Then
        TDocUtils.setControlValidity(PhoneEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;
    If (Trim(MobileEdit.Text) <> '') Then
    Begin
      ControlValid := TUtils.IsPhoneNumberValid(MobileEdit.Text);
      Result := Result And ControlValid;
      If HighlightControls Then
        TDocUtils.setControlValidity(MobileEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;
  End;

  If EmailEdit.Enabled Then
  Begin
    ControlValid := TCRMUtils.IsEmailValid(EmailEdit.Text);
    If HighlightControls Then
      TDocUtils.setControlValidity(EmailEdit.ElementID, Alt_Valid_Check[ControlValid]);
  End;

  If (VaccinatedEdit.Value = '1') Then
  Begin
    ControlValid := (LastVaccinationEdit.Date > FMinVaccinatedDate) And (LastVaccinationEdit.Date < Today);
    Result := Result And ControlValid;
    If HighlightControls Then
      TDocUtils.setControlValidity(LastVaccinationEdit.ElementID, Alt_Valid_Check[ControlValid]);
  End;
End;

Procedure TMyDetails.LastnameEditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := Trim(LastnameEdit.Text) <> '';
  TDocUtils.setControlValidity(LastnameEdit.ElementID, Alt_Valid_Check[ControlValid]);
End;

Procedure TMyDetails.LastVaccinationEditChange(Sender: TObject);
Var
  lValid: Boolean;
Begin
  lValid := (LastVaccinationEdit.Date > FMinVaccinatedDate) And (LastVaccinationEdit.Date < Today);
  TDocUtils.setControlValidity(TDBEdit(Sender).ElementID, Alt_Valid_Check[lValid]);
End;

Procedure TMyDetails.MobileEditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := TUtils.IsPhoneNumberValid(MobileEdit.Text);
  TDocUtils.setControlValidity(MobileEdit.ElementID, Alt_Valid_Check[ControlValid]);
  If ControlValid Then
    TDocUtils.setControlValidity(PhoneEdit.ElementID, TValidityState.vsNone);

  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.MyDetailsCancelButtonClick(Sender: TObject);
Begin
  HandleCancel;
End;

Procedure TMyDetails.MyDetailsSaveButtonClick(Sender: TObject);
Var
  lCountryCode: String;
Begin
  If Not IsValid(True) Then
    Exit;

  lCountryCode := GetCountryCode(CountryEdit.Text);
  If CustomerDetailsCountryCode.Value <> lCountryCode Then
  begin
     If Not(CustomerDetails.State In dsEditModes) Then
    CustomerDetails.Edit;

    CustomerDetailsCountryCode.Value := lCountryCode;
  end;

//  if not CustomerDetailsDetailsComplete.Value then
//  begin
//     If Not(CustomerDetails.State In dsEditModes) Then
//      CustomerDetails.Edit;
//     CustomerDetailsDetailsComplete.Value := True;
//  end;


  CustomerDetails.Post;

  If TXDataDSUtils.IsModified(CustomerDetails) Then
    CustomerDetails.ApplyUpdates
  Else
    ModalResult := mrCancel;
End;

Procedure TMyDetails.PhoneEditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := TUtils.IsPhoneNumberValid(PhoneEdit.Text);
  TDocUtils.setControlValidity(PhoneEdit.ElementID, Alt_Valid_Check[ControlValid]);
  If ControlValid Then
    TDocUtils.setControlValidity(MobileEdit.ElementID, TValidityState.vsNone);
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.PhoneEditKeyPress(Sender: TObject; Var Key: Char);
Begin
  If Not(Key In ['0' .. '9', #32, '+', '-']) Then
    Key := #0;
End;

Procedure TMyDetails.PostcodeEditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := Trim(PostcodeEdit.Text) <> '';
  TDocUtils.setControlValidity(PostcodeEdit.ElementID, Alt_Valid_Check[ControlValid]);
End;

Procedure TMyDetails.TownEditExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := Trim(TownEdit.Text) <> '';
  TDocUtils.setControlValidity(TownEdit.ElementID, Alt_Valid_Check[ControlValid]);
End;

Procedure TMyDetails.VaccinatedEditChange(Sender: TObject);
Begin
  LastVaccinatedPanel.Visible := CustomerDetails.FieldByName('FullyVaccinated').AsInteger = 1;
  If LastVaccinatedPanel.Visible Then
    LastVaccinationEdit.SetFocus;
End;

Procedure TMyDetails.VaccinatedEditExit(Sender: TObject);
Begin
  MyDetailsSaveButton.Enabled := IsValid(False);
End;

Procedure TMyDetails.WebFormCreate(Sender: TObject);
Begin
  Inherited;
  window.location.hash := self.ClassName.Substring(1);
  FLoading := True;
  FMinVaccinatedDate := EncodeDate(2021, 01, 01);
  VaccinatedEdit.LookupValues.AddPair('1', 'Yes');
  VaccinatedEdit.LookupValues.AddPair('0', 'No');
  LastVaccinationEdit.ElementHandle.setAttribute('min', FormatDateTime('yyyy-mm-dd',
    StartOfTheYear(IncYear(Today, -2))));
  LastVaccinationEdit.ElementHandle.setAttribute('max', FormatDateTime('yyyy-mm-dd', Today));
  CustomerDetails.QueryString := '$filter=CustomerId eq ' + MainData.CustomerId.ToString;
  CustomerDetails.Load;
End;

procedure TMyDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LastVaccinatedPanel := TPanel.Create('LastVaccinatedPanel');
  LastVaccinationEdit := TDBDateTimePicker.Create('LastVaccinationEdit');
  VaccinatedEdit := TDBLookupComboBox.Create('VaccinatedEdit');
  EmailEdit := TDBEdit.Create('EmailEdit');
  MobileEdit := TDBEdit.Create('MobileEdit');
  PhoneEdit := TDBEdit.Create('PhoneEdit');
  LastnameEdit := TDBEdit.Create('LastnameEdit');
  FirstnameEdit := TDBEdit.Create('FirstnameEdit');
  TitleEdit := TDBEdit.Create('TitleEdit');
  CountryEdit := TCountryComboBox.Create('CountryEdit');
  PostcodeEdit := TDBEdit.Create('PostcodeEdit');
  CountyEdit := TDBEdit.Create('CountyEdit');
  TownEdit := TDBEdit.Create('TownEdit');
  Add3Edit := TDBEdit.Create('Add3Edit');
  Add2Edit := TDBEdit.Create('Add2Edit');
  Add1Edit := TDBEdit.Create('Add1Edit');
  MyDetailsCancelButton := TButton.Create('MyDetailsCancelButton');
  MyDetailsSaveButton := TButton.Create('MyDetailsSaveButton');
  CustomerDetails := TXDataWebDataSet.Create(Self);
  CustomerDetailsCustomerId := TIntegerField.Create(Self);
  CustomerDetailsTitle := TStringField.Create(Self);
  CustomerDetailsFirstName := TStringField.Create(Self);
  CustomerDetailsMiddleName := TStringField.Create(Self);
  CustomerDetailsLastName := TStringField.Create(Self);
  CustomerDetailsHomeTelephoneNumber := TStringField.Create(Self);
  CustomerDetailsMobileNumber := TStringField.Create(Self);
  CustomerDetailsEmail := TStringField.Create(Self);
  CustomerDetailsAddressLine1 := TStringField.Create(Self);
  CustomerDetailsAddressLine2 := TStringField.Create(Self);
  CustomerDetailsAddressLine3 := TStringField.Create(Self);
  CustomerDetailsTown := TStringField.Create(Self);
  CustomerDetailsCounty := TStringField.Create(Self);
  CustomerDetailsPostCode := TStringField.Create(Self);
  CustomerDetailsDetailsComplete := TBooleanField.Create(Self);
  CustomerDetailsFullyVaccinated := TIntegerField.Create(Self);
  CustomerDetailsDateOfLastVaccination := TDateTimeField.Create(Self);
  CustomerDetailsCountryCode := TStringField.Create(Self);
  CustomerDetailsDiscountId := TIntegerField.Create(Self);
  CustomerDetailsUserId := TStringField.Create(Self);
  CustomerDetailsCustomerCode := TStringField.Create(Self);
  CustomerDetailsHearAboutUs := TIntegerField.Create(Self);
  CustomerDetailsCustomerType := TStringField.Create(Self);
  CustomerDetailsParentCustomerId := TIntegerField.Create(Self);
  CustomerDetailsAddressSameAsParent := TBooleanField.Create(Self);
  CustomerDetailsContactDetailId := TIntegerField.Create(Self);
  CustomerDetailsDateCreated := TDateTimeField.Create(Self);
  CustomerSource := TDataSource.Create(Self);

  LastVaccinatedPanel.BeforeLoadDFMValues;
  LastVaccinationEdit.BeforeLoadDFMValues;
  VaccinatedEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  PhoneEdit.BeforeLoadDFMValues;
  LastnameEdit.BeforeLoadDFMValues;
  FirstnameEdit.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  CountryEdit.BeforeLoadDFMValues;
  PostcodeEdit.BeforeLoadDFMValues;
  CountyEdit.BeforeLoadDFMValues;
  TownEdit.BeforeLoadDFMValues;
  Add3Edit.BeforeLoadDFMValues;
  Add2Edit.BeforeLoadDFMValues;
  Add1Edit.BeforeLoadDFMValues;
  MyDetailsCancelButton.BeforeLoadDFMValues;
  MyDetailsSaveButton.BeforeLoadDFMValues;
  CustomerDetails.BeforeLoadDFMValues;
  CustomerDetailsCustomerId.BeforeLoadDFMValues;
  CustomerDetailsTitle.BeforeLoadDFMValues;
  CustomerDetailsFirstName.BeforeLoadDFMValues;
  CustomerDetailsMiddleName.BeforeLoadDFMValues;
  CustomerDetailsLastName.BeforeLoadDFMValues;
  CustomerDetailsHomeTelephoneNumber.BeforeLoadDFMValues;
  CustomerDetailsMobileNumber.BeforeLoadDFMValues;
  CustomerDetailsEmail.BeforeLoadDFMValues;
  CustomerDetailsAddressLine1.BeforeLoadDFMValues;
  CustomerDetailsAddressLine2.BeforeLoadDFMValues;
  CustomerDetailsAddressLine3.BeforeLoadDFMValues;
  CustomerDetailsTown.BeforeLoadDFMValues;
  CustomerDetailsCounty.BeforeLoadDFMValues;
  CustomerDetailsPostCode.BeforeLoadDFMValues;
  CustomerDetailsDetailsComplete.BeforeLoadDFMValues;
  CustomerDetailsFullyVaccinated.BeforeLoadDFMValues;
  CustomerDetailsDateOfLastVaccination.BeforeLoadDFMValues;
  CustomerDetailsCountryCode.BeforeLoadDFMValues;
  CustomerDetailsDiscountId.BeforeLoadDFMValues;
  CustomerDetailsUserId.BeforeLoadDFMValues;
  CustomerDetailsCustomerCode.BeforeLoadDFMValues;
  CustomerDetailsHearAboutUs.BeforeLoadDFMValues;
  CustomerDetailsCustomerType.BeforeLoadDFMValues;
  CustomerDetailsParentCustomerId.BeforeLoadDFMValues;
  CustomerDetailsAddressSameAsParent.BeforeLoadDFMValues;
  CustomerDetailsContactDetailId.BeforeLoadDFMValues;
  CustomerDetailsDateCreated.BeforeLoadDFMValues;
  CustomerSource.BeforeLoadDFMValues;
  try
    Height := 708;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    LastVaccinatedPanel.SetParentComponent(Self);
    LastVaccinatedPanel.Name := 'LastVaccinatedPanel';
    LastVaccinatedPanel.Left := 72;
    LastVaccinatedPanel.Top := 458;
    LastVaccinatedPanel.Width := 289;
    LastVaccinatedPanel.Height := 60;
    LastVaccinatedPanel.ElementClassName := 'card';
    LastVaccinatedPanel.HeightStyle := ssAuto;
    LastVaccinatedPanel.WidthStyle := ssAuto;
    LastVaccinatedPanel.BorderColor := clNone;
    LastVaccinatedPanel.BorderStyle := bsNone;
    LastVaccinatedPanel.ChildOrder := 13;
    LastVaccinatedPanel.Color := clWindow;
    LastVaccinatedPanel.ElementBodyClassName := 'card-body';
    LastVaccinatedPanel.ElementFont := efCSS;
    LastVaccinatedPanel.ElementPosition := epIgnore;
    LastVaccinatedPanel.TabOrder := 0;
    LastVaccinatedPanel.Visible := False;
    LastVaccinationEdit.SetParentComponent(LastVaccinatedPanel);
    LastVaccinationEdit.Name := 'LastVaccinationEdit';
    LastVaccinationEdit.Left := 39;
    LastVaccinationEdit.Top := 14;
    LastVaccinationEdit.Width := 170;
    LastVaccinationEdit.Height := 22;
    LastVaccinationEdit.ElementClassName := 'form-control';
    LastVaccinationEdit.HeightStyle := ssAuto;
    LastVaccinationEdit.WidthStyle := ssAuto;
    LastVaccinationEdit.BorderStyle := bsSingle;
    LastVaccinationEdit.ChildOrder := 15;
    LastVaccinationEdit.Color := clWhite;
    LastVaccinationEdit.Date := 45408.701627916660000000;
    LastVaccinationEdit.ElementFont := efCSS;
    LastVaccinationEdit.ElementPosition := epIgnore;
    LastVaccinationEdit.Role := '';
    LastVaccinationEdit.TabOrder := 15;
    LastVaccinationEdit.Text := '';
    SetEvent(LastVaccinationEdit, Self, 'OnChange', 'LastVaccinationEditChange');
    LastVaccinationEdit.DataField := 'DateOfLastVaccination';
    LastVaccinationEdit.DataSource := CustomerSource;
    VaccinatedEdit.SetParentComponent(Self);
    VaccinatedEdit.Name := 'VaccinatedEdit';
    VaccinatedEdit.Left := 72;
    VaccinatedEdit.Top := 430;
    VaccinatedEdit.Width := 145;
    VaccinatedEdit.Height := 22;
    VaccinatedEdit.ChildOrder := 14;
    VaccinatedEdit.ElementClassName := 'form-select';
    VaccinatedEdit.ElementFont := efCSS;
    VaccinatedEdit.ElementPosition := epIgnore;
    VaccinatedEdit.HeightPercent := 100.000000000000000000;
    VaccinatedEdit.TabOrder := 8;
    VaccinatedEdit.WidthPercent := 100.000000000000000000;
    SetEvent(VaccinatedEdit, Self, 'OnChange', 'VaccinatedEditChange');
    SetEvent(VaccinatedEdit, Self, 'OnExit', 'VaccinatedEditExit');
    VaccinatedEdit.DataField := 'FullyVaccinated';
    VaccinatedEdit.DataSource := CustomerSource;
    VaccinatedEdit.ListSource := MainData.DummyLookUpSource;
    EmailEdit.SetParentComponent(Self);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 72;
    EmailEdit.Top := 402;
    EmailEdit.Width := 121;
    EmailEdit.Height := 22;
    EmailEdit.CharCase := wecLowerCase;
    EmailEdit.ChildOrder := 2;
    EmailEdit.ElementClassName := 'form-control';
    EmailEdit.ElementFont := efCSS;
    EmailEdit.ElementPosition := epIgnore;
    EmailEdit.HeightStyle := ssAuto;
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.TabOrder := 7;
    EmailEdit.Text := 'EmailEdit';
    EmailEdit.WidthStyle := ssAuto;
    EmailEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailEdit, Self, 'OnExit', 'EmailEditExit');
    EmailEdit.DataField := 'Email';
    EmailEdit.DataSource := CustomerSource;
    MobileEdit.SetParentComponent(Self);
    MobileEdit.Name := 'MobileEdit';
    MobileEdit.Left := 72;
    MobileEdit.Top := 374;
    MobileEdit.Width := 121;
    MobileEdit.Height := 22;
    MobileEdit.ChildOrder := 3;
    MobileEdit.ElementClassName := 'form-control';
    MobileEdit.ElementFont := efCSS;
    MobileEdit.ElementPosition := epIgnore;
    MobileEdit.HeightStyle := ssAuto;
    MobileEdit.HeightPercent := 100.000000000000000000;
    MobileEdit.MaxLength := 25;
    MobileEdit.TabOrder := 5;
    MobileEdit.Text := 'MobileEdit';
    MobileEdit.WidthStyle := ssAuto;
    MobileEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MobileEdit, Self, 'OnExit', 'MobileEditExit');
    MobileEdit.DataField := 'MobileNumber';
    MobileEdit.DataSource := CustomerSource;
    PhoneEdit.SetParentComponent(Self);
    PhoneEdit.Name := 'PhoneEdit';
    PhoneEdit.Left := 72;
    PhoneEdit.Top := 349;
    PhoneEdit.Width := 121;
    PhoneEdit.Height := 22;
    PhoneEdit.ChildOrder := 4;
    PhoneEdit.ElementClassName := 'form-control';
    PhoneEdit.ElementFont := efCSS;
    PhoneEdit.ElementPosition := epIgnore;
    PhoneEdit.HeightStyle := ssAuto;
    PhoneEdit.HeightPercent := 100.000000000000000000;
    PhoneEdit.MaxLength := 25;
    PhoneEdit.TabOrder := 5;
    PhoneEdit.Text := 'PhoneEdit';
    PhoneEdit.WidthStyle := ssAuto;
    PhoneEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PhoneEdit, Self, 'OnExit', 'PhoneEditExit');
    SetEvent(PhoneEdit, Self, 'OnKeyPress', 'PhoneEditKeyPress');
    PhoneEdit.DataField := 'HomeTelephoneNumber';
    PhoneEdit.DataSource := CustomerSource;
    LastnameEdit.SetParentComponent(Self);
    LastnameEdit.Name := 'LastnameEdit';
    LastnameEdit.Left := 72;
    LastnameEdit.Top := 137;
    LastnameEdit.Width := 121;
    LastnameEdit.Height := 22;
    LastnameEdit.ChildOrder := 5;
    LastnameEdit.ElementClassName := 'form-control';
    LastnameEdit.ElementFont := efCSS;
    LastnameEdit.ElementPosition := epIgnore;
    LastnameEdit.HeightStyle := ssAuto;
    LastnameEdit.HeightPercent := 100.000000000000000000;
    LastnameEdit.TabOrder := 2;
    LastnameEdit.Text := 'LastnameEdit';
    LastnameEdit.WidthStyle := ssAuto;
    LastnameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(LastnameEdit, Self, 'OnExit', 'LastnameEditExit');
    LastnameEdit.DataField := 'LastName';
    LastnameEdit.DataSource := CustomerSource;
    FirstnameEdit.SetParentComponent(Self);
    FirstnameEdit.Name := 'FirstnameEdit';
    FirstnameEdit.Left := 72;
    FirstnameEdit.Top := 112;
    FirstnameEdit.Width := 121;
    FirstnameEdit.Height := 22;
    FirstnameEdit.ChildOrder := 6;
    FirstnameEdit.ElementClassName := 'form-control';
    FirstnameEdit.ElementFont := efCSS;
    FirstnameEdit.ElementPosition := epIgnore;
    FirstnameEdit.HeightStyle := ssAuto;
    FirstnameEdit.HeightPercent := 100.000000000000000000;
    FirstnameEdit.TabOrder := 1;
    FirstnameEdit.Text := 'FirstnameEdit';
    FirstnameEdit.WidthStyle := ssAuto;
    FirstnameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FirstnameEdit, Self, 'OnExit', 'FirstnameEditExit');
    FirstnameEdit.DataField := 'FirstName';
    FirstnameEdit.DataSource := CustomerSource;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 72;
    TitleEdit.Top := 88;
    TitleEdit.Width := 121;
    TitleEdit.Height := 22;
    TitleEdit.ChildOrder := 7;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.Text := 'TitleEdit';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := CustomerSource;
    CountryEdit.SetParentComponent(Self);
    CountryEdit.Name := 'CountryEdit';
    CountryEdit.Left := 72;
    CountryEdit.Top := 320;
    CountryEdit.Width := 145;
    CountryEdit.Height := 23;
    CountryEdit.ElementClassName := 'form-select';
    CountryEdit.ElementFont := efCSS;
    CountryEdit.ElementPosition := epIgnore;
    CountryEdit.HeightStyle := ssAuto;
    CountryEdit.HeightPercent := 100.000000000000000000;
    CountryEdit.TabOrder := 6;
    CountryEdit.WidthStyle := ssAuto;
    CountryEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CountryEdit, Self, 'OnExit', 'CountryEditExit');
    CountryEdit.ItemIndex := -1;
    PostcodeEdit.SetParentComponent(Self);
    PostcodeEdit.Name := 'PostcodeEdit';
    PostcodeEdit.Left := 72;
    PostcodeEdit.Top := 292;
    PostcodeEdit.Width := 121;
    PostcodeEdit.Height := 22;
    PostcodeEdit.CharCase := wecUpperCase;
    PostcodeEdit.ChildOrder := 9;
    PostcodeEdit.ElementClassName := 'form-control';
    PostcodeEdit.ElementFont := efCSS;
    PostcodeEdit.ElementPosition := epIgnore;
    PostcodeEdit.HeightStyle := ssAuto;
    PostcodeEdit.HeightPercent := 100.000000000000000000;
    PostcodeEdit.TabOrder := 5;
    PostcodeEdit.Text := 'PostcodeEdit';
    PostcodeEdit.WidthStyle := ssAuto;
    PostcodeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PostcodeEdit, Self, 'OnExit', 'PostcodeEditExit');
    PostcodeEdit.DataField := 'PostCode';
    PostcodeEdit.DataSource := CustomerSource;
    CountyEdit.SetParentComponent(Self);
    CountyEdit.Name := 'CountyEdit';
    CountyEdit.Left := 72;
    CountyEdit.Top := 264;
    CountyEdit.Width := 121;
    CountyEdit.Height := 22;
    CountyEdit.ChildOrder := 10;
    CountyEdit.ElementClassName := 'form-control';
    CountyEdit.ElementFont := efCSS;
    CountyEdit.ElementPosition := epIgnore;
    CountyEdit.HeightStyle := ssAuto;
    CountyEdit.HeightPercent := 100.000000000000000000;
    CountyEdit.TabOrder := 4;
    CountyEdit.Text := 'CountyEdit';
    CountyEdit.WidthStyle := ssAuto;
    CountyEdit.WidthPercent := 100.000000000000000000;
    CountyEdit.DataField := 'County';
    CountyEdit.DataSource := CustomerSource;
    TownEdit.SetParentComponent(Self);
    TownEdit.Name := 'TownEdit';
    TownEdit.Left := 72;
    TownEdit.Top := 236;
    TownEdit.Width := 121;
    TownEdit.Height := 22;
    TownEdit.ChildOrder := 11;
    TownEdit.ElementClassName := 'form-control';
    TownEdit.ElementFont := efCSS;
    TownEdit.ElementPosition := epIgnore;
    TownEdit.HeightStyle := ssAuto;
    TownEdit.HeightPercent := 100.000000000000000000;
    TownEdit.TabOrder := 3;
    TownEdit.Text := 'TownEdit';
    TownEdit.WidthStyle := ssAuto;
    TownEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TownEdit, Self, 'OnExit', 'TownEditExit');
    TownEdit.DataField := 'Town';
    TownEdit.DataSource := CustomerSource;
    Add3Edit.SetParentComponent(Self);
    Add3Edit.Name := 'Add3Edit';
    Add3Edit.Left := 72;
    Add3Edit.Top := 211;
    Add3Edit.Width := 121;
    Add3Edit.Height := 22;
    Add3Edit.ChildOrder := 12;
    Add3Edit.ElementClassName := 'form-control';
    Add3Edit.ElementFont := efCSS;
    Add3Edit.ElementPosition := epIgnore;
    Add3Edit.HeightStyle := ssAuto;
    Add3Edit.HeightPercent := 100.000000000000000000;
    Add3Edit.TabOrder := 2;
    Add3Edit.Text := 'Add3Edit';
    Add3Edit.WidthStyle := ssAuto;
    Add3Edit.WidthPercent := 100.000000000000000000;
    Add3Edit.DataField := 'AddressLine3';
    Add3Edit.DataSource := CustomerSource;
    Add2Edit.SetParentComponent(Self);
    Add2Edit.Name := 'Add2Edit';
    Add2Edit.Tag := 5;
    Add2Edit.Left := 72;
    Add2Edit.Top := 186;
    Add2Edit.Width := 121;
    Add2Edit.Height := 22;
    Add2Edit.ChildOrder := 13;
    Add2Edit.ElementClassName := 'form-control';
    Add2Edit.ElementFont := efCSS;
    Add2Edit.ElementPosition := epIgnore;
    Add2Edit.HeightStyle := ssAuto;
    Add2Edit.HeightPercent := 100.000000000000000000;
    Add2Edit.TabOrder := 1;
    Add2Edit.Text := 'Add2Edit';
    Add2Edit.WidthStyle := ssAuto;
    Add2Edit.WidthPercent := 100.000000000000000000;
    Add2Edit.DataField := 'AddressLine2';
    Add2Edit.DataSource := CustomerSource;
    Add1Edit.SetParentComponent(Self);
    Add1Edit.Name := 'Add1Edit';
    Add1Edit.Left := 72;
    Add1Edit.Top := 162;
    Add1Edit.Width := 121;
    Add1Edit.Height := 22;
    Add1Edit.ChildOrder := 14;
    Add1Edit.ElementClassName := 'form-control';
    Add1Edit.ElementFont := efCSS;
    Add1Edit.ElementPosition := epIgnore;
    Add1Edit.HeightStyle := ssAuto;
    Add1Edit.HeightPercent := 100.000000000000000000;
    Add1Edit.Text := 'Add1Edit';
    Add1Edit.WidthStyle := ssAuto;
    Add1Edit.WidthPercent := 100.000000000000000000;
    SetEvent(Add1Edit, Self, 'OnExit', 'Add1EditExit');
    Add1Edit.DataField := 'AddressLine1';
    Add1Edit.DataSource := CustomerSource;
    MyDetailsCancelButton.SetParentComponent(Self);
    MyDetailsCancelButton.Name := 'MyDetailsCancelButton';
    MyDetailsCancelButton.Left := 64;
    MyDetailsCancelButton.Top := 560;
    MyDetailsCancelButton.Width := 96;
    MyDetailsCancelButton.Height := 25;
    MyDetailsCancelButton.Caption := 'Close';
    MyDetailsCancelButton.ChildOrder := 15;
    MyDetailsCancelButton.ElementClassName := 'btn btn-primary';
    MyDetailsCancelButton.ElementFont := efCSS;
    MyDetailsCancelButton.ElementPosition := epIgnore;
    MyDetailsCancelButton.HeightStyle := ssAuto;
    MyDetailsCancelButton.HeightPercent := 100.000000000000000000;
    MyDetailsCancelButton.WidthStyle := ssAuto;
    MyDetailsCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(MyDetailsCancelButton, Self, 'OnClick', 'MyDetailsCancelButtonClick');
    MyDetailsSaveButton.SetParentComponent(Self);
    MyDetailsSaveButton.Name := 'MyDetailsSaveButton';
    MyDetailsSaveButton.Left := 166;
    MyDetailsSaveButton.Top := 560;
    MyDetailsSaveButton.Width := 96;
    MyDetailsSaveButton.Height := 25;
    MyDetailsSaveButton.Caption := 'Save';
    MyDetailsSaveButton.ChildOrder := 15;
    MyDetailsSaveButton.ElementClassName := 'btn btn-primary';
    MyDetailsSaveButton.ElementFont := efCSS;
    MyDetailsSaveButton.ElementPosition := epIgnore;
    MyDetailsSaveButton.Enabled := False;
    MyDetailsSaveButton.HeightStyle := ssAuto;
    MyDetailsSaveButton.HeightPercent := 100.000000000000000000;
    MyDetailsSaveButton.WidthStyle := ssAuto;
    MyDetailsSaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(MyDetailsSaveButton, Self, 'OnClick', 'MyDetailsSaveButtonClick');
    CustomerDetails.SetParentComponent(Self);
    CustomerDetails.Name := 'CustomerDetails';
    CustomerDetails.AfterApplyUpdates := CustomerDetailsAfterApplyUpdates;
    CustomerDetails.AfterOpen := CustomerDetailsAfterOpen;
    CustomerDetails.EntitySetName := 'Customer';
    CustomerDetails.Connection := MainData.DataConnection;
    CustomerDetails.Left := 40;
    CustomerDetails.Top := 24;
    CustomerDetailsCustomerId.SetParentComponent(CustomerDetails);
    CustomerDetailsCustomerId.Name := 'CustomerDetailsCustomerId';
    CustomerDetailsCustomerId.FieldName := 'CustomerId';
    CustomerDetailsCustomerId.Required := True;
    CustomerDetailsTitle.SetParentComponent(CustomerDetails);
    CustomerDetailsTitle.Name := 'CustomerDetailsTitle';
    CustomerDetailsTitle.FieldName := 'Title';
    CustomerDetailsTitle.Size := 15;
    CustomerDetailsFirstName.SetParentComponent(CustomerDetails);
    CustomerDetailsFirstName.Name := 'CustomerDetailsFirstName';
    CustomerDetailsFirstName.FieldName := 'FirstName';
    CustomerDetailsFirstName.Required := True;
    CustomerDetailsFirstName.Size := 50;
    CustomerDetailsMiddleName.SetParentComponent(CustomerDetails);
    CustomerDetailsMiddleName.Name := 'CustomerDetailsMiddleName';
    CustomerDetailsMiddleName.FieldName := 'MiddleName';
    CustomerDetailsMiddleName.Size := 50;
    CustomerDetailsLastName.SetParentComponent(CustomerDetails);
    CustomerDetailsLastName.Name := 'CustomerDetailsLastName';
    CustomerDetailsLastName.FieldName := 'LastName';
    CustomerDetailsLastName.Required := True;
    CustomerDetailsLastName.Size := 50;
    CustomerDetailsHomeTelephoneNumber.SetParentComponent(CustomerDetails);
    CustomerDetailsHomeTelephoneNumber.Name := 'CustomerDetailsHomeTelephoneNumber';
    CustomerDetailsHomeTelephoneNumber.FieldName := 'HomeTelephoneNumber';
    CustomerDetailsHomeTelephoneNumber.Size := 30;
    CustomerDetailsMobileNumber.SetParentComponent(CustomerDetails);
    CustomerDetailsMobileNumber.Name := 'CustomerDetailsMobileNumber';
    CustomerDetailsMobileNumber.FieldName := 'MobileNumber';
    CustomerDetailsMobileNumber.Size := 30;
    CustomerDetailsEmail.SetParentComponent(CustomerDetails);
    CustomerDetailsEmail.Name := 'CustomerDetailsEmail';
    CustomerDetailsEmail.FieldName := 'Email';
    CustomerDetailsEmail.Size := 255;
    CustomerDetailsAddressLine1.SetParentComponent(CustomerDetails);
    CustomerDetailsAddressLine1.Name := 'CustomerDetailsAddressLine1';
    CustomerDetailsAddressLine1.FieldName := 'AddressLine1';
    CustomerDetailsAddressLine1.Required := True;
    CustomerDetailsAddressLine1.Size := 100;
    CustomerDetailsAddressLine2.SetParentComponent(CustomerDetails);
    CustomerDetailsAddressLine2.Name := 'CustomerDetailsAddressLine2';
    CustomerDetailsAddressLine2.FieldName := 'AddressLine2';
    CustomerDetailsAddressLine2.Size := 100;
    CustomerDetailsAddressLine3.SetParentComponent(CustomerDetails);
    CustomerDetailsAddressLine3.Name := 'CustomerDetailsAddressLine3';
    CustomerDetailsAddressLine3.FieldName := 'AddressLine3';
    CustomerDetailsAddressLine3.Size := 100;
    CustomerDetailsTown.SetParentComponent(CustomerDetails);
    CustomerDetailsTown.Name := 'CustomerDetailsTown';
    CustomerDetailsTown.FieldName := 'Town';
    CustomerDetailsTown.Size := 50;
    CustomerDetailsCounty.SetParentComponent(CustomerDetails);
    CustomerDetailsCounty.Name := 'CustomerDetailsCounty';
    CustomerDetailsCounty.FieldName := 'County';
    CustomerDetailsCounty.Size := 50;
    CustomerDetailsPostCode.SetParentComponent(CustomerDetails);
    CustomerDetailsPostCode.Name := 'CustomerDetailsPostCode';
    CustomerDetailsPostCode.FieldName := 'PostCode';
    CustomerDetailsPostCode.Size := 30;
    CustomerDetailsDetailsComplete.SetParentComponent(CustomerDetails);
    CustomerDetailsDetailsComplete.Name := 'CustomerDetailsDetailsComplete';
    CustomerDetailsDetailsComplete.FieldName := 'DetailsComplete';
    CustomerDetailsDetailsComplete.Required := True;
    CustomerDetailsFullyVaccinated.SetParentComponent(CustomerDetails);
    CustomerDetailsFullyVaccinated.Name := 'CustomerDetailsFullyVaccinated';
    CustomerDetailsFullyVaccinated.FieldName := 'FullyVaccinated';
    CustomerDetailsFullyVaccinated.Required := True;
    CustomerDetailsDateOfLastVaccination.SetParentComponent(CustomerDetails);
    CustomerDetailsDateOfLastVaccination.Name := 'CustomerDetailsDateOfLastVaccination';
    CustomerDetailsDateOfLastVaccination.FieldName := 'DateOfLastVaccination';
    CustomerDetailsCountryCode.SetParentComponent(CustomerDetails);
    CustomerDetailsCountryCode.Name := 'CustomerDetailsCountryCode';
    CustomerDetailsCountryCode.FieldName := 'CountryCode';
    CustomerDetailsCountryCode.Size := 5;
    CustomerDetailsDiscountId.SetParentComponent(CustomerDetails);
    CustomerDetailsDiscountId.Name := 'CustomerDetailsDiscountId';
    CustomerDetailsDiscountId.FieldName := 'DiscountId';
    CustomerDetailsUserId.SetParentComponent(CustomerDetails);
    CustomerDetailsUserId.Name := 'CustomerDetailsUserId';
    CustomerDetailsUserId.FieldName := 'UserId';
    CustomerDetailsUserId.Size := 128;
    CustomerDetailsCustomerCode.SetParentComponent(CustomerDetails);
    CustomerDetailsCustomerCode.Name := 'CustomerDetailsCustomerCode';
    CustomerDetailsCustomerCode.FieldName := 'CustomerCode';
    CustomerDetailsCustomerCode.Size := 15;
    CustomerDetailsHearAboutUs.SetParentComponent(CustomerDetails);
    CustomerDetailsHearAboutUs.Name := 'CustomerDetailsHearAboutUs';
    CustomerDetailsHearAboutUs.FieldName := 'HearAboutUs';
    CustomerDetailsCustomerType.SetParentComponent(CustomerDetails);
    CustomerDetailsCustomerType.Name := 'CustomerDetailsCustomerType';
    CustomerDetailsCustomerType.FieldName := 'CustomerType';
    CustomerDetailsCustomerType.Size := 9;
    CustomerDetailsParentCustomerId.SetParentComponent(CustomerDetails);
    CustomerDetailsParentCustomerId.Name := 'CustomerDetailsParentCustomerId';
    CustomerDetailsParentCustomerId.FieldName := 'ParentCustomerId';
    CustomerDetailsAddressSameAsParent.SetParentComponent(CustomerDetails);
    CustomerDetailsAddressSameAsParent.Name := 'CustomerDetailsAddressSameAsParent';
    CustomerDetailsAddressSameAsParent.FieldName := 'AddressSameAsParent';
    CustomerDetailsContactDetailId.SetParentComponent(CustomerDetails);
    CustomerDetailsContactDetailId.Name := 'CustomerDetailsContactDetailId';
    CustomerDetailsContactDetailId.FieldName := 'ContactDetailId';
    CustomerDetailsDateCreated.SetParentComponent(CustomerDetails);
    CustomerDetailsDateCreated.Name := 'CustomerDetailsDateCreated';
    CustomerDetailsDateCreated.FieldName := 'DateCreated';
    CustomerSource.SetParentComponent(Self);
    CustomerSource.Name := 'CustomerSource';
    CustomerSource.DataSet := CustomerDetails;
    SetEvent(CustomerSource, Self, 'OnStateChange', 'CustomerSourceStateChange');
    SetEvent(CustomerSource, Self, 'OnDataChange', 'CustomerSourceDataChange');
    CustomerSource.Left := 128;
    CustomerSource.Top := 24;
  finally
    LastVaccinatedPanel.AfterLoadDFMValues;
    LastVaccinationEdit.AfterLoadDFMValues;
    VaccinatedEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    PhoneEdit.AfterLoadDFMValues;
    LastnameEdit.AfterLoadDFMValues;
    FirstnameEdit.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    CountryEdit.AfterLoadDFMValues;
    PostcodeEdit.AfterLoadDFMValues;
    CountyEdit.AfterLoadDFMValues;
    TownEdit.AfterLoadDFMValues;
    Add3Edit.AfterLoadDFMValues;
    Add2Edit.AfterLoadDFMValues;
    Add1Edit.AfterLoadDFMValues;
    MyDetailsCancelButton.AfterLoadDFMValues;
    MyDetailsSaveButton.AfterLoadDFMValues;
    CustomerDetails.AfterLoadDFMValues;
    CustomerDetailsCustomerId.AfterLoadDFMValues;
    CustomerDetailsTitle.AfterLoadDFMValues;
    CustomerDetailsFirstName.AfterLoadDFMValues;
    CustomerDetailsMiddleName.AfterLoadDFMValues;
    CustomerDetailsLastName.AfterLoadDFMValues;
    CustomerDetailsHomeTelephoneNumber.AfterLoadDFMValues;
    CustomerDetailsMobileNumber.AfterLoadDFMValues;
    CustomerDetailsEmail.AfterLoadDFMValues;
    CustomerDetailsAddressLine1.AfterLoadDFMValues;
    CustomerDetailsAddressLine2.AfterLoadDFMValues;
    CustomerDetailsAddressLine3.AfterLoadDFMValues;
    CustomerDetailsTown.AfterLoadDFMValues;
    CustomerDetailsCounty.AfterLoadDFMValues;
    CustomerDetailsPostCode.AfterLoadDFMValues;
    CustomerDetailsDetailsComplete.AfterLoadDFMValues;
    CustomerDetailsFullyVaccinated.AfterLoadDFMValues;
    CustomerDetailsDateOfLastVaccination.AfterLoadDFMValues;
    CustomerDetailsCountryCode.AfterLoadDFMValues;
    CustomerDetailsDiscountId.AfterLoadDFMValues;
    CustomerDetailsUserId.AfterLoadDFMValues;
    CustomerDetailsCustomerCode.AfterLoadDFMValues;
    CustomerDetailsHearAboutUs.AfterLoadDFMValues;
    CustomerDetailsCustomerType.AfterLoadDFMValues;
    CustomerDetailsParentCustomerId.AfterLoadDFMValues;
    CustomerDetailsAddressSameAsParent.AfterLoadDFMValues;
    CustomerDetailsContactDetailId.AfterLoadDFMValues;
    CustomerDetailsDateCreated.AfterLoadDFMValues;
    CustomerSource.AfterLoadDFMValues;
  end;
end;

End.
