unit AccountBaseForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  TAccountBasePage = class(TCoreWebForm)
    CloseButton: TButton;
    HolidaySelectedLabel: TLabel;
    BookingRefSpan: THTMLSpan;
    procedure WebFormCreate(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
  private
    FBookingId: Integer;
    FOnCloseCallback: TNotifyEvent;
    procedure SetBookingId(const Value: Integer);
    { Private declarations }
  protected
    procedure LoadSelectedBooking; virtual;
    procedure AfterBookingLoaded(Sender: TObject); virtual;
    procedure InitForm; virtual;
  public
    { Public declarations }
    procedure TearDown; override;
    property BookingId: Integer read FBookingId write SetBookingId;
    property OnCloseCallback: TNotifyEvent read FOnCloseCallback write FOnCloseCallback;
  protected procedure LoadDFMValues; override; end;

  TAccountBasePageClass = class of TAccountBasePage;

implementation

{$R *.dfm}

uses
  MainDataModule;

procedure TAccountBasePage.WebFormCreate(Sender: TObject);
begin
  inherited;
  window.location.hash := PageHashName;
end;

{ TAccountBasePage }

procedure TAccountBasePage.AfterBookingLoaded(Sender: TObject);
begin
  InitForm;
  HideWaitMessage;
end;

procedure TAccountBasePage.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TAccountBasePage.InitForm;
begin
  HolidaySelectedLabel.Caption := MainData.HolidayDescription;
  HolidaySelectedLabel.Visible := (HolidaySelectedLabel.Caption <> '');
  BookingRefSpan.HTML.Text := MainData.BookingDatasetBookingReference.Value;
end;

procedure TAccountBasePage.LoadSelectedBooking;
begin
  MainData.OnSelectedBookingLoaded := AfterBookingLoaded;
  MainData.LoadSelectedBooking(FBookingId);
end;

procedure TAccountBasePage.SetBookingId(const Value: Integer);
begin
  FBookingId := Value;
  ShowWaitMessage;
  LoadSelectedBooking;
end;

procedure TAccountBasePage.TearDown;
begin
  MainData.OnSelectedBookingLoaded := nil;
  MainData.CloseBookingData(True);
  if Assigned(FOnCloseCallback) then
     FOnCloseCallback(Self);
end;

procedure TAccountBasePage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HolidaySelectedLabel := TLabel.Create('HolidaySelectedLabel');
  CloseButton := TButton.Create('AccountCloseButton');
  BookingRefSpan := THTMLSpan.Create('BookingRefSpan');

  HolidaySelectedLabel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  BookingRefSpan.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    HolidaySelectedLabel.SetParentComponent(Self);
    HolidaySelectedLabel.Name := 'HolidaySelectedLabel';
    HolidaySelectedLabel.Left := 80;
    HolidaySelectedLabel.Top := 48;
    HolidaySelectedLabel.Width := 113;
    HolidaySelectedLabel.Height := 15;
    HolidaySelectedLabel.Caption := 'HolidaySelectedLabel';
    HolidaySelectedLabel.Color := clBtnFace;
    HolidaySelectedLabel.ElementFont := efCSS;
    HolidaySelectedLabel.ElementPosition := epIgnore;
    HolidaySelectedLabel.HeightStyle := ssAuto;
    HolidaySelectedLabel.HeightPercent := 100.000000000000000000;
    HolidaySelectedLabel.WidthStyle := ssAuto;
    HolidaySelectedLabel.WidthPercent := 100.000000000000000000;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 512;
    CloseButton.Top := 416;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ElementClassName := 'btn btn-primary';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    BookingRefSpan.SetParentComponent(Self);
    BookingRefSpan.Name := 'BookingRefSpan';
    BookingRefSpan.Left := 88;
    BookingRefSpan.Top := 88;
    BookingRefSpan.Width := 100;
    BookingRefSpan.Height := 41;
    BookingRefSpan.HeightStyle := ssAuto;
    BookingRefSpan.WidthStyle := ssAuto;
    BookingRefSpan.ChildOrder := 2;
    BookingRefSpan.ElementPosition := epIgnore;
    BookingRefSpan.ElementFont := efCSS;
    BookingRefSpan.Role := '';
  finally
    HolidaySelectedLabel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    BookingRefSpan.AfterLoadDFMValues;
  end;
end;

end.
