unit AccountDocumentsForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, AccountBaseForm, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  TAccountDocumentsPage = class(TAccountBasePage)
  private
    { Private declarations }
  protected
     procedure LoadSelectedBooking; override;
     [async]
     procedure GetDocumentList; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  AccountDocumentsPage: TAccountDocumentsPage;

implementation

{$R *.dfm}

uses MainDataModule;

{ TAccountDocumentsPage }

procedure TAccountDocumentsPage.GetDocumentList;
begin

end;

procedure TAccountDocumentsPage.LoadSelectedBooking;
begin
  GetDocumentList;
end;

procedure TAccountDocumentsPage.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.