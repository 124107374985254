unit Holiday.Booking.Update;

interface

uses
  System.SysUtils,
  System.DateUtils;

type

  THolidayGuestsUpdate = class
  private
    class procedure IncreaseCount;
    class procedure DecreaseCount;
    class procedure SwitchToPainter;
    class procedure SwitchToNPP;
    class procedure UpdateCosts(const PainterChange, NPPChange: Integer);
  public
    class procedure UpdateOnChange;
    class procedure NPPUpdateCost;
    class procedure PainterUpdateCost;
    class procedure UpdateSecondaryCosts;
  end;

  THolidaySelectionUpdate = class
  private
    class procedure HolidayChanged;
    class procedure RoomShareChanged;
    class procedure UpdateFirstRoomType;
    class procedure UpdateSecondRoomType;
  public
    class procedure UpdateBooking;
  end;

  TBookingUpdate = class
  private
  public
    class procedure CalcBookingCosts;
  end;


implementation

{ THolidayBookingUpdate }

uses
  MainDataModule,
  Cyrus.Enumerations;

class procedure THolidayGuestsUpdate.SwitchToNPP;
var
  lGuestId: Integer;
begin
  lGuestId := MainData.BookingDatasetGuestId.Value;
  MainData.BookingDatasetNumberOfPainters.Value := 1;
  MainData.BookingDatasetNonPaintingPartner.Value := True;
  if (lGuestId > 0) and MainData.GuestDataset.Active and MainData.GuestDataset.Locate('CustomerId', lGuestId, []) then
    MainData.EditDataset(MainData.GuestDataset);

  MainData.GuestDatasetGuestType.Value := Guest_Type[TGuestType.NonPainter];
  MainData.GuestDatasetMedia1.Clear;
  MainData.GuestDatasetMedia2.Clear;
  MainData.GuestDatasetEaselRequired.Value := False;
  MainData.GuestDatasetFoldingChairRequired.Value := False;
  MainData.GuestDatasetBoardRequired.Value := False;
end;

class procedure THolidayGuestsUpdate.SwitchToPainter;
var
  lGuestId: Integer;
begin
  lGuestId := MainData.BookingDatasetGuestId.Value;
  MainData.BookingDatasetNumberOfPainters.Value := 2;
  MainData.BookingDatasetNonPaintingPartner.Value := False;
  if (lGuestId > 0) and MainData.GuestDataset.Active and MainData.GuestDataset.Locate('CustomerId', lGuestId, []) then
  begin
    MainData.EditDataset(MainData.GuestDataset);
    MainData.GuestDatasetGuestType.Value := Guest_Type[TGuestType.Painter];
    { TODO : Check if there is just one media option }
    MainData.GuestDatasetMedia1.Value := Media_Types[TMediaType.None];
  end;

end;

class procedure THolidayGuestsUpdate.UpdateCosts(const PainterChange, NPPChange: Integer);
begin
  if NPPChange = -1 then
  begin
    if MainData.BookingCosts.Locate('ItemType', Cost_Item_Type[TCostItemType.NPP], []) then
      MainData.BookingCosts.Delete;
  end
  else if NPPChange = 1 then
    NPPUpdateCost;

  if PainterChange <> 0 then
    PainterUpdateCost;

end;

class procedure THolidayGuestsUpdate.DecreaseCount;
var
  lGuestId: Integer;
begin
  lGuestId := MainData.BookingDatasetGuestId.Value;
  MainData.BookingDatasetGuestId.Value := 0;
  MainData.BookingDatasetNoOfGuests.Value := 1;
  MainData.BookingDatasetNonPaintingPartner.Value := False;
  MainData.BookingDatasetNumberOfPainters.Value := 1;

  if MainData.Customer2.Active then
    MainData.Customer2.Close;

  if (lGuestId > 0) and MainData.GuestDataset.Active and MainData.GuestDataset.Locate('CustomerId', lGuestId, []) then
    MainData.GuestDataset.Delete;

  MainData.BookingDatasetSecondRoomTypeId.Value := Room_Type_Int[TRoomType.None];
  MainData.BookingDatasetShareRoom.Value := False;

end;

class procedure THolidayGuestsUpdate.IncreaseCount;
begin
  MainData.BookingDatasetNoOfGuests.Value := 2;
  MainData.BookingDatasetNonPaintingPartner.Value := MainData.HolidayBooking.NonPainters = 1;
  MainData.BookingDatasetNumberOfPainters.Value := MainData.HolidayBooking.Painters;
  MainData.BookingDatasetShareRoom.Value := MainData.HolidayBooking.RoomShare;
  MainData.BookingDatasetSecondRoomTypeId.Value := Room_Type_Int[MainData.HolidayBooking.RoomType2];
end;

class procedure THolidayGuestsUpdate.NPPUpdateCost;
begin

  if not MainData.BookingDatasetNonPaintingPartner.Value then
     Exit;

  if not MainData.BookingCosts.Locate('ItemType', Cost_Item_Type[TCostItemType.NPP], []) then
  begin
    MainData.BookingCosts.Append;
    MainData.BookingCostsItemType.Value := Cost_Item_Type[TCostItemType.NPP];
    MainData.BookingCostsQuantity.Value := 1;
    MainData.BookingCostsCostPeriod.Value := Cost_Period_PerStay;
  end
  else
    MainData.EditDataset(MainData.BookingCosts);

  if MainData.HolidayBooking.RoomType = TRoomType.Single then
  begin
    MainData.BookingCostsUnitPrice.Value := MainData.HolidayDataNPPSingleRoom.Value;
    MainData.BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Standard];
    MainData.BookingCostsDescription.Value := 'Non-Painter price (Single Room)';
  end
  else
  begin
    if (not MainData.HolidayBooking.RoomShare) then
    begin
      MainData.BookingCostsUnitPrice.Value := MainData.HolidayDataNPPSingleOccupancy.Value;
      MainData.BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.SingleOccupier];
      MainData.BookingCostsDescription.Value := 'Non-Painter price (' + Room_Type_Description
        [MainData.HolidayBooking.RoomType2] + ' - Single Occupancy)';
    end
    else
    begin
      MainData.BookingCostsUnitPrice.Value := MainData.HolidayDataNPPPrice.Value;
      MainData.BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Shared];
      MainData.BookingCostsDescription.Value := 'Non-Painter price';
    end;
  end;

  MainData.BookingCosts.Post;
end;

class procedure THolidayGuestsUpdate.PainterUpdateCost;
var
  Qty: Integer;
begin
  if MainData.BookingCosts.Locate('ItemType', Cost_Item_Type[TCostItemType.Painter], []) then
    MainData.BookingCosts.Edit
  else
  begin
    MainData.BookingCosts.Append;
    MainData.BookingCostsItemType.Value := Cost_Item_Type[TCostItemType.Painter];
    MainData.BookingCostsCostPeriod.Value := Cost_Period_PerStay;
  end;

  Qty := MainData.BookingDatasetNumberOfPainters.Value;
  MainData.BookingCostsQuantity.Value := Qty;

  if MainData.HolidayBooking.RoomType = TRoomType.Single then
  begin
    MainData.BookingCostsUnitPrice.Value := MainData.HolidayDataSingleRoom.Value;
    MainData.BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Standard];
    MainData.BookingCostsDescription.Value := 'Painter price (Single Room)';
  end
  else
  begin
    if (MainData.HolidayBooking.GuestCount = 1) or (not MainData.HolidayBooking.RoomShare) then
    begin
      MainData.BookingCostsUnitPrice.Value := MainData.HolidayDataSingleOccupancy.Value;
      MainData.BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.SingleOccupier];
      MainData.BookingCostsDescription.Value := 'Painter price (' + Room_Type_Description
        [MainData.HolidayBooking.RoomType] + ' - Single Occupancy)';
    end
    else
    begin
      MainData.BookingCostsUnitPrice.Value := MainData.HolidayDataPainterPrice.Value;
      MainData.BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Shared];
      MainData.BookingCostsDescription.Value := 'Painter price (' + Room_Type_Description
        [MainData.HolidayBooking.RoomType] + ')';
    end;
  end;

  MainData.BookingCosts.Post;

end;

class procedure THolidayGuestsUpdate.UpdateOnChange;
var
  lGuestId, lPainterChange, lNPPChange: Integer;
begin
  lPainterChange := 0;
  lNPPChange := 0;

  lGuestId := MainData.BookingDatasetGuestId.Value;
  MainData.EditDataset(MainData.BookingDataset);
  MainData.BookingDatasetShareRoom.Value := MainData.HolidayBooking.RoomShare;

  // Guest was removed, so 2 -> 1 Guests
  if MainData.BookingDatasetNoOfGuests.Value > MainData.HolidayBooking.GuestCount then
  begin
    if MainData.BookingDatasetNonPaintingPartner.Value then
      lNPPChange := -1
    else
      lPainterChange := -1;

    DecreaseCount;

  end
  // Guest was added, so 1 -> 2 guests
  else if MainData.BookingDatasetNoOfGuests.Value < MainData.HolidayBooking.GuestCount then
  begin
    if MainData.BookingDatasetNonPaintingPartner.Value then
      lNPPChange := 1
    else
      lPainterChange := 1;

    IncreaseCount;
  end
  // This is a switch from a Painter guest to a NPP Guest
  else if MainData.BookingDatasetNumberOfPainters.Value > MainData.HolidayBooking.Painters then
  begin
    lPainterChange := -1;
    lNPPChange := 1;
    SwitchToNPP
  end
  // This is a switch from a NPP to a Painter
  else if MainData.BookingDatasetNumberOfPainters.Value < MainData.HolidayBooking.Painters then
  begin
    lPainterChange := 1;
    lNPPChange := -1;
    SwitchToPainter;
  end;

  UpdateCosts(lPainterChange, lNPPChange);

end;

class procedure THolidayGuestsUpdate.UpdateSecondaryCosts;
begin
//   if MainData.BookingCosts.Locate('ItemType', Cost_Item_Type[TCostItemType.ExtraNights], []) then
//   begin
//
//   end;
end;

{ THolidaySelectionUpdate }

class procedure THolidaySelectionUpdate.HolidayChanged;
begin
  MainData.BookingDatasetHolidayId.Value := MainData.HolidayBooking.HolidayId;

  MainData.BookingDatasetArrivalDate.Value := IncDay(MainData.HolidayBooking.StartDate,
    -MainData.BookingDatasetFirstRoomExtraNightsBefore.Value);

  MainData.BookingDatasetDepartureDate.Value := IncDay(MainData.HolidayBooking.EndDate,
    MainData.BookingDatasetFirstRoomExtraNightsAfter.Value);

  if MainData.HolidayBooking.BalanceDueDate > Now then
    MainData.BookingDatasetFinalBalanceDueOn.Value := MainData.HolidayBooking.BalanceDueDate
  else
    MainData.BookingDatasetFinalBalanceDueOn.Value := Today;

  if (MainData.HolidayBooking.BalanceDueDate > Today) or (not MainData.HolidayDataConfirmed.Value) then
    MainData.BookingDatasetDepositDue.Value := MainData.HolidayDataDepositresidential.Value *
      MainData.HolidayBooking.GuestCount
  else
    MainData.BookingDatasetDepositDue.Value := 0;

end;

class procedure THolidaySelectionUpdate.RoomShareChanged;
begin
  // This means now only one room
  if MainData.HolidayBooking.RoomShare then
  begin
    MainData.BookingDatasetSecondRoomTypeId.Value := Room_Type_Int[TRoomType.None];
    if MainData.GuestDataset.Active and MainData.GuestDataset.Locate('CustomerId', MainData.GuestId, []) then
    begin
      MainData.EditDataset(MainData.GuestDataset);
      MainData.GuestDatasetRoomTypeId.Value := Room_Type_Int[TRoomType.None];
    end;
  end
  else // now 2 rooms
  begin
    MainData.BookingDatasetFirstRoomTypeId.Value := MainData.HolidayBooking.RoomTypeInt;
    MainData.BookingDatasetSecondRoomTypeId.Value := MainData.HolidayBooking.RoomTypeInt;
    UpdateFirstRoomType;
    UpdateSecondRoomType;
  end;
end;

class procedure THolidaySelectionUpdate.UpdateBooking;
var
  lHolidayChanged, lRoomShareChanged: Boolean;
begin
  MainData.EditDataset(MainData.BookingDataset);

  lHolidayChanged := MainData.BookingDatasetHolidayId.Value <> MainData.HolidayBooking.HolidayId;

  if lHolidayChanged then
    HolidayChanged;

  lRoomShareChanged := MainData.BookingDatasetShareRoom.Value <> MainData.HolidayBooking.RoomShare;

  if lRoomShareChanged then
    RoomShareChanged
  else
  begin
    if MainData.BookingDatasetFirstRoomTypeId.Value <> MainData.HolidayBooking.RoomTypeInt then
      UpdateFirstRoomType;

    if MainData.BookingDatasetSecondRoomTypeId.Value <> MainData.HolidayBooking.RoomType2Int then
      UpdateSecondRoomType;

  end;

end;

class procedure THolidaySelectionUpdate.UpdateFirstRoomType;
begin
  MainData.BookingDatasetFirstRoomTypeId.Value := MainData.HolidayBooking.RoomTypeInt;
  if MainData.GuestDataset.Active then
  begin
    if MainData.GuestDataset.Locate('CustomerId', MainData.CustomerId, []) then
    begin
      MainData.EditDataset(MainData.GuestDataset);
      MainData.GuestDatasetRoomTypeId.Value := MainData.HolidayBooking.RoomTypeInt;
    end;
  end;
end;

class procedure THolidaySelectionUpdate.UpdateSecondRoomType;
begin
  MainData.BookingDatasetSecondRoomTypeId.Value := MainData.HolidayBooking.RoomTypeInt;
  if MainData.GuestDataset.Active then
  begin
    if MainData.GuestDataset.Locate('CustomerId', MainData.GuestId, []) then
    begin
      MainData.EditDataset(MainData.GuestDataset);
      MainData.GuestDatasetRoomTypeId.Value := MainData.HolidayBooking.RoomType2Int;
    end;
  end;
end;

{ TBookingUpdate }

class procedure TBookingUpdate.CalcBookingCosts;
begin

end;

end.
