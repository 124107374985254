unit BookingDetailsFrame;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs;

type
  TBookingDetailsShared = class(TFrame)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  BookingDetailsShared: TBookingDetailsShared;

implementation

{$R *.dfm}

procedure TBookingDetailsShared.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'BookingDetailsShared';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    TabOrder := 0;
  finally
  end;
end;

end.