Unit AccountOtherDetails;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB;

Type
  TAccountOtherDetailsForm = Class(TForm)
    DietaryRequirements: TDBEdit;
    Mobility: TDBEdit;
    OtherNeeds: TDBEdit;
    EmergencyContactRelation: TDBEdit;
    EmergencyContactPhone: TDBEdit;
    EmergencyContactName: TDBEdit;
    AcctOtherSaveBtn: TButton;
    AcctOtherCancelBtn: TButton;
    OtherDetailsSource: TDataSource;
    Procedure AcctOtherCancelBtnClick(Sender: TObject);
    Procedure AcctOtherSaveBtnClick(Sender: TObject);
  Private
    Function GetDataSet: TDataset;
    procedure SetDataset(const Value: TDataset);
    { Private declarations }
  Public
    { Public declarations }
    Property DataSet: TDataset Read GetDataSet write SetDataset;
  protected procedure LoadDFMValues; override; End;

Var
  AccountOtherDetailsForm: TAccountOtherDetailsForm;

Implementation

{$R *.dfm}

Procedure TAccountOtherDetailsForm.AcctOtherCancelBtnClick(Sender: TObject);
Begin
  DataSet.Cancel;
  ModalResult := mrCancel;
End;

Procedure TAccountOtherDetailsForm.AcctOtherSaveBtnClick(Sender: TObject);
Begin
  DataSet.Post;
  ModalResult := mrOK;
End;

{ TAccountOtherDetailsForm }

Function TAccountOtherDetailsForm.GetDataSet: TDataset;
Begin
  Result := EmergencyContactName.DataSource.DataSet;
End;

procedure TAccountOtherDetailsForm.SetDataset(const Value: TDataset);
begin
  OtherDetailsSource.DataSet := Value;
  If Not(DataSet.State In dsEditModes) Then
    DataSet.Edit;

end;

procedure TAccountOtherDetailsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DietaryRequirements := TDBEdit.Create('DietaryRequirements');
  Mobility := TDBEdit.Create('Mobility');
  OtherNeeds := TDBEdit.Create('OtherNeeds');
  EmergencyContactRelation := TDBEdit.Create('EmergencyContactRelation');
  EmergencyContactPhone := TDBEdit.Create('EmergencyContactPhone');
  EmergencyContactName := TDBEdit.Create('EmergencyContactName');
  AcctOtherSaveBtn := TButton.Create('AcctOtherSaveBtn');
  AcctOtherCancelBtn := TButton.Create('AcctOtherCancelBtn');
  OtherDetailsSource := TDataSource.Create(Self);

  DietaryRequirements.BeforeLoadDFMValues;
  Mobility.BeforeLoadDFMValues;
  OtherNeeds.BeforeLoadDFMValues;
  EmergencyContactRelation.BeforeLoadDFMValues;
  EmergencyContactPhone.BeforeLoadDFMValues;
  EmergencyContactName.BeforeLoadDFMValues;
  AcctOtherSaveBtn.BeforeLoadDFMValues;
  AcctOtherCancelBtn.BeforeLoadDFMValues;
  OtherDetailsSource.BeforeLoadDFMValues;
  try
    Name := 'AccountOtherDetailsForm';
    Width := 466;
    Height := 320;
    DietaryRequirements.SetParentComponent(Self);
    DietaryRequirements.Name := 'DietaryRequirements';
    DietaryRequirements.Left := 232;
    DietaryRequirements.Top := 160;
    DietaryRequirements.Width := 177;
    DietaryRequirements.Height := 22;
    DietaryRequirements.ChildOrder := 4;
    DietaryRequirements.ElementClassName := 'form-control';
    DietaryRequirements.ElementFont := efCSS;
    DietaryRequirements.ElementPosition := epIgnore;
    DietaryRequirements.HeightStyle := ssAuto;
    DietaryRequirements.HeightPercent := 100.000000000000000000;
    DietaryRequirements.TabOrder := 4;
    DietaryRequirements.Text := 'DietaryRequirements';
    DietaryRequirements.WidthStyle := ssAuto;
    DietaryRequirements.WidthPercent := 100.000000000000000000;
    DietaryRequirements.DataField := 'Dietaryrequirements';
    DietaryRequirements.DataSource := OtherDetailsSource;
    Mobility.SetParentComponent(Self);
    Mobility.Name := 'Mobility';
    Mobility.Left := 232;
    Mobility.Top := 188;
    Mobility.Width := 177;
    Mobility.Height := 22;
    Mobility.ChildOrder := 4;
    Mobility.ElementClassName := 'form-control';
    Mobility.ElementFont := efCSS;
    Mobility.ElementPosition := epIgnore;
    Mobility.HeightStyle := ssAuto;
    Mobility.HeightPercent := 100.000000000000000000;
    Mobility.TabOrder := 4;
    Mobility.Text := 'Mobility';
    Mobility.WidthStyle := ssAuto;
    Mobility.WidthPercent := 100.000000000000000000;
    Mobility.DataField := 'Mobility';
    Mobility.DataSource := OtherDetailsSource;
    OtherNeeds.SetParentComponent(Self);
    OtherNeeds.Name := 'OtherNeeds';
    OtherNeeds.Left := 232;
    OtherNeeds.Top := 216;
    OtherNeeds.Width := 177;
    OtherNeeds.Height := 22;
    OtherNeeds.ChildOrder := 4;
    OtherNeeds.ElementClassName := 'form-control';
    OtherNeeds.ElementFont := efCSS;
    OtherNeeds.ElementPosition := epIgnore;
    OtherNeeds.HeightStyle := ssAuto;
    OtherNeeds.HeightPercent := 100.000000000000000000;
    OtherNeeds.TabOrder := 6;
    OtherNeeds.Text := 'OtherNeeds';
    OtherNeeds.WidthStyle := ssAuto;
    OtherNeeds.WidthPercent := 100.000000000000000000;
    OtherNeeds.DataField := 'Otherneeds';
    OtherNeeds.DataSource := OtherDetailsSource;
    EmergencyContactRelation.SetParentComponent(Self);
    EmergencyContactRelation.Name := 'EmergencyContactRelation';
    EmergencyContactRelation.Left := 16;
    EmergencyContactRelation.Top := 216;
    EmergencyContactRelation.Width := 177;
    EmergencyContactRelation.Height := 22;
    EmergencyContactRelation.ChildOrder := 4;
    EmergencyContactRelation.ElementClassName := 'form-control';
    EmergencyContactRelation.ElementFont := efCSS;
    EmergencyContactRelation.ElementPosition := epIgnore;
    EmergencyContactRelation.HeightStyle := ssAuto;
    EmergencyContactRelation.HeightPercent := 100.000000000000000000;
    EmergencyContactRelation.TabOrder := 2;
    EmergencyContactRelation.Text := 'EmergencyContactRelation';
    EmergencyContactRelation.WidthStyle := ssAuto;
    EmergencyContactRelation.WidthPercent := 100.000000000000000000;
    EmergencyContactRelation.DataField := 'Emergencycontactrelation';
    EmergencyContactRelation.DataSource := OtherDetailsSource;
    EmergencyContactPhone.SetParentComponent(Self);
    EmergencyContactPhone.Name := 'EmergencyContactPhone';
    EmergencyContactPhone.Left := 16;
    EmergencyContactPhone.Top := 188;
    EmergencyContactPhone.Width := 177;
    EmergencyContactPhone.Height := 22;
    EmergencyContactPhone.ChildOrder := 4;
    EmergencyContactPhone.ElementClassName := 'form-control';
    EmergencyContactPhone.ElementFont := efCSS;
    EmergencyContactPhone.ElementPosition := epIgnore;
    EmergencyContactPhone.HeightStyle := ssAuto;
    EmergencyContactPhone.HeightPercent := 100.000000000000000000;
    EmergencyContactPhone.TabOrder := 2;
    EmergencyContactPhone.Text := 'EmergencyContactPhone';
    EmergencyContactPhone.WidthStyle := ssAuto;
    EmergencyContactPhone.WidthPercent := 100.000000000000000000;
    EmergencyContactPhone.DataField := 'Emergencycontacttelephone';
    EmergencyContactPhone.DataSource := OtherDetailsSource;
    EmergencyContactName.SetParentComponent(Self);
    EmergencyContactName.Name := 'EmergencyContactName';
    EmergencyContactName.Left := 16;
    EmergencyContactName.Top := 160;
    EmergencyContactName.Width := 177;
    EmergencyContactName.Height := 22;
    EmergencyContactName.ChildOrder := 4;
    EmergencyContactName.ElementClassName := 'form-control';
    EmergencyContactName.ElementFont := efCSS;
    EmergencyContactName.ElementPosition := epIgnore;
    EmergencyContactName.HeightStyle := ssAuto;
    EmergencyContactName.HeightPercent := 100.000000000000000000;
    EmergencyContactName.TabOrder := 1;
    EmergencyContactName.Text := 'EmergencyContactName';
    EmergencyContactName.WidthStyle := ssAuto;
    EmergencyContactName.WidthPercent := 100.000000000000000000;
    EmergencyContactName.DataField := 'Emergencycontactname';
    EmergencyContactName.DataSource := OtherDetailsSource;
    AcctOtherSaveBtn.SetParentComponent(Self);
    AcctOtherSaveBtn.Name := 'AcctOtherSaveBtn';
    AcctOtherSaveBtn.Left := 313;
    AcctOtherSaveBtn.Top := 264;
    AcctOtherSaveBtn.Width := 96;
    AcctOtherSaveBtn.Height := 25;
    AcctOtherSaveBtn.Caption := 'Save';
    AcctOtherSaveBtn.ChildOrder := 6;
    AcctOtherSaveBtn.ElementClassName := 'btn btn-primary';
    AcctOtherSaveBtn.ElementFont := efCSS;
    AcctOtherSaveBtn.ElementPosition := epIgnore;
    AcctOtherSaveBtn.HeightStyle := ssAuto;
    AcctOtherSaveBtn.HeightPercent := 100.000000000000000000;
    AcctOtherSaveBtn.WidthStyle := ssAuto;
    AcctOtherSaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(AcctOtherSaveBtn, Self, 'OnClick', 'AcctOtherSaveBtnClick');
    AcctOtherCancelBtn.SetParentComponent(Self);
    AcctOtherCancelBtn.Name := 'AcctOtherCancelBtn';
    AcctOtherCancelBtn.Left := 193;
    AcctOtherCancelBtn.Top := 264;
    AcctOtherCancelBtn.Width := 96;
    AcctOtherCancelBtn.Height := 25;
    AcctOtherCancelBtn.Caption := 'Cancel';
    AcctOtherCancelBtn.ChildOrder := 6;
    AcctOtherCancelBtn.ElementClassName := 'btn btn-primary';
    AcctOtherCancelBtn.ElementFont := efCSS;
    AcctOtherCancelBtn.ElementPosition := epIgnore;
    AcctOtherCancelBtn.HeightStyle := ssAuto;
    AcctOtherCancelBtn.HeightPercent := 100.000000000000000000;
    AcctOtherCancelBtn.WidthStyle := ssAuto;
    AcctOtherCancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(AcctOtherCancelBtn, Self, 'OnClick', 'AcctOtherCancelBtnClick');
    OtherDetailsSource.SetParentComponent(Self);
    OtherDetailsSource.Name := 'OtherDetailsSource';
    OtherDetailsSource.Left := 232;
    OtherDetailsSource.Top := 88;
  finally
    DietaryRequirements.AfterLoadDFMValues;
    Mobility.AfterLoadDFMValues;
    OtherNeeds.AfterLoadDFMValues;
    EmergencyContactRelation.AfterLoadDFMValues;
    EmergencyContactPhone.AfterLoadDFMValues;
    EmergencyContactName.AfterLoadDFMValues;
    AcctOtherSaveBtn.AfterLoadDFMValues;
    AcctOtherCancelBtn.AfterLoadDFMValues;
    OtherDetailsSource.AfterLoadDFMValues;
  end;
end;

End.
