unit WebForm.Core;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TCoreWebForm = class(TForm)
  private
    { Private declarations }
    FOnSelfClose: TNotifyEvent;
  protected
    FPopUpForm: TForm;
    procedure SelfClose;
    /// <summary>
    /// This calls the routine in MainForm
    /// </summary>
    /// <param name="AIcon">
    /// A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    /// <summary>
    /// Calls the DisplayMessage method on the MainForm
    /// </summary>
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    /// <summary>
    /// Calls the ConfirmationRequest method on the MainForm
    /// </summary>
    [async]
    function ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons): TModalResult; async;
    [async]
    procedure ShowAPopUp(AFormClass: TFormClass); async;
    procedure ShowWaitMessage;
    procedure HideWaitMessage;
  public
    { Public declarations }
    function CanClose: boolean; virtual;
    procedure ClosePopUp; virtual;
    procedure TearDown; virtual;
    procedure AfterCreated; virtual;
    function PageHashName: string; virtual;
    property OnSelfClose: TNotifyEvent write FOnSelfClose;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  System.StrUtils,
  smx.webcore.types,
  MainDataModule;

{ TCoreWebForm }

procedure TCoreWebForm.AfterCreated;
begin

end;

function TCoreWebForm.CanClose: boolean;
begin
  Result := True;
end;

procedure TCoreWebForm.ClosePopUp;
begin
  if Assigned(FPopUpForm) then
  begin
    FPopUpForm.Close;
    FPopUpForm.Free;
    FPopUpForm := nil;
  end;
end;

function TCoreWebForm.ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons)
  : TModalResult;
begin
  Result := Await(TheMainPage.ConfirmationRequest(AMessage, DlgType, Buttons));
end;

procedure TCoreWebForm.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  TheMainPage.DisplayMessage(AMessage, DlgType);
end;

procedure TCoreWebForm.HideWaitMessage;
begin
  TheMainPage.HideWaitMessage;
end;

function TCoreWebForm.PageHashName: string;
begin
  Result := Self.ClassName.Substring(1);
end;

procedure TCoreWebForm.SelfClose;
begin
  if Assigned(FOnSelfClose) then
    FOnSelfClose(Self);
end;

procedure TCoreWebForm.ShowAPopUp(AFormClass: TFormClass);
var
//  AForm: TWebForm;
  lClass: string;
begin
  FPopUpForm := AFormClass.Create(Self);
  try
    FPopUpForm.Popup := True;
    FPopUpForm.ElementClassName := 'PopUpForm PopUp';
    FPopUpForm.PopupOpacity := 1;
    Await(TForm, FPopUpForm.Load());
    Await(TModalResult, FPopUpForm.Execute);
  finally
    FPopUpForm.Free;
    FPopUpForm := nil;
  end;
end;

procedure TCoreWebForm.ShowAToast(const ATitle, AMessage, AIcon: string);
begin
  TheMainPage.ShowAToast(ATitle, AMessage, AIcon);
end;

procedure TCoreWebForm.ShowWaitMessage;
begin
  TheMainPage.ShowWaitMessage;
end;

procedure TCoreWebForm.TearDown;
begin
  // for descendants
end;

procedure TCoreWebForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'CoreWebForm';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
  finally
  end;
end;

end.
