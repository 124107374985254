Unit HolidayChoice;

Interface

{ TODO : Added Sharing Room? Validate room types available }
Uses
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  Holiday.ReturnTypes,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.DB,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.CDS,
  WEBLib.StdCtrls,
  BaseBooking,
  Vcl.Mask,
  WEBLib.Mask,
  Booking.Web.Shared,
  Cyrus.Enumerations,
  WEBLib.WebCtrls;

Type
  THolidayChoiceForm = Class(TBaseBookingForm)
    VenueSelect: TLookupComboBox;
    HotelSelect: TLookupComboBox;
    HolidaysTable: TDBTableControl;
    HolidaySource: TDataSource;
    PaintersSelect: TComboBox;
    NonPainterSelect: TComboBox;
    GuestCount: TLabel;
    HolidaysSelectLabel: TLabel;
    HotelSelectLabel: TLabel;
    RoomShare: TComboBox;
    RoomInfo: TPanel;
    ShareARoomDiv: TPanel;
    SecondRoomDiv: TPanel;
    RoomTypeSelect: TLookupComboBox;
    RoomTypeSelect2: TLookupComboBox;
    BasicGuestInfo: TPanel;
    RoomTypeSelectLabel: TLabel;
    RoomTypeSelect2Label: TLabel;
    Procedure HolidaysTableClickCell(Sender: TObject; ACol, ARow: Integer);
    Procedure HotelSelectChange(Sender: TObject);
    Procedure NonPainterSelectChange(Sender: TObject);
    Procedure PaintersSelectChange(Sender: TObject);
    Procedure RoomShareChange(Sender: TObject);
    Procedure VenueSelectChange(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
    Procedure WebFormShow(Sender: TObject);
  Private
    FNextPageRequested: Boolean;
    FVenueIndex: Integer;
    Procedure ResetHolidayChoice(Const IncludeVenue: Boolean; Const WaitListStep: TWaitListStep = TWaitListStep.wlNoAdd);

    [async]
    Procedure LoadVenues; async;

    [async]
    Procedure LoadHotels(Const VenueId: Integer); async;

    [async]
    Procedure LoadHolidays(Const HotelId: Integer); async;

    Procedure LoadRooms;
    Procedure LoadRoomShare;
    Function IsValid(Const HighlightControls: Boolean = True): Boolean; Override;
    Procedure CalcGuestCount;
    [async]
    Function HandleWaitList: Boolean; async;
    Procedure AfterWaitingListProcessed(Sender: TObject);
    Procedure HighlightHoliday;
    Procedure RoomAllocationCallBack(Sender: TObject);
    Procedure SelectHoliday(Const HolidayId: Integer);
    Procedure HotelSelected;
  Protected
    Function GetBookingStateStage: TBookingState; Override;
    Procedure UpdateHolidayBookingCosts; Override;
  Public
    { Public declarations }
    [async]
    Procedure InitForm; async; Override;
    [async]
    Function PreviewClose: Boolean; async; Override;
    Function CanCloseBookingPage(Const ANextPage: Boolean = True): TCanProgress; Override;
    Procedure TearDown; Override;
    [async]
    Procedure LoadBooking; async; Override;
    Procedure SaveBooking; Override;
    Function NextPage: TBookingPage; Override;
  protected procedure LoadDFMValues; override; End;

Var
  HolidayChoiceForm: THolidayChoiceForm;

Implementation

{$R *.dfm}
{$DEFINE USE_LOGIN}

Uses
  System.DateUtils,
  Vcl.Grids,
  MainDataModule,
  XData.Web.Client,
  SMX.Web.Document.Utils,
  Holiday.Utils,
  SMX.CRM.Utils;

Procedure THolidayChoiceForm.AfterWaitingListProcessed(Sender: TObject);
var lwaitlist: string;
Begin
  HideWaitMessage;
  MainData.WaitingListProcessedCallBack := Nil;
  ResetHolidayChoice(True, TWaitListStep.wlEmailSent);
  lWaitlist := MainData.HolidayBooking.WaitListStep;
  If MainData.HolidayBooking.WaitList = TWaitListStep.wlEmailSent Then
    DisplayMessage
      ('You have been added to the waiting list. We will be in touch if space becomes available. Thank you');
  MainData.ProcessingState := TProcessingState.psStandard;
  MainData.HolidayBooking.WaitList := TWaitListStep.wlNoAdd;
End;

Procedure THolidayChoiceForm.CalcGuestCount;
Var
  lPainterCount, lNPPCount, lGuestCount: Integer;
Begin
  lPainterCount := PaintersSelect.ItemIndex + 1;

  If lPainterCount = 2 Then
    NonPainterSelect.ItemIndex := 0;

  lNPPCount := NonPainterSelect.ItemIndex;
  lGuestCount := lPainterCount + lNPPCount;

  GuestCount.Caption := lGuestCount.ToString;
  ShareARoomDiv.Visible := lGuestCount = 2;
  SecondRoomDiv.Visible := SecondRoomDiv.Visible And ShareARoomDiv.Visible;

End;

Function THolidayChoiceForm.CanCloseBookingPage(Const ANextPage: Boolean = True): TCanProgress;
Begin

  FNextPageRequested := ANextPage;

  If Not ANextPage Then
    Result := TCanProgress.ccNo
  Else If IsValid(True) Then
    Result := TCanProgress.ccImmediate
  Else
    Result := TCanProgress.ccNo;

  FNextPageRequested := False;

End;

Function THolidayChoiceForm.GetBookingStateStage: TBookingState;
Begin
  Result := TBookingState.HolidaySelected;
End;

Function THolidayChoiceForm.IsValid(Const HighlightControls: Boolean = True): Boolean;
Var
  ControlValid: Boolean;
  lValue, lOtherValue: String;
Begin
  Result := True;

  ControlValid := VenueSelect.Value <> '';
  If HighlightControls Then
    TDocUtils.setControlValidity(VenueSelect.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := MainData.HolidayBooking.HolidayId > 0;
  Result := Result And ControlValid;

  ControlValid := RoomTypeSelect.ItemIndex > -1;
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(RoomTypeSelect.ElementID, Alt_Valid_Check[ControlValid]);

  If SecondRoomDiv.Visible Then
  Begin
    ControlValid := RoomTypeSelect2.ItemIndex > -1;
    Result := Result And ControlValid;
    If HighlightControls Then
      TDocUtils.setControlValidity(RoomTypeSelect2.ElementID, Alt_Valid_Check[ControlValid]);
  End;

End;

procedure THolidayChoiceForm.ResetHolidayChoice(Const IncludeVenue: Boolean;
    Const WaitListStep: TWaitListStep = TWaitListStep.wlNoAdd);
Begin
  MainData.ResetHolidayOptions(WaitListStep);
  HolidaySelectedLabel.Caption := '';
  BasicGuestInfo.Visible := False;
  HotelSelect.LookupValues.Clear;
  HotelSelect.Clear;
  HotelSelect.Value := '';
  HotelSelect.Enabled := True;
  If IncludeVenue Then
    VenueSelect.ItemIndex := -1;
End;

Function THolidayChoiceForm.HandleWaitList: Boolean;
Var
  lRetval: TModalResult;
  lResult, lAddToWaitList: Boolean;
  lMessage: String;
  lOutcome: TRoomsAvailableOutcome;
Begin
  lAddToWaitList := False;
  lOutcome := THolidayUtils.RoomsAvailable;
  Case lOutcome Of
    raoYes:
      Exit(True);
    raoNone:
      Begin
        lMessage :=
          'There are no more spaces available on this holiday currently. Do you want to be added to the waiting list?';
        lRetval := Await(TModalResult, ConfirmationRequest(lMessage, mtConfirmation, [mbYes, mbNo]));
        lAddToWaitList := lRetval = mrYes;
      End;
    raoType:
      Begin
        lMessage := 'All rooms of the type you have chosen have been taken. Do you want to select another room?';
        lRetval := Await(TModalResult, ConfirmationRequest(lMessage, mtConfirmation, [mbYes, mbNo]));
        If lRetval = mrNo Then
        Begin
          lMessage := 'Would you like to be added to the waiting list?';
          lRetval := Await(TModalResult, ConfirmationRequest(lMessage, mtConfirmation, [mbYes, mbNo]));
          lAddToWaitList := (lRetval = mrYes);
        End
        Else
          Exit(False);
      End;
  End;

  If Not lAddToWaitList Then
  Begin
    ResetHolidayChoice(True);
    Exit(False);
  End;

  lResult := Await(MainData.AddToWaitList);

  If lResult Then
  Begin
    ShowWaitMessage;
    MainData.WaitingListProcessedCallBack := AfterWaitingListProcessed;
    MainData.SaveBookingDatasets(TBookingState.WaitingList);
    Result := False;
  End
  Else
    Result := True;

End;

Procedure THolidayChoiceForm.HighlightHoliday;
Var
  Coord: TGridCoord;
  I: Integer;
Begin
  Coord := HolidaysTable.FindCell(MainData.HolidayBooking.HolidayId.ToString, False, True);

  If Coord.Y > -1 Then
    HolidaysTable.RowIndex := Coord.Y;
End;

Procedure THolidayChoiceForm.HolidaysTableClickCell(Sender: TObject; ACol, ARow: Integer);
Begin
  SelectHoliday(HolidaysTable.Cells[0, ARow].ToInteger);
End;

Procedure THolidayChoiceForm.HotelSelected;
Begin
  MainData.LoadHotel(HotelSelect.Value.ToInteger);
  MainData.HolidayBooking.HotelName := HotelSelect.DisplayText;
  LoadHolidays(MainData.HolidayBooking.HotelId);
End;

Procedure THolidayChoiceForm.HotelSelectChange(Sender: TObject);
Begin
  HotelSelected;
End;

Procedure THolidayChoiceForm.InitForm;
Var
  lIsValid: Boolean;
  lButtonState: TButtonState;
  lVenueId, lPainterCount: Integer;
  sVenueId: String;
Begin
  Inherited;
  HolidaysTable.DataSource := HolidaySource;

  Await(LoadVenues);

  CalcGuestCount;

  lIsValid := IsValid(False);
  lButtonState := GetAButtonState(lIsValid);
  UpdateMainForm(wbsHidden, lButtonState, Crumb_Choose_Holiday);

End;

Procedure THolidayChoiceForm.LoadBooking;
Var
  lVenueId, lPainterCount: Integer;
Begin
  If MainData.ChoiceComplete Then
  Begin
    lVenueId := MainData.HolidayBooking.VenueId;
    VenueSelect.ItemIndex := FVenueIndex;
    Await(LoadHotels(lVenueId));
    Await(LoadHolidays(MainData.HolidayBooking.HotelId));
    SelectHoliday(MainData.HolidayBooking.HolidayId);
    HighlightHoliday;
    lPainterCount := MainData.HolidayBooking.Painters;
    PaintersSelect.ItemIndex := MainData.HolidayBooking.Painters - 1;
    NonPainterSelect.ItemIndex := MainData.HolidayBooking.NonPainters;
    LoadRooms;
    LoadRoomShare;
    CalcGuestCount;
    PaintersSelect.SetFocus;
  End;
End;

Procedure THolidayChoiceForm.LoadHolidays(Const HotelId: Integer);
Begin

  RoomInfo.Visible := False;

  Await(MainData.LoadHolidays(HotelId));

  If MainData.HolidayBooking.HolidayId > 0 Then
  Begin
    MainData.HolidaysDataset.Locate('HolidayId', MainData.HolidayBooking.HolidayId, []);
  End
  Else If MainData.HolidaysDataset.IsEmpty Then
    HolidaysSelectLabel.Caption := 'There are no holidays available for your selection'
  Else
  Begin
    HolidaysSelectLabel.Caption := 'Select your holiday';
    If MainData.HolidaysDataset.RecordCount = 1 Then
    Begin
      SelectHoliday(MainData.HolidaysDatasetHolidayId.Value);
      HighlightHoliday;
    End;
  End;

End;

Procedure THolidayChoiceForm.LoadHotels(Const VenueId: Integer);
Var
  I, lIdx, lHotelId: Integer;
  lDescription: String;
Begin

  HotelSelect.LookupValues.Clear;

  Await(MainData.LoadHotels(VenueId));

  lIdx := -1;
  For I := 0 To MainData.Hotels.Count - 1 Do
  Begin
    lHotelId := MainData.Hotels[I].HotelId;
    HotelSelect.LookupValues.AddPair(lHotelId.ToString, MainData.Hotels[I].Description);
    If MainData.HolidayBooking.HotelId = lHotelId Then
      lIdx := I;
  End;

  If MainData.HolidayBooking.HotelId > 0 Then // We've loaded all this
  Begin
    HotelSelect.Value := MainData.HolidayBooking.HotelId.ToString;
    HotelSelect.Enabled := HotelSelect.LookupValues.Count > 1;
    HotelSelect.ItemIndex := lIdx;
    HotelSelectLabel.Caption := 'Your hotel';
    Exit;
  End;

  If HotelSelect.LookupValues.Count = 1 Then
  Begin
    HotelSelect.ItemIndex := 0;
    HotelSelected;
    HotelSelect.Enabled := False;
    HotelSelectLabel.Caption := 'Your hotel';
  End
  Else
  Begin
    HotelSelectLabel.Caption := 'Select your hotel';
    HotelSelect.Enabled := True;
  End;
End;

Procedure THolidayChoiceForm.LoadRooms;
Var
  ARoomDescription: String;
  ARoomCount, ARoomId, DoubleRoomIndex, Idx, FirstIdx, SecondIdx: Integer;
  ARoomType: TRoomType;
Begin
  RoomTypeSelect.LookupValues.Clear;
  RoomTypeSelect2.LookupValues.Clear;
  DoubleRoomIndex := -1;
  FirstIdx := -1;
  SecondIdx := -1;
  Idx := -1;

  ARoomCount := MainData.RoomAllocations.RecordCount;

  MainData.RoomAllocations.First;
  While Not MainData.RoomAllocations.Eof Do
  Begin

    If MainData.RoomAllocationsMaxAllocation.Value > 0 Then
    Begin
      Inc(Idx);

      If MainData.RoomAllocationsRoomTypeId.IsNull Then
        ShowMessage('WTF');

      ARoomId := MainData.RoomAllocationsRoomTypeId.Value;
      ARoomType := TRoomType(ARoomId);

      If MainData.RoomTypes.Locate('Roomtypeid', ARoomId, []) Then
        ARoomDescription := MainData.RoomTypesRoomdescription.Value
      Else
        ARoomDescription := Room_Type_Description[ARoomType];

      RoomTypeSelect.LookupValues.AddPair(ARoomId.ToString, ARoomDescription);

      RoomTypeSelect2.LookupValues.AddPair(ARoomId.ToString, ARoomDescription);

      If ARoomType = TRoomType.Double Then
        DoubleRoomIndex := Idx;

      If ARoomType = MainData.HolidayBooking.RoomType Then
        FirstIdx := Idx;
      If ARoomType = MainData.HolidayBooking.RoomType2 Then
        SecondIdx := Idx;
    End;

    MainData.RoomAllocations.Next;
  End;

  If FirstIdx > -1 Then
  Begin
    RoomTypeSelect.ItemIndex := FirstIdx;
  End
  Else If DoubleRoomIndex > -1 Then
  Begin
    RoomTypeSelect.ItemIndex := DoubleRoomIndex;
    MainData.HolidayBooking.RoomType := TRoomType.Double;
  End
  Else
    RoomTypeSelect.ItemIndex := 0;

  If SecondIdx > -1 Then
    RoomTypeSelect2.ItemIndex := SecondIdx;

  RoomInfo.Visible := True;

  RoomTypeSelect.Enabled := True;
  RoomTypeSelect2.Enabled := True;

  UpdateMainForm(wbsHidden, GetAButtonState(IsValid(False)), Crumb_Choose_Holiday);

End;

Procedure THolidayChoiceForm.LoadRoomShare;
Begin
  If MainData.HolidayBooking.RoomShare Then
    RoomShare.ItemIndex := 1
  Else
    RoomShare.ItemIndex := 0;
  SecondRoomDiv.Visible := RoomShare.ItemIndex = 0;
End;

Procedure THolidayChoiceForm.LoadVenues;
Var
  I, lVenueId, lThisId: Integer;
Begin
  Await(MainData.LoadVenues);
  FVenueIndex := -1;
  lVenueId := MainData.HolidayBooking.VenueId;

  For I := 0 To MainData.Venues.Count - 1 Do
  Begin
    lThisId := MainData.Venues[I].VenueId;
    VenueSelect.LookupValues.AddPair(lThisId.ToString, MainData.Venues[I].VenueName);
    If (lVenueId > 0) And (lThisId = MainData.HolidayBooking.VenueId) Then
      FVenueIndex := I;
  End;
End;

Function THolidayChoiceForm.NextPage: TBookingPage;
Begin
  If MainData.SphinxLogin.IsLoggedIn Then
    Result := TBookingPage.bpCustomerDetails
  Else
  Begin
    MainData.Login(TLoginSource.lsBooking);
  End;
End;

Procedure THolidayChoiceForm.NonPainterSelectChange(Sender: TObject);
Begin
  If PaintersSelect.ItemIndex = 1 Then
    PaintersSelect.ItemIndex := 0;
  CalcGuestCount;
End;

Procedure THolidayChoiceForm.PaintersSelectChange(Sender: TObject);
Begin
  CalcGuestCount;
End;

Function THolidayChoiceForm.PreviewClose: Boolean;
Var
  lHolidayRef, lMessage: String;
Begin
  If MainData.IsLoggedIn Then
  Begin
    lHolidayRef := THolidayUtils.CreateBookingRef(MainData.HolidayBooking.HolidayRef, MainData.CustomerId);
    Result := Await(MainData.BookingExists(lHolidayRef));
    If Result Then
    Begin
      lMessage :=
        'You can only make one booking for the same holiday. To review your booked holiday, go to "My Account"'
{$IFDEF DEBUG} + ' (' + lHolidayRef + ')'{$ENDIF};
      DisplayMessage(lMessage, TMsgDlgType.mtInformation);
      ResetHolidayChoice(True);
      Exit(False);
    End;
  End;

  MainData.HolidayBooking.RoomType := TRoomType(RoomTypeSelect.Value.ToInteger);

  If Not MainData.HolidayBooking.RoomShare Then
    MainData.HolidayBooking.RoomType2 := TRoomType(RoomTypeSelect2.Value.ToInteger)
  Else
    MainData.HolidayBooking.RoomType2 := TRoomType.None;

  Result := Await(HandleWaitList);

End;

Procedure THolidayChoiceForm.RoomAllocationCallBack(Sender: TObject);
Begin
  LoadRooms;
End;

Procedure THolidayChoiceForm.RoomShareChange(Sender: TObject);
Var
  lRoomShare: Boolean;
Begin
  lRoomShare := RoomShare.ItemIndex = 1;
  SecondRoomDiv.Visible := Not lRoomShare;
  If lRoomShare Then
  Begin
    If RoomTypeSelect2.ItemIndex < 0 Then
      RoomTypeSelect2.ItemIndex := RoomTypeSelect.ItemIndex;
  End;

  MainData.HolidayBooking.RoomShare := lRoomShare;

End;

Procedure THolidayChoiceForm.SaveBooking;
Var
  lCount: Integer;
Begin
  Inherited;
  lCount := PaintersSelect.ItemIndex + 1;
  MainData.HolidayBooking.Painters := PaintersSelect.ItemIndex + 1;
  If MainData.HolidayBooking.Painters = 2 Then
    MainData.HolidayBooking.NonPainters := 0
  Else
  Begin
    lCount := NonPainterSelect.ItemIndex;
    MainData.HolidayBooking.NonPainters := NonPainterSelect.ItemIndex;
  End;

  If MainData.IsLoggedIn Then
    MainData.StartBooking
  Else
    MainData.ChoiceComplete := True;
End;

Procedure THolidayChoiceForm.SelectHoliday(Const HolidayId: Integer);
Begin
  MainData.LoadHoliday(HolidayId);
  UpdateHolidayDescription;
  BasicGuestInfo.Visible := True;
  CalcGuestCount;
  // Now we wait for Room Allocations
End;

Procedure THolidayChoiceForm.TearDown;
Begin
  Inherited;
  MainData.RoomAllocationsCallBack := Nil;
End;

Procedure THolidayChoiceForm.UpdateHolidayBookingCosts;
Begin
  PriceBreakDown.Visible := False;
End;

Procedure THolidayChoiceForm.VenueSelectChange(Sender: TObject);
Var
  lSelectedVenue: Integer;
Begin
  If VenueSelect.ItemIndex = -1 Then
    Exit;

  lSelectedVenue := VenueSelect.Value.ToInteger;

  If MainData.HolidayBooking.VenueId <> lSelectedVenue Then
    ResetHolidayChoice(False);
  MainData.SelectVenue(lSelectedVenue);
  LoadHotels(lSelectedVenue);
End;

Procedure THolidayChoiceForm.WebFormCreate(Sender: TObject);
Begin
  HolidaySelectedLabel.Caption := '';
  RoomTypeSelect.ItemIndex := 0;
  FNextPageRequested := False;
  // RoomShare.ItemIndex
  RoomInfo.Visible := False;
  MainData.RoomAllocationsCallBack := RoomAllocationCallBack;
End;

Procedure THolidayChoiceForm.WebFormShow(Sender: TObject);
Begin
  Inherited;
  VenueSelect.SetFocus;
End;

procedure THolidayChoiceForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HolidaysSelectLabel := TLabel.Create('HolidaysSelectLabel');
  HotelSelectLabel := TLabel.Create('HotelSelectLabel');
  VenueSelect := TLookupComboBox.Create('VenueSelect');
  HotelSelect := TLookupComboBox.Create('HotelSelect');
  HolidaysTable := TDBTableControl.Create('HolidaysTable');
  BasicGuestInfo := TPanel.Create('BasicGuestInfo');
  GuestCount := TLabel.Create('GuestCount');
  RoomInfo := TPanel.Create('RoomInfo');
  RoomTypeSelectLabel := TLabel.Create('RoomTypeSelectLabel');
  ShareARoomDiv := TPanel.Create('ShareARoomDiv');
  RoomShare := TComboBox.Create('RoomShare');
  SecondRoomDiv := TPanel.Create('SecondRoomDiv');
  RoomTypeSelect2Label := TLabel.Create('RoomTypeSelect2Label');
  RoomTypeSelect2 := TLookupComboBox.Create('RoomTypeSelect2');
  RoomTypeSelect := TLookupComboBox.Create('RoomTypeSelect');
  PaintersSelect := TComboBox.Create('PaintersSelect');
  NonPainterSelect := TComboBox.Create('NonPainterSelect');
  HolidaySource := TDataSource.Create(Self);

  HolidaysSelectLabel.BeforeLoadDFMValues;
  HotelSelectLabel.BeforeLoadDFMValues;
  HolidaySelectedLabel.BeforeLoadDFMValues;
  VenueSelect.BeforeLoadDFMValues;
  HotelSelect.BeforeLoadDFMValues;
  HolidaysTable.BeforeLoadDFMValues;
  BasicGuestInfo.BeforeLoadDFMValues;
  GuestCount.BeforeLoadDFMValues;
  RoomInfo.BeforeLoadDFMValues;
  RoomTypeSelectLabel.BeforeLoadDFMValues;
  ShareARoomDiv.BeforeLoadDFMValues;
  RoomShare.BeforeLoadDFMValues;
  SecondRoomDiv.BeforeLoadDFMValues;
  RoomTypeSelect2Label.BeforeLoadDFMValues;
  RoomTypeSelect2.BeforeLoadDFMValues;
  RoomTypeSelect.BeforeLoadDFMValues;
  PaintersSelect.BeforeLoadDFMValues;
  NonPainterSelect.BeforeLoadDFMValues;
  HolidaySource.BeforeLoadDFMValues;
  try
    Width := 971;
    Height := 949;
    Color := clBtnFace;
    HolidaysSelectLabel.SetParentComponent(Self);
    HolidaysSelectLabel.Name := 'HolidaysSelectLabel';
    HolidaysSelectLabel.Left := 72;
    HolidaysSelectLabel.Top := 104;
    HolidaysSelectLabel.Width := 125;
    HolidaysSelectLabel.Height := 18;
    HolidaysSelectLabel.Caption := 'Select Your Holiday';
    HolidaysSelectLabel.Color := clBtnFace;
    HolidaysSelectLabel.ElementFont := efCSS;
    HolidaysSelectLabel.ElementPosition := epIgnore;
    HolidaysSelectLabel.HeightStyle := ssAuto;
    HolidaysSelectLabel.HeightPercent := 100.000000000000000000;
    HolidaysSelectLabel.HTMLType := tSPAN;
    HolidaysSelectLabel.WidthStyle := ssAuto;
    HolidaysSelectLabel.WidthPercent := 100.000000000000000000;
    HotelSelectLabel.SetParentComponent(Self);
    HotelSelectLabel.Name := 'HotelSelectLabel';
    HotelSelectLabel.Left := 32;
    HotelSelectLabel.Top := 200;
    HotelSelectLabel.Width := 112;
    HotelSelectLabel.Height := 18;
    HotelSelectLabel.Caption := 'Select Your Hotel';
    HotelSelectLabel.Color := clBtnFace;
    HotelSelectLabel.ElementFont := efCSS;
    HotelSelectLabel.ElementPosition := epIgnore;
    HotelSelectLabel.HeightStyle := ssAuto;
    HotelSelectLabel.HeightPercent := 100.000000000000000000;
    HotelSelectLabel.HTMLType := tSPAN;
    HotelSelectLabel.WidthStyle := ssAuto;
    HotelSelectLabel.WidthPercent := 100.000000000000000000;
    HolidaySelectedLabel.Left := 408;
    HolidaySelectedLabel.Top := 40;
    VenueSelect.SetParentComponent(Self);
    VenueSelect.Name := 'VenueSelect';
    VenueSelect.Left := 72;
    VenueSelect.Top := 24;
    VenueSelect.Width := 273;
    VenueSelect.Height := 22;
    VenueSelect.ElementClassName := ' form-select form-control';
    VenueSelect.ElementFont := efCSS;
    VenueSelect.ElementPosition := epIgnore;
    VenueSelect.HeightStyle := ssAuto;
    VenueSelect.HeightPercent := 100.000000000000000000;
    VenueSelect.WidthStyle := ssAuto;
    VenueSelect.WidthPercent := 100.000000000000000000;
    SetEvent(VenueSelect, Self, 'OnChange', 'VenueSelectChange');
    VenueSelect.ItemIndex := -1;
    HotelSelect.SetParentComponent(Self);
    HotelSelect.Name := 'HotelSelect';
    HotelSelect.Left := 72;
    HotelSelect.Top := 64;
    HotelSelect.Width := 273;
    HotelSelect.Height := 22;
    HotelSelect.ElementClassName := ' form-select form-control';
    HotelSelect.ElementFont := efCSS;
    HotelSelect.ElementPosition := epIgnore;
    HotelSelect.HeightPercent := 100.000000000000000000;
    HotelSelect.TabOrder := 2;
    HotelSelect.WidthStyle := ssAuto;
    HotelSelect.WidthPercent := 100.000000000000000000;
    SetEvent(HotelSelect, Self, 'OnChange', 'HotelSelectChange');
    HotelSelect.ItemIndex := -1;
    HolidaysTable.SetParentComponent(Self);
    HolidaysTable.Name := 'HolidaysTable';
    HolidaysTable.Left := 174;
    HolidaysTable.Top := 160;
    HolidaysTable.Width := 481;
    HolidaysTable.Height := 200;
    HolidaysTable.HeightStyle := ssAuto;
    HolidaysTable.WidthStyle := ssAuto;
    HolidaysTable.BorderColor := clSilver;
    HolidaysTable.ChildOrder := 3;
    HolidaysTable.ElementFont := efCSS;
    HolidaysTable.ElementHeaderClassName := 'thead-light';
    HolidaysTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    HolidaysTable.Footer.ButtonActiveElementClassName := 'btn btn-primary';
    HolidaysTable.Footer.ButtonElementClassName := 'btn btn-light';
    HolidaysTable.Footer.DropDownElementClassName := 'form-control';
    HolidaysTable.Footer.InputElementClassName := 'form-control';
    HolidaysTable.Footer.LinkActiveElementClassName := 'link-primary';
    HolidaysTable.Footer.LinkElementClassName := 'link-secondary';
    HolidaysTable.Footer.ListElementClassName := 'pagination';
    HolidaysTable.Footer.ListItemElementClassName := 'page-item';
    HolidaysTable.Footer.ListLinkElementClassName := 'page-link';
    HolidaysTable.Header.ButtonActiveElementClassName := 'btn btn-primary';
    HolidaysTable.Header.ButtonElementClassName := 'btn btn-light';
    HolidaysTable.Header.DropDownElementClassName := 'form-control';
    HolidaysTable.Header.InputElementClassName := 'form-control';
    HolidaysTable.Header.LinkActiveElementClassName := 'link-primary';
    HolidaysTable.Header.LinkElementClassName := 'link-secondary';
    HolidaysTable.Header.ListElementClassName := 'pagination';
    HolidaysTable.Header.ListItemElementClassName := 'page-item';
    HolidaysTable.Header.ListLinkElementClassName := 'page-link';
    SetEvent(HolidaysTable, Self, 'OnClickCell', 'HolidaysTableClickCell');
    HolidaysTable.Columns.Clear;
    with HolidaysTable.Columns.Add do
    begin
      DataField := 'HolidayId';
      Title := 'Id';
    end;
    with HolidaysTable.Columns.Add do
    begin
      DataField := 'Title';
      Title := 'Holiday';
    end;
    with HolidaysTable.Columns.Add do
    begin
      DataField := 'StartDateFormatted';
      Title := 'From';
    end;
    with HolidaysTable.Columns.Add do
    begin
      DataField := 'EndDateFormatted';
      Title := 'To';
    end;
    with HolidaysTable.Columns.Add do
    begin
      DataField := 'Tutor';
      Title := 'Tutor';
    end;
    with HolidaysTable.Columns.Add do
    begin
      DataField := 'Media';
      Title := 'Media';
    end;
    BasicGuestInfo.SetParentComponent(Self);
    BasicGuestInfo.Name := 'BasicGuestInfo';
    BasicGuestInfo.Left := 351;
    BasicGuestInfo.Top := 424;
    BasicGuestInfo.Width := 473;
    BasicGuestInfo.Height := 321;
    BasicGuestInfo.ElementClassName := 'card';
    BasicGuestInfo.HeightStyle := ssAuto;
    BasicGuestInfo.WidthStyle := ssAuto;
    BasicGuestInfo.BorderColor := clNone;
    BasicGuestInfo.BorderStyle := bsNone;
    BasicGuestInfo.ChildOrder := 13;
    BasicGuestInfo.Color := clWindow;
    BasicGuestInfo.ElementBodyClassName := 'card-body';
    BasicGuestInfo.ElementFont := efCSS;
    BasicGuestInfo.ElementPosition := epIgnore;
    BasicGuestInfo.TabOrder := 4;
    BasicGuestInfo.Visible := False;
    GuestCount.SetParentComponent(BasicGuestInfo);
    GuestCount.Name := 'GuestCount';
    GuestCount.Left := 106;
    GuestCount.Top := 67;
    GuestCount.Width := 8;
    GuestCount.Height := 18;
    GuestCount.Caption := '1';
    GuestCount.ElementFont := efCSS;
    GuestCount.ElementPosition := epIgnore;
    GuestCount.HeightStyle := ssAuto;
    GuestCount.HeightPercent := 100.000000000000000000;
    GuestCount.HTMLType := tSPAN;
    GuestCount.WidthStyle := ssAuto;
    GuestCount.WidthPercent := 100.000000000000000000;
    RoomInfo.SetParentComponent(BasicGuestInfo);
    RoomInfo.Name := 'RoomInfo';
    RoomInfo.Left := 5;
    RoomInfo.Top := 120;
    RoomInfo.Width := 465;
    RoomInfo.Height := 162;
    RoomInfo.ElementClassName := 'card';
    RoomInfo.HeightStyle := ssAuto;
    RoomInfo.WidthStyle := ssAuto;
    RoomInfo.BorderColor := clNone;
    RoomInfo.BorderStyle := bsNone;
    RoomInfo.ChildOrder := 13;
    RoomInfo.Color := clWindow;
    RoomInfo.ElementBodyClassName := 'card-body';
    RoomInfo.ElementFont := efCSS;
    RoomInfo.ElementPosition := epIgnore;
    RoomInfo.TabOrder := 0;
    RoomInfo.Visible := False;
    RoomTypeSelectLabel.SetParentComponent(RoomInfo);
    RoomTypeSelectLabel.Name := 'RoomTypeSelectLabel';
    RoomTypeSelectLabel.Left := 8;
    RoomTypeSelectLabel.Top := 56;
    RoomTypeSelectLabel.Width := 72;
    RoomTypeSelectLabel.Height := 18;
    RoomTypeSelectLabel.Caption := 'Room type';
    RoomTypeSelectLabel.ElementFont := efCSS;
    RoomTypeSelectLabel.ElementPosition := epIgnore;
    RoomTypeSelectLabel.HeightStyle := ssAuto;
    RoomTypeSelectLabel.HeightPercent := 100.000000000000000000;
    RoomTypeSelectLabel.WidthStyle := ssAuto;
    RoomTypeSelectLabel.WidthPercent := 100.000000000000000000;
    ShareARoomDiv.SetParentComponent(RoomInfo);
    ShareARoomDiv.Name := 'ShareARoomDiv';
    ShareARoomDiv.Left := 16;
    ShareARoomDiv.Top := 16;
    ShareARoomDiv.Width := 193;
    ShareARoomDiv.Height := 33;
    ShareARoomDiv.ElementClassName := 'card';
    ShareARoomDiv.HeightStyle := ssAuto;
    ShareARoomDiv.WidthStyle := ssAuto;
    ShareARoomDiv.BorderColor := clNone;
    ShareARoomDiv.BorderStyle := bsNone;
    ShareARoomDiv.ChildOrder := 3;
    ShareARoomDiv.Color := clWindow;
    ShareARoomDiv.ElementBodyClassName := 'card-body';
    ShareARoomDiv.ElementFont := efCSS;
    ShareARoomDiv.ElementPosition := epIgnore;
    ShareARoomDiv.TabOrder := 0;
    ShareARoomDiv.Visible := False;
    RoomShare.SetParentComponent(ShareARoomDiv);
    RoomShare.Name := 'RoomShare';
    RoomShare.Left := 85;
    RoomShare.Top := 4;
    RoomShare.Width := 57;
    RoomShare.Height := 26;
    RoomShare.ElementClassName := 'form-select form-control';
    RoomShare.ElementFont := efCSS;
    RoomShare.HeightStyle := ssAuto;
    RoomShare.HeightPercent := 100.000000000000000000;
    RoomShare.Text := 'Yes';
    RoomShare.WidthStyle := ssAuto;
    RoomShare.WidthPercent := 100.000000000000000000;
    SetEvent(RoomShare, Self, 'OnChange', 'RoomShareChange');
    RoomShare.ItemIndex := 1;
    RoomShare.Items.BeginUpdate;
    try
      RoomShare.Items.Clear;
      RoomShare.Items.Add('No');
      RoomShare.Items.Add('Yes');
    finally
      RoomShare.Items.EndUpdate;
    end;
    SecondRoomDiv.SetParentComponent(RoomInfo);
    SecondRoomDiv.Name := 'SecondRoomDiv';
    SecondRoomDiv.Left := 3;
    SecondRoomDiv.Top := 104;
    SecondRoomDiv.Width := 214;
    SecondRoomDiv.Height := 55;
    SecondRoomDiv.ElementClassName := 'card';
    SecondRoomDiv.HeightStyle := ssAuto;
    SecondRoomDiv.WidthStyle := ssAuto;
    SecondRoomDiv.BorderColor := clNone;
    SecondRoomDiv.BorderStyle := bsNone;
    SecondRoomDiv.ChildOrder := 3;
    SecondRoomDiv.Color := clWindow;
    SecondRoomDiv.ElementBodyClassName := 'card-body';
    SecondRoomDiv.ElementFont := efCSS;
    SecondRoomDiv.ElementPosition := epIgnore;
    SecondRoomDiv.TabOrder := 2;
    SecondRoomDiv.Visible := False;
    RoomTypeSelect2Label.SetParentComponent(SecondRoomDiv);
    RoomTypeSelect2Label.Name := 'RoomTypeSelect2Label';
    RoomTypeSelect2Label.Left := 3;
    RoomTypeSelect2Label.Top := 3;
    RoomTypeSelect2Label.Width := 182;
    RoomTypeSelect2Label.Height := 18;
    RoomTypeSelect2Label.Caption := 'Room Type - Second Guest';
    RoomTypeSelect2Label.ElementFont := efCSS;
    RoomTypeSelect2Label.ElementPosition := epIgnore;
    RoomTypeSelect2Label.HeightStyle := ssAuto;
    RoomTypeSelect2Label.HeightPercent := 100.000000000000000000;
    RoomTypeSelect2Label.WidthStyle := ssAuto;
    RoomTypeSelect2Label.WidthPercent := 100.000000000000000000;
    RoomTypeSelect2.SetParentComponent(SecondRoomDiv);
    RoomTypeSelect2.Name := 'RoomTypeSelect2';
    RoomTypeSelect2.Left := 3;
    RoomTypeSelect2.Top := 24;
    RoomTypeSelect2.Width := 145;
    RoomTypeSelect2.Height := 22;
    RoomTypeSelect2.ElementClassName := 'form-select form-control';
    RoomTypeSelect2.ElementFont := efCSS;
    RoomTypeSelect2.ElementPosition := epIgnore;
    RoomTypeSelect2.HeightStyle := ssAuto;
    RoomTypeSelect2.HeightPercent := 100.000000000000000000;
    RoomTypeSelect2.WidthStyle := ssAuto;
    RoomTypeSelect2.WidthPercent := 100.000000000000000000;
    RoomTypeSelect2.ItemIndex := -1;
    RoomTypeSelect.SetParentComponent(RoomInfo);
    RoomTypeSelect.Name := 'RoomTypeSelect';
    RoomTypeSelect.Left := 3;
    RoomTypeSelect.Top := 76;
    RoomTypeSelect.Width := 145;
    RoomTypeSelect.Height := 22;
    RoomTypeSelect.ElementClassName := 'form-select form-control';
    RoomTypeSelect.ElementFont := efCSS;
    RoomTypeSelect.ElementPosition := epIgnore;
    RoomTypeSelect.HeightStyle := ssAuto;
    RoomTypeSelect.HeightPercent := 100.000000000000000000;
    RoomTypeSelect.TabOrder := 3;
    RoomTypeSelect.WidthStyle := ssAuto;
    RoomTypeSelect.WidthPercent := 100.000000000000000000;
    RoomTypeSelect.ItemIndex := -1;
    PaintersSelect.SetParentComponent(BasicGuestInfo);
    PaintersSelect.Name := 'PaintersSelect';
    PaintersSelect.Left := 106;
    PaintersSelect.Top := 3;
    PaintersSelect.Width := 57;
    PaintersSelect.Height := 26;
    PaintersSelect.ElementClassName := 'form-select form-control';
    PaintersSelect.ElementFont := efCSS;
    PaintersSelect.HeightStyle := ssAuto;
    PaintersSelect.HeightPercent := 100.000000000000000000;
    PaintersSelect.Text := '1';
    PaintersSelect.WidthPercent := 100.000000000000000000;
    SetEvent(PaintersSelect, Self, 'OnChange', 'PaintersSelectChange');
    PaintersSelect.ItemIndex := 0;
    PaintersSelect.Items.BeginUpdate;
    try
      PaintersSelect.Items.Clear;
      PaintersSelect.Items.Add('1');
      PaintersSelect.Items.Add('2');
    finally
      PaintersSelect.Items.EndUpdate;
    end;
    NonPainterSelect.SetParentComponent(BasicGuestInfo);
    NonPainterSelect.Name := 'NonPainterSelect';
    NonPainterSelect.Left := 106;
    NonPainterSelect.Top := 35;
    NonPainterSelect.Width := 57;
    NonPainterSelect.Height := 26;
    NonPainterSelect.ElementClassName := 'form-select form-control';
    NonPainterSelect.ElementFont := efCSS;
    NonPainterSelect.HeightStyle := ssAuto;
    NonPainterSelect.HeightPercent := 100.000000000000000000;
    NonPainterSelect.TabOrder := 1;
    NonPainterSelect.Text := 'No';
    NonPainterSelect.WidthStyle := ssAuto;
    NonPainterSelect.WidthPercent := 100.000000000000000000;
    SetEvent(NonPainterSelect, Self, 'OnChange', 'NonPainterSelectChange');
    NonPainterSelect.ItemIndex := 0;
    NonPainterSelect.Items.BeginUpdate;
    try
      NonPainterSelect.Items.Clear;
      NonPainterSelect.Items.Add('No');
      NonPainterSelect.Items.Add('Yes');
    finally
      NonPainterSelect.Items.EndUpdate;
    end;
    HolidaySource.SetParentComponent(Self);
    HolidaySource.Name := 'HolidaySource';
    HolidaySource.DataSet := MainData.HolidaysDataset;
    HolidaySource.Left := 392;
    HolidaySource.Top := 104;
  finally
    HolidaysSelectLabel.AfterLoadDFMValues;
    HotelSelectLabel.AfterLoadDFMValues;
    HolidaySelectedLabel.AfterLoadDFMValues;
    VenueSelect.AfterLoadDFMValues;
    HotelSelect.AfterLoadDFMValues;
    HolidaysTable.AfterLoadDFMValues;
    BasicGuestInfo.AfterLoadDFMValues;
    GuestCount.AfterLoadDFMValues;
    RoomInfo.AfterLoadDFMValues;
    RoomTypeSelectLabel.AfterLoadDFMValues;
    ShareARoomDiv.AfterLoadDFMValues;
    RoomShare.AfterLoadDFMValues;
    SecondRoomDiv.AfterLoadDFMValues;
    RoomTypeSelect2Label.AfterLoadDFMValues;
    RoomTypeSelect2.AfterLoadDFMValues;
    RoomTypeSelect.AfterLoadDFMValues;
    PaintersSelect.AfterLoadDFMValues;
    NonPainterSelect.AfterLoadDFMValues;
    HolidaySource.AfterLoadDFMValues;
  end;
end;

End.
