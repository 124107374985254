Unit CustomerDetails;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseBooking,
  Vcl.Controls,
  WEBLib.DropDown,
  WEBLib.Country,
  Data.DB,
  WEBLib.DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.ExtCtrls,
  Cyrus.Enumerations,
  Booking.Web.Shared,
  WEBLib.WebCtrls,
  Holiday.ReturnTypes;

Type
  TCustomerDetailsForm = Class(TBaseBookingForm)
    TitleEdit: TDBEdit;
    CustomerSource: TDataSource;
    FirstnameEdit: TDBEdit;
    LastnameEdit: TDBEdit;
    PhoneEdit: TDBEdit;
    MobileEdit: TDBEdit;
    VaccinatedEdit: TDBLookupComboBox;
    LastVaccinationEdit: TDBDateTimePicker;
    EmailEdit: TDBEdit;
    RegistrationHeading: TLabel;
    AddressPanel: TPanel;
    Add1Edit: TDBEdit;
    Add2Edit: TDBEdit;
    TownEdit: TDBEdit;
    PostcodeEdit: TDBEdit;
    Add3Edit: TDBEdit;
    SameAddressLabel: TLabel;
    SameAddressDetails: TDBCheckBox;
    GuestAddressSelect: TPanel;
    CountryEdit: TCountryComboBox;
    CountyEdit: TDBEdit;
    LastVaccinatedPanel: TPanel;
    Procedure CountryEditExit(Sender: TObject);
    Procedure EmailEditExit(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
    Procedure FirstnameEditExit(Sender: TObject);
    Procedure LastVaccinationEditChange(Sender: TObject);
    Procedure LastVaccinationEditExit(Sender: TObject);
    Procedure MobileEditExit(Sender: TObject);
    Procedure MobileEditKeyPress(Sender: TObject; Var Key: Char);
    Procedure PhoneEditExit(Sender: TObject);
    Procedure PhoneEditKeyPress(Sender: TObject; Var Key: Char);
    Procedure SameAddressDetailsClick(Sender: TObject);
    Procedure TitleEditExit(Sender: TObject);
    Procedure VaccinatedEditChange(Sender: TObject);
    Procedure VaccinatedEditExit(Sender: TObject);
    Procedure WebFormShow(Sender: TObject);
  Private
    { Private declarations }
    FCustomerType: TCustomerType;
    FDisplayHighlighting: Boolean;
    FOriginalProcessingState: TProcessingState;
    FMinVaccinatedDate: TDate;
    FWaitListStep: TWaitListStep;
    Procedure SetCustomerType(Const Value: TCustomerType);
    Procedure LoadCustomerCallBack(Sender: TObject);
    Function GetDataset: TDataset;
    Procedure SetDataSet(Const Value: TDataset);

    Property CustomerType: TCustomerType Read FCustomerType Write SetCustomerType;
    Property Dataset: TDataset Read GetDataset Write SetDataSet;
  Protected
    Function GetBookingStateStage: TBookingState; Override;
    Procedure SetPageLoading(Const Value: Boolean); Override;
    Procedure SetDisplayingAs(Const Value: TBookingPage); Override;
    Procedure UpdateHolidayBookingCosts; Override;
    Function IsValid(Const HighlightControls: Boolean = True): Boolean; Override;

  Public
    { Public declarations }
    [async]
    Procedure InitForm; async; Override;
    Procedure TearDown; Override;
    Function CanCloseBookingPage(Const ANextPage: Boolean = True): TCanProgress; Override;
    [async]
    Procedure LoadBooking; async; Override;
    Procedure AfterLoaded; Override;
    Procedure SaveBooking; Override;
    Function NextPage: TBookingPage; Override;
    Function PreviousPage: TBookingPage; Override;
  protected procedure LoadDFMValues; override; End;

Var
  CustomerDetailsForm: TCustomerDetailsForm;

Implementation

{$R *.dfm}

Uses
  System.Rtti,
  System.DateUtils,
  MainDataModule,
  SMX.Web.Document.Utils,
  SMX.Web.Utils,
  SMX.CRM.Utils;

Procedure TCustomerDetailsForm.WebFormCreate(Sender: TObject);
Begin
  Inherited;
  FDisplayHighlighting := False;
End;

Procedure TCustomerDetailsForm.AfterLoaded;
Begin
  Inherited;

End;

Function TCustomerDetailsForm.CanCloseBookingPage(Const ANextPage: Boolean = True): TCanProgress;
Begin
  If Not ANextPage Then
    Result := TCanProgress.ccImmediate
  Else If IsValid Then
    Result := TCanProgress.ccWait
  Else
    Result := TCanProgress.ccNo;
End;

Procedure TCustomerDetailsForm.CountryEditExit(Sender: TObject);
Begin
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.EmailEditExit(Sender: TObject);
Begin
  TDocUtils.setControlValidity(EmailEdit.ElementID, Alt_Valid_Check[TCRMUtils.IsEmailValid(EmailEdit.Text)]);
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.FirstnameEditExit(Sender: TObject);
Var
  lValid: Boolean;
Begin
  If FDisplayHighlighting Then
  Begin
    lValid := Trim(TDBEdit(Sender).Text) <> '';
    TDocUtils.setControlValidity(TDBEdit(Sender).ElementID, Alt_Valid_Check[lValid]);
  End;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Function TCustomerDetailsForm.GetBookingStateStage: TBookingState;
Begin
  If FCustomerType = TCustomerType.Primary Then
  Begin
    If MainData.HolidayBooking.WaitList In [TWaitListStep.wlAdd, TWaitListStep.wlComplete,
      TWaitListStep.wlEmailSent] Then
      Result := TBookingState.WaitingList
    Else
      Result := TBookingState.CustomerIdentified;
  End
  Else
    Result := TBookingState.GuestIdentified;
End;

Function TCustomerDetailsForm.GetDataset: TDataset;
Begin
  Result := CustomerSource.Dataset;
End;

Procedure TCustomerDetailsForm.InitForm;
Begin
  Inherited;
  VaccinatedEdit.LookupValues.AddPair('1', 'Yes');
  VaccinatedEdit.LookupValues.AddPair('0', 'No');
  FMinVaccinatedDate := EncodeDate(2021, 01, 01);
  LastVaccinationEdit.ElementHandle.setAttribute('min', FormatDateTime('yyyy-mm-dd', FMinVaccinatedDate));
  LastVaccinationEdit.ElementHandle.setAttribute('max', FormatDateTime('yyyy-mm-dd', Today));

  FOriginalProcessingState := MainData.ProcessingState;
  FWaitListStep := MainData.HolidayBooking.WaitList;

  MainData.CustomerOpenCallBack := LoadCustomerCallBack;
  If FCustomerType = TCustomerType.Primary Then
    UpdateMainForm(wbsEnabled, wbsDisabled, Crumb_Customer_Details)
  Else // CustomerType = secondary
    UpdateMainForm(wbsEnabled, wbsDisabled, Crumb_Guest_Details);

End;

Function TCustomerDetailsForm.IsValid(Const HighlightControls: Boolean = True): Boolean;
Var
  ControlValid: Boolean;
Begin
  Result := True;

  ControlValid := Trim(FirstnameEdit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(FirstnameEdit.ElementID, Alt_Valid_Check[ControlValid]);

  ControlValid := Trim(LastnameEdit.Text) <> '';
  Result := Result And ControlValid;
  If HighlightControls Then
    TDocUtils.setControlValidity(LastnameEdit.ElementID, Alt_Valid_Check[ControlValid]);

  If AddressPanel.Visible Then
  Begin

    ControlValid := Trim(Add1Edit.Text) <> '';
    Result := Result And ControlValid;
    If HighlightControls Then
      TDocUtils.setControlValidity(Add1Edit.ElementID, Alt_Valid_Check[ControlValid]);

    ControlValid := Trim(TownEdit.Text) <> '';
    Result := Result And ControlValid;
    If HighlightControls Then
      TDocUtils.setControlValidity(TownEdit.ElementID, Alt_Valid_Check[ControlValid]);

    ControlValid := Trim(PostcodeEdit.Text) <> '';
    Result := Result And ControlValid;
    If HighlightControls Then
      TDocUtils.setControlValidity(PostcodeEdit.ElementID, Alt_Valid_Check[ControlValid]);

  End;

  If CustomerType = TCustomerType.Primary Then
  Begin
    ControlValid := (Trim(PhoneEdit.Text) <> '') Or (Trim(MobileEdit.Text) <> '');
    Result := Result And ControlValid;
  End
  Else
    ControlValid := True;

  If Not ControlValid Then
  Begin
    If HighlightControls Then
    Begin
      TDocUtils.setControlValidity(PhoneEdit.ElementID, Alt_Valid_Check[ControlValid]);
      TDocUtils.setControlValidity(MobileEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;
  End
  Else
  Begin
    If (Trim(PhoneEdit.Text) <> '') Then
    Begin
      ControlValid := TUtils.IsPhoneNumberValid(PhoneEdit.Text);
      Result := Result And ControlValid;
      If HighlightControls Then
        TDocUtils.setControlValidity(PhoneEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;

    If (Trim(MobileEdit.Text) <> '') Then
    Begin
      ControlValid := TUtils.IsPhoneNumberValid(MobileEdit.Text);
      Result := Result And ControlValid;
      If HighlightControls Then
        TDocUtils.setControlValidity(MobileEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;
  End;

  If EmailEdit.Enabled Then
  Begin
    If (CustomerType = TCustomerType.Primary) Or (EmailEdit.Text <> '') Then
    Begin
      ControlValid := TCRMUtils.IsEmailValid(EmailEdit.Text);
      If HighlightControls Then
        TDocUtils.setControlValidity(EmailEdit.ElementID, Alt_Valid_Check[ControlValid]);
    End;
  End;

  If (VaccinatedEdit.Value = '1') Then
  Begin
    ControlValid := (LastVaccinationEdit.Date > FMinVaccinatedDate) And (LastVaccinationEdit.Date < Today);
    Result := Result And ControlValid;
    If HighlightControls Then
      TDocUtils.setControlValidity(LastVaccinationEdit.ElementID, Alt_Valid_Check[ControlValid]);
  End;
End;

Procedure TCustomerDetailsForm.LastVaccinationEditChange(Sender: TObject);
Var
  lValid: Boolean;
Begin
  If FDisplayHighlighting Then
  Begin
    lValid := (LastVaccinationEdit.Date > FMinVaccinatedDate) And (LastVaccinationEdit.Date < Today);
    TDocUtils.setControlValidity(TDBEdit(Sender).ElementID, Alt_Valid_Check[lValid]);
  End;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.LastVaccinationEditExit(Sender: TObject);
Begin
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.LoadBooking;
Var
  lState: TsmxRecordState;
Begin
  Inherited;

  Case FCustomerType Of
    TCustomerType.Primary:
      lState := MainData.FindCustomer;
    TCustomerType.Secondary:
      lState := MainData.FindGuest;
  End;

  If lState <> TsmxRecordState.rsLoading Then
  Begin
    LoadCustomerCallBack(self);
  End;
  // Else Handled by Callback

End;

Procedure TCustomerDetailsForm.LoadCustomerCallBack(Sender: TObject);
Var
  lDatasetName: String;
Begin

  If FCustomerType = TCustomerType.Primary Then
  Begin
    Dataset := MainData.CustomerDataset;
    EmailEdit.Enabled := False;
  End
  Else
  Begin
    Dataset := MainData.Customer2;
  End;

  MainData.EditDataset(Dataset);
  Dataset.EnableControls;
  lDatasetName := Dataset.Name;

  CountryEdit.Text := GetCountryByCode(Dataset.FieldByName('CountryCode').AsString);
  LastVaccinatedPanel.Visible := Dataset.FieldByName('FullyVaccinated').AsInteger = 1;
  AddressPanel.Visible := Not Dataset.FieldByName('AddressSameAsParent').Value;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
  TitleEdit.SetFocus;
End;

Procedure TCustomerDetailsForm.MobileEditExit(Sender: TObject);
Begin
  TDocUtils.setControlValidity(MobileEdit.ElementID, Alt_Valid_Check[TUtils.IsPhoneNumberValid(MobileEdit.Text)]);
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.MobileEditKeyPress(Sender: TObject; Var Key: Char);
Begin
  If Not(Key In ['0' .. '9', #32, '+', '-']) Then
    Key := #0;
End;

Function TCustomerDetailsForm.NextPage: TBookingPage;
Begin

  If (CustomerType = TCustomerType.Primary) Then
  Begin
    If (FWaitListStep In [TWaitListStep.wlAdd, TWaitListStep.wlComplete]) Then
      Result := TBookingPage.bpChooseHoliday
    Else
      Result := TBookingPage.bpCustomerRequirements
  End
  Else
    Result := TBookingPage.bpGuestRequirements;
End;

Procedure TCustomerDetailsForm.PhoneEditExit(Sender: TObject);
Begin
  TDocUtils.setControlValidity(PhoneEdit.ElementID, Alt_Valid_Check[TUtils.IsPhoneNumberValid(PhoneEdit.Text)]);
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.PhoneEditKeyPress(Sender: TObject; Var Key: Char);
Begin
  If Not(Key In ['0' .. '9', #32, '+', '-']) Then
    Key := #0;
End;

Function TCustomerDetailsForm.PreviousPage: TBookingPage;
Begin
  If (CustomerType = TCustomerType.Primary) Then
    Result := TBookingPage.bpChooseHoliday
  Else // secondary Guest
    Result := TBookingPage.bpCustomerRequirements;
End;

Procedure TCustomerDetailsForm.SameAddressDetailsClick(Sender: TObject);
Begin
  // This is before the value of the
  AddressPanel.Visible := Not SameAddressDetails.Checked;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.SaveBooking;
Var
  lCountryCode: String;
Begin
  Inherited;
  lCountryCode := GetCountryCode(CountryEdit.Text);
  If Dataset.FieldByName('CountryCode').AsString <> lCountryCode Then
  Begin
    If Not(Dataset.State In dsEditModes) Then
      Dataset.Edit;
    Dataset.FieldByName('CountryCode').AsString := lCountryCode;
  End;

  If FCustomerType = TCustomerType.Primary Then
    MainData.SaveCustomer
  Else
    MainData.SaveCustomer2;
End;

Procedure TCustomerDetailsForm.SetCustomerType(Const Value: TCustomerType);
Begin
  FCustomerType := Value;

  If FCustomerType = TCustomerType.Primary Then
  Begin
    RegistrationHeading.Caption := 'Your Details';
    GuestAddressSelect.Visible := False;
    GuestAddressSelect.TabStop := False;
    SameAddressDetails.TabStop := False;
  End
  Else
  Begin
    RegistrationHeading.Caption := 'Your Guest''s Details';
    GuestAddressSelect.Visible := True;
    GuestAddressSelect.TabStop := False;
    SameAddressLabel.Caption := Format('The address is the same as mine (%s)', [MainData.CustomerName]);
  End;
End;

Procedure TCustomerDetailsForm.SetDataSet(Const Value: TDataset);
Begin
  CustomerSource.Dataset := Value;
End;

Procedure TCustomerDetailsForm.SetDisplayingAs(Const Value: TBookingPage);
Begin
  Inherited;
  CustomerType := CustomerTypeForPage[DisplayingAs];
End;

Procedure TCustomerDetailsForm.SetPageLoading(Const Value: Boolean);
Begin
  Inherited;
  If Assigned(Dataset) And (Not Value) And (Not(Dataset.State = dsInsert)) Then
    UpdateMainForm(wbsEnabled, GetAButtonState(IsValid(False)), Crumb_Customer_Details);
End;

Procedure TCustomerDetailsForm.TearDown;
Begin
  Inherited;
  MainData.CustomerOpenCallBack := Nil;
End;

Procedure TCustomerDetailsForm.TitleEditExit(Sender: TObject);
Begin
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.UpdateHolidayBookingCosts;
Begin
  PriceBreakDown.Visible := False;
End;

Procedure TCustomerDetailsForm.VaccinatedEditChange(Sender: TObject);
Begin
  LastVaccinatedPanel.Visible := Dataset.FieldByName('FullyVaccinated').AsInteger = 1;
  If LastVaccinatedPanel.Visible Then
    LastVaccinationEdit.SetFocus;
End;

Procedure TCustomerDetailsForm.VaccinatedEditExit(Sender: TObject);
Begin
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TCustomerDetailsForm.WebFormShow(Sender: TObject);
Begin
  Inherited;
  TitleEdit.SetFocus;
  FDisplayHighlighting := True;
End;

procedure TCustomerDetailsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RegistrationHeading := TLabel.Create('RegistrationHeading');
  TitleEdit := TDBEdit.Create('TitleEdit');
  FirstnameEdit := TDBEdit.Create('FirstnameEdit');
  LastnameEdit := TDBEdit.Create('LastnameEdit');
  MobileEdit := TDBEdit.Create('MobileEdit');
  VaccinatedEdit := TDBLookupComboBox.Create('VaccinatedEdit');
  EmailEdit := TDBEdit.Create('EmailEdit');
  AddressPanel := TPanel.Create('AddressPanel');
  Add1Edit := TDBEdit.Create('Add1Edit');
  Add2Edit := TDBEdit.Create('Add2Edit');
  TownEdit := TDBEdit.Create('TownEdit');
  PostcodeEdit := TDBEdit.Create('PostcodeEdit');
  Add3Edit := TDBEdit.Create('Add3Edit');
  CountryEdit := TCountryComboBox.Create('CountryEdit');
  CountyEdit := TDBEdit.Create('CountyEdit');
  PhoneEdit := TDBEdit.Create('PhoneEdit');
  GuestAddressSelect := TPanel.Create('GuestAddressSelect');
  SameAddressLabel := TLabel.Create('SameAddressLabel');
  SameAddressDetails := TDBCheckBox.Create('SameAddressDetails');
  LastVaccinatedPanel := TPanel.Create('LastVaccinatedPanel');
  LastVaccinationEdit := TDBDateTimePicker.Create('LastVaccinationEdit');
  CustomerSource := TDataSource.Create(Self);

  HolidaySelectedLabel.BeforeLoadDFMValues;
  RegistrationHeading.BeforeLoadDFMValues;
  PriceBreakDown.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  FirstnameEdit.BeforeLoadDFMValues;
  LastnameEdit.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  VaccinatedEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  AddressPanel.BeforeLoadDFMValues;
  Add1Edit.BeforeLoadDFMValues;
  Add2Edit.BeforeLoadDFMValues;
  TownEdit.BeforeLoadDFMValues;
  PostcodeEdit.BeforeLoadDFMValues;
  Add3Edit.BeforeLoadDFMValues;
  CountryEdit.BeforeLoadDFMValues;
  CountyEdit.BeforeLoadDFMValues;
  PhoneEdit.BeforeLoadDFMValues;
  GuestAddressSelect.BeforeLoadDFMValues;
  SameAddressLabel.BeforeLoadDFMValues;
  SameAddressDetails.BeforeLoadDFMValues;
  LastVaccinatedPanel.BeforeLoadDFMValues;
  LastVaccinationEdit.BeforeLoadDFMValues;
  CustomerSource.BeforeLoadDFMValues;
  try
    Height := 715;
    SetEvent(Self, 'OnExit', 'PhoneEditExit');
    HolidaySelectedLabel.Left := 79;
    HolidaySelectedLabel.Top := 40;
    RegistrationHeading.SetParentComponent(Self);
    RegistrationHeading.Name := 'RegistrationHeading';
    RegistrationHeading.Left := 80;
    RegistrationHeading.Top := 64;
    RegistrationHeading.Width := 132;
    RegistrationHeading.Height := 18;
    RegistrationHeading.Caption := 'Registration Heading';
    RegistrationHeading.ElementFont := efCSS;
    RegistrationHeading.ElementPosition := epIgnore;
    RegistrationHeading.HeightStyle := ssAuto;
    RegistrationHeading.HeightPercent := 100.000000000000000000;
    RegistrationHeading.WidthStyle := ssAuto;
    RegistrationHeading.WidthPercent := 100.000000000000000000;
    PriceBreakDown.Left := 352;
    PriceBreakDown.Top := 330;
    PriceBreakDown.TabOrder := 5;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 72;
    TitleEdit.Top := 88;
    TitleEdit.Width := 121;
    TitleEdit.Height := 22;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.Text := 'TitleEdit';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TitleEdit, Self, 'OnExit', 'TitleEditExit');
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := CustomerSource;
    FirstnameEdit.SetParentComponent(Self);
    FirstnameEdit.Name := 'FirstnameEdit';
    FirstnameEdit.Left := 72;
    FirstnameEdit.Top := 112;
    FirstnameEdit.Width := 121;
    FirstnameEdit.Height := 22;
    FirstnameEdit.ElementClassName := 'form-control';
    FirstnameEdit.ElementFont := efCSS;
    FirstnameEdit.ElementPosition := epIgnore;
    FirstnameEdit.HeightStyle := ssAuto;
    FirstnameEdit.HeightPercent := 100.000000000000000000;
    FirstnameEdit.TabOrder := 1;
    FirstnameEdit.Text := 'FirstnameEdit';
    FirstnameEdit.WidthStyle := ssAuto;
    FirstnameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FirstnameEdit, Self, 'OnExit', 'FirstnameEditExit');
    FirstnameEdit.DataField := 'FirstName';
    FirstnameEdit.DataSource := CustomerSource;
    LastnameEdit.SetParentComponent(Self);
    LastnameEdit.Name := 'LastnameEdit';
    LastnameEdit.Left := 72;
    LastnameEdit.Top := 137;
    LastnameEdit.Width := 121;
    LastnameEdit.Height := 22;
    LastnameEdit.ElementClassName := 'form-control';
    LastnameEdit.ElementFont := efCSS;
    LastnameEdit.ElementPosition := epIgnore;
    LastnameEdit.HeightStyle := ssAuto;
    LastnameEdit.HeightPercent := 100.000000000000000000;
    LastnameEdit.TabOrder := 2;
    LastnameEdit.Text := 'LastnameEdit';
    LastnameEdit.WidthStyle := ssAuto;
    LastnameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(LastnameEdit, Self, 'OnExit', 'FirstnameEditExit');
    LastnameEdit.DataField := 'LastName';
    LastnameEdit.DataSource := CustomerSource;
    MobileEdit.SetParentComponent(Self);
    MobileEdit.Name := 'MobileEdit';
    MobileEdit.Left := 56;
    MobileEdit.Top := 542;
    MobileEdit.Width := 121;
    MobileEdit.Height := 22;
    MobileEdit.ElementClassName := 'form-control';
    MobileEdit.ElementFont := efCSS;
    MobileEdit.ElementPosition := epIgnore;
    MobileEdit.HeightStyle := ssAuto;
    MobileEdit.HeightPercent := 100.000000000000000000;
    MobileEdit.MaxLength := 25;
    MobileEdit.TabOrder := 5;
    MobileEdit.Text := 'MobileEdit';
    MobileEdit.WidthStyle := ssAuto;
    MobileEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MobileEdit, Self, 'OnExit', 'MobileEditExit');
    SetEvent(MobileEdit, Self, 'OnKeyPress', 'MobileEditKeyPress');
    MobileEdit.DataField := 'MobileNumber';
    MobileEdit.DataSource := CustomerSource;
    VaccinatedEdit.SetParentComponent(Self);
    VaccinatedEdit.Name := 'VaccinatedEdit';
    VaccinatedEdit.Left := 280;
    VaccinatedEdit.Top := 476;
    VaccinatedEdit.Width := 145;
    VaccinatedEdit.Height := 22;
    VaccinatedEdit.ChildOrder := 14;
    VaccinatedEdit.ElementClassName := 'form-select';
    VaccinatedEdit.ElementFont := efCSS;
    VaccinatedEdit.ElementPosition := epIgnore;
    VaccinatedEdit.HeightPercent := 100.000000000000000000;
    VaccinatedEdit.TabOrder := 8;
    VaccinatedEdit.WidthPercent := 100.000000000000000000;
    SetEvent(VaccinatedEdit, Self, 'OnChange', 'VaccinatedEditChange');
    SetEvent(VaccinatedEdit, Self, 'OnExit', 'VaccinatedEditExit');
    VaccinatedEdit.DataField := 'FullyVaccinated';
    VaccinatedEdit.DataSource := CustomerSource;
    VaccinatedEdit.ListSource := MainData.DummyLookUpSource;
    EmailEdit.SetParentComponent(Self);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 56;
    EmailEdit.Top := 570;
    EmailEdit.Width := 121;
    EmailEdit.Height := 22;
    EmailEdit.CharCase := wecLowerCase;
    EmailEdit.ElementClassName := 'form-control';
    EmailEdit.ElementFont := efCSS;
    EmailEdit.ElementPosition := epIgnore;
    EmailEdit.HeightStyle := ssAuto;
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.TabOrder := 7;
    EmailEdit.Text := 'EmailEdit';
    EmailEdit.WidthStyle := ssAuto;
    EmailEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailEdit, Self, 'OnExit', 'EmailEditExit');
    EmailEdit.DataField := 'Email';
    EmailEdit.DataSource := CustomerSource;
    AddressPanel.SetParentComponent(Self);
    AddressPanel.Name := 'AddressPanel';
    AddressPanel.Left := 41;
    AddressPanel.Top := 222;
    AddressPanel.Width := 201;
    AddressPanel.Height := 259;
    AddressPanel.ElementClassName := 'card';
    AddressPanel.HeightStyle := ssAuto;
    AddressPanel.WidthStyle := ssAuto;
    AddressPanel.BorderStyle := bsNone;
    AddressPanel.Color := clWindow;
    AddressPanel.ElementBodyClassName := 'card-body';
    AddressPanel.ElementFont := efCSS;
    AddressPanel.ElementPosition := epIgnore;
    AddressPanel.ShowCaption := False;
    AddressPanel.TabOrder := 1;
    Add1Edit.SetParentComponent(AddressPanel);
    Add1Edit.Name := 'Add1Edit';
    Add1Edit.Left := 32;
    Add1Edit.Top := 26;
    Add1Edit.Width := 121;
    Add1Edit.Height := 22;
    Add1Edit.ElementClassName := 'form-control';
    Add1Edit.ElementFont := efCSS;
    Add1Edit.ElementPosition := epIgnore;
    Add1Edit.HeightStyle := ssAuto;
    Add1Edit.HeightPercent := 100.000000000000000000;
    Add1Edit.Text := 'Add1Edit';
    Add1Edit.WidthStyle := ssAuto;
    Add1Edit.WidthPercent := 100.000000000000000000;
    SetEvent(Add1Edit, Self, 'OnExit', 'FirstnameEditExit');
    Add1Edit.DataField := 'AddressLine1';
    Add1Edit.DataSource := CustomerSource;
    Add2Edit.SetParentComponent(AddressPanel);
    Add2Edit.Name := 'Add2Edit';
    Add2Edit.Tag := 5;
    Add2Edit.Left := 32;
    Add2Edit.Top := 50;
    Add2Edit.Width := 121;
    Add2Edit.Height := 22;
    Add2Edit.ElementClassName := 'form-control';
    Add2Edit.ElementFont := efCSS;
    Add2Edit.ElementPosition := epIgnore;
    Add2Edit.HeightStyle := ssAuto;
    Add2Edit.HeightPercent := 100.000000000000000000;
    Add2Edit.TabOrder := 1;
    Add2Edit.Text := 'Add2Edit';
    Add2Edit.WidthStyle := ssAuto;
    Add2Edit.WidthPercent := 100.000000000000000000;
    Add2Edit.DataField := 'AddressLine2';
    Add2Edit.DataSource := CustomerSource;
    TownEdit.SetParentComponent(AddressPanel);
    TownEdit.Name := 'TownEdit';
    TownEdit.Left := 32;
    TownEdit.Top := 100;
    TownEdit.Width := 121;
    TownEdit.Height := 22;
    TownEdit.ElementClassName := 'form-control';
    TownEdit.ElementFont := efCSS;
    TownEdit.ElementPosition := epIgnore;
    TownEdit.HeightStyle := ssAuto;
    TownEdit.HeightPercent := 100.000000000000000000;
    TownEdit.TabOrder := 3;
    TownEdit.Text := 'TownEdit';
    TownEdit.WidthStyle := ssAuto;
    TownEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TownEdit, Self, 'OnExit', 'FirstnameEditExit');
    TownEdit.DataField := 'Town';
    TownEdit.DataSource := CustomerSource;
    PostcodeEdit.SetParentComponent(AddressPanel);
    PostcodeEdit.Name := 'PostcodeEdit';
    PostcodeEdit.Left := 32;
    PostcodeEdit.Top := 156;
    PostcodeEdit.Width := 121;
    PostcodeEdit.Height := 22;
    PostcodeEdit.CharCase := wecUpperCase;
    PostcodeEdit.ElementClassName := 'form-control';
    PostcodeEdit.ElementFont := efCSS;
    PostcodeEdit.ElementPosition := epIgnore;
    PostcodeEdit.HeightStyle := ssAuto;
    PostcodeEdit.HeightPercent := 100.000000000000000000;
    PostcodeEdit.TabOrder := 5;
    PostcodeEdit.Text := 'PostcodeEdit';
    PostcodeEdit.WidthStyle := ssAuto;
    PostcodeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PostcodeEdit, Self, 'OnExit', 'FirstnameEditExit');
    PostcodeEdit.DataField := 'PostCode';
    PostcodeEdit.DataSource := CustomerSource;
    Add3Edit.SetParentComponent(AddressPanel);
    Add3Edit.Name := 'Add3Edit';
    Add3Edit.Left := 32;
    Add3Edit.Top := 75;
    Add3Edit.Width := 121;
    Add3Edit.Height := 22;
    Add3Edit.ElementClassName := 'form-control';
    Add3Edit.ElementFont := efCSS;
    Add3Edit.ElementPosition := epIgnore;
    Add3Edit.HeightStyle := ssAuto;
    Add3Edit.HeightPercent := 100.000000000000000000;
    Add3Edit.TabOrder := 2;
    Add3Edit.Text := 'Add3Edit';
    Add3Edit.WidthStyle := ssAuto;
    Add3Edit.WidthPercent := 100.000000000000000000;
    Add3Edit.DataField := 'AddressLine3';
    Add3Edit.DataSource := CustomerSource;
    CountryEdit.SetParentComponent(AddressPanel);
    CountryEdit.Name := 'CountryEdit';
    CountryEdit.Left := 31;
    CountryEdit.Top := 184;
    CountryEdit.Width := 145;
    CountryEdit.Height := 26;
    CountryEdit.ElementClassName := 'form-select';
    CountryEdit.ElementFont := efCSS;
    CountryEdit.ElementPosition := epIgnore;
    CountryEdit.HeightStyle := ssAuto;
    CountryEdit.HeightPercent := 100.000000000000000000;
    CountryEdit.TabOrder := 6;
    CountryEdit.WidthStyle := ssAuto;
    CountryEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CountryEdit, Self, 'OnExit', 'CountryEditExit');
    CountryEdit.ItemIndex := -1;
    CountyEdit.SetParentComponent(AddressPanel);
    CountyEdit.Name := 'CountyEdit';
    CountyEdit.Left := 32;
    CountyEdit.Top := 128;
    CountyEdit.Width := 121;
    CountyEdit.Height := 22;
    CountyEdit.ElementClassName := 'form-control';
    CountyEdit.ElementFont := efCSS;
    CountyEdit.ElementPosition := epIgnore;
    CountyEdit.HeightStyle := ssAuto;
    CountyEdit.HeightPercent := 100.000000000000000000;
    CountyEdit.TabOrder := 4;
    CountyEdit.Text := 'CountyEdit';
    CountyEdit.WidthStyle := ssAuto;
    CountyEdit.WidthPercent := 100.000000000000000000;
    CountyEdit.DataField := 'County';
    CountyEdit.DataSource := CustomerSource;
    PhoneEdit.SetParentComponent(AddressPanel);
    PhoneEdit.Name := 'PhoneEdit';
    PhoneEdit.Left := 32;
    PhoneEdit.Top := 216;
    PhoneEdit.Width := 121;
    PhoneEdit.Height := 22;
    PhoneEdit.ElementClassName := 'form-control';
    PhoneEdit.ElementFont := efCSS;
    PhoneEdit.ElementPosition := epIgnore;
    PhoneEdit.HeightStyle := ssAuto;
    PhoneEdit.HeightPercent := 100.000000000000000000;
    PhoneEdit.MaxLength := 25;
    PhoneEdit.TabOrder := 5;
    PhoneEdit.Text := 'PhoneEdit';
    PhoneEdit.WidthStyle := ssAuto;
    PhoneEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PhoneEdit, Self, 'OnExit', 'PhoneEditExit');
    SetEvent(PhoneEdit, Self, 'OnKeyPress', 'PhoneEditKeyPress');
    PhoneEdit.DataField := 'HomeTelephoneNumber';
    PhoneEdit.DataSource := CustomerSource;
    GuestAddressSelect.SetParentComponent(Self);
    GuestAddressSelect.Name := 'GuestAddressSelect';
    GuestAddressSelect.Left := 280;
    GuestAddressSelect.Top := 168;
    GuestAddressSelect.Width := 289;
    GuestAddressSelect.Height := 60;
    GuestAddressSelect.ElementClassName := 'card';
    GuestAddressSelect.HeightStyle := ssAuto;
    GuestAddressSelect.WidthStyle := ssAuto;
    GuestAddressSelect.BorderColor := clNone;
    GuestAddressSelect.BorderStyle := bsNone;
    GuestAddressSelect.ChildOrder := 13;
    GuestAddressSelect.Color := clWindow;
    GuestAddressSelect.ElementBodyClassName := 'card-body';
    GuestAddressSelect.ElementFont := efCSS;
    GuestAddressSelect.ElementPosition := epIgnore;
    GuestAddressSelect.TabOrder := 0;
    GuestAddressSelect.Visible := False;
    SameAddressLabel.SetParentComponent(GuestAddressSelect);
    SameAddressLabel.Name := 'SameAddressLabel';
    SameAddressLabel.Left := 16;
    SameAddressLabel.Top := 2;
    SameAddressLabel.Width := 122;
    SameAddressLabel.Height := 18;
    SameAddressLabel.Caption := 'SameAddressLabel';
    SameAddressLabel.ElementFont := efCSS;
    SameAddressLabel.ElementPosition := epIgnore;
    SameAddressLabel.HeightStyle := ssAuto;
    SameAddressLabel.HeightPercent := 100.000000000000000000;
    SameAddressLabel.WidthStyle := ssAuto;
    SameAddressLabel.WidthPercent := 100.000000000000000000;
    SameAddressDetails.SetParentComponent(GuestAddressSelect);
    SameAddressDetails.Name := 'SameAddressDetails';
    SameAddressDetails.Left := 88;
    SameAddressDetails.Top := 26;
    SameAddressDetails.Width := 113;
    SameAddressDetails.Height := 22;
    SameAddressDetails.TabStop := False;
    SameAddressDetails.ElementClassName := 'form-check-input';
    SameAddressDetails.ElementFont := efCSS;
    SameAddressDetails.ElementPosition := epIgnore;
    SameAddressDetails.HeightStyle := ssAuto;
    SameAddressDetails.HeightPercent := 100.000000000000000000;
    SameAddressDetails.WidthStyle := ssAuto;
    SameAddressDetails.WidthPercent := 100.000000000000000000;
    SetEvent(SameAddressDetails, Self, 'OnClick', 'SameAddressDetailsClick');
    SameAddressDetails.DataField := 'AddressSameAsParent';
    SameAddressDetails.DataSource := CustomerSource;
    LastVaccinatedPanel.SetParentComponent(Self);
    LastVaccinatedPanel.Name := 'LastVaccinatedPanel';
    LastVaccinatedPanel.Left := 280;
    LastVaccinatedPanel.Top := 504;
    LastVaccinatedPanel.Width := 289;
    LastVaccinatedPanel.Height := 60;
    LastVaccinatedPanel.ElementClassName := 'card';
    LastVaccinatedPanel.HeightStyle := ssAuto;
    LastVaccinatedPanel.WidthStyle := ssAuto;
    LastVaccinatedPanel.BorderColor := clNone;
    LastVaccinatedPanel.BorderStyle := bsNone;
    LastVaccinatedPanel.ChildOrder := 13;
    LastVaccinatedPanel.Color := clWindow;
    LastVaccinatedPanel.ElementBodyClassName := 'card-body';
    LastVaccinatedPanel.ElementFont := efCSS;
    LastVaccinatedPanel.ElementPosition := epIgnore;
    LastVaccinatedPanel.TabOrder := 8;
    LastVaccinatedPanel.Visible := False;
    LastVaccinationEdit.SetParentComponent(LastVaccinatedPanel);
    LastVaccinationEdit.Name := 'LastVaccinationEdit';
    LastVaccinationEdit.Left := 39;
    LastVaccinationEdit.Top := 14;
    LastVaccinationEdit.Width := 170;
    LastVaccinationEdit.Height := 22;
    LastVaccinationEdit.ElementClassName := 'form-control';
    LastVaccinationEdit.HeightStyle := ssAuto;
    LastVaccinationEdit.WidthStyle := ssAuto;
    LastVaccinationEdit.BorderStyle := bsSingle;
    LastVaccinationEdit.ChildOrder := 15;
    LastVaccinationEdit.Color := clWhite;
    LastVaccinationEdit.Date := 45408.701627916660000000;
    LastVaccinationEdit.ElementFont := efCSS;
    LastVaccinationEdit.ElementPosition := epIgnore;
    LastVaccinationEdit.Role := '';
    LastVaccinationEdit.TabOrder := 15;
    LastVaccinationEdit.Text := '';
    SetEvent(LastVaccinationEdit, Self, 'OnChange', 'LastVaccinationEditChange');
    SetEvent(LastVaccinationEdit, Self, 'OnExit', 'LastVaccinationEditExit');
    LastVaccinationEdit.DataField := 'DateOfLastVaccination';
    LastVaccinationEdit.DataSource := CustomerSource;
    CustomerSource.SetParentComponent(Self);
    CustomerSource.Name := 'CustomerSource';
    CustomerSource.Left := 512;
    CustomerSource.Top := 48;
  finally
    HolidaySelectedLabel.AfterLoadDFMValues;
    RegistrationHeading.AfterLoadDFMValues;
    PriceBreakDown.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    FirstnameEdit.AfterLoadDFMValues;
    LastnameEdit.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    VaccinatedEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    AddressPanel.AfterLoadDFMValues;
    Add1Edit.AfterLoadDFMValues;
    Add2Edit.AfterLoadDFMValues;
    TownEdit.AfterLoadDFMValues;
    PostcodeEdit.AfterLoadDFMValues;
    Add3Edit.AfterLoadDFMValues;
    CountryEdit.AfterLoadDFMValues;
    CountyEdit.AfterLoadDFMValues;
    PhoneEdit.AfterLoadDFMValues;
    GuestAddressSelect.AfterLoadDFMValues;
    SameAddressLabel.AfterLoadDFMValues;
    SameAddressDetails.AfterLoadDFMValues;
    LastVaccinatedPanel.AfterLoadDFMValues;
    LastVaccinationEdit.AfterLoadDFMValues;
    CustomerSource.AfterLoadDFMValues;
  end;
end;

End.
