unit Sphinx.Consts;

interface

{$IFNDEF PAS2JS}
{$R Sphinx.Languages.res}
{$ENDIF}

type
  AuthorizeRequestParams = class //fi:C104
  public const
    Scope = 'scope';
    ResponseType = 'response_type';
    ClientId = 'client_id';
    RedirectUri = 'redirect_uri';
    State = 'state';
    ResponseMode = 'response_mode';
    Nonce = 'nonce';
    Display = 'display';
    Prompt = 'prompt';
    MaxAge = 'max_age';
    UiLocales = 'ui_locales';
    IdTokenHint = 'id_token_hint';
    LoginHint = 'login_hint';
    AcrValues = 'acr_values';
    CodeChallenge = 'code_challenge';
    CodeChallengeMethod = 'code_challenge_method';
    Request = 'request';
    RequestUri = 'request_uri';
    Resource = 'resource';
  end;

  AuthorizeResponseParams = class //fi:C104
  public const
    Scope = 'scope';
    Code = 'code';
    AccessToken = 'access_token';
    ExpiresIn = 'expires_in';
    TokenType = 'token_type';
    RefreshToken = 'refresh_token';
    IdentityToken = 'id_token';
    State = 'state';
    SessionState = 'session_state';
    Issuer = 'iss';
    Error = 'error';
    ErrorDescription = 'error_description';
  end;

  AuthorizeErrors = class //fi:C104
  public const
    // OAuth2 errors
    InvalidRequest = 'invalid_request';
    UnauthorizedClient = 'unauthorized_client';
    AccessDenied = 'access_denied';
    UnsupportedResponseType = 'unsupported_response_type';
    InvalidScope = 'invalid_scope';
    ServerError = 'server_error';
    TemporarilyUnavailable = 'temporarily_unavailable';

    // OIDC errors
    InteractionRequired = 'interaction_required';
    LoginRequired = 'login_required';
    AccountSelectionRequired = 'account_selection_required';
    ConsentRequired = 'consent_required';
    InvalidRequestUri = 'invalid_request_uri';
    InvalidRequestObject = 'invalid_request_object';
    RequestNotSupported = 'request_not_supported';
    RequestUriNotSupported = 'request_uri_not_supported';
    RegistrationNotSupported = 'registration_not_supported';

    // resource indicator spec
    InvalidTarget = 'invalid_target';
  end;

  TokenRequestParams = class
  public const
    GrantType = 'grant_type';
    RedirectUri = 'redirect_uri';
    ClientId = 'client_id';
    ClientSecret = 'client_secret';
    ClientAssertion = 'client_assertion';
    ClientAssertionType = 'client_assertion_type';
    Assertion = 'assertion';
    Code = 'code';
    RefreshToken = 'refresh_token';
    Scope = 'scope';
    UserName = 'username';
    Password = 'password';
    CodeVerifier = 'code_verifier';
    TokenType = 'token_type';
    Algorithm = 'alg';
    Key = 'key';
    DeviceCode = 'device_code';

    // token exchange
    Resource = 'resource';
    Audience = 'audience';
    RequestedTokenType = 'requested_token_type';
    SubjectToken = 'subject_token';
    SubjectTokenType = 'subject_token_type';
    ActorToken = 'actor_token';
    ActorTokenType = 'actor_token_type';
  end;

  TokenResponseParams = class
  public const
    AccessToken = 'access_token';
    ExpiresIn = 'expires_in';
    TokenType = 'token_type';
    RefreshToken = 'refresh_token';
    IdentityToken = 'id_token';
    Error = 'error';
    ErrorDescription = 'error_description';
    BearerTokenType = 'Bearer';
    IssuedTokenType = 'issued_token_type';
    Scope = 'scope';
  end;

  TokenErrors = class
  public const
    InvalidRequest = 'invalid_request';
    InvalidClient = 'invalid_client';
    InvalidGrant = 'invalid_grant';
    UnauthorizedClient = 'unauthorized_client';
    UnsupportedGrantType = 'unsupported_grant_type';
    UnsupportedResponseType = 'unsupported_response_type';
    InvalidScope = 'invalid_scope';
    AuthorizationPending = 'authorization_pending';
    AccessDenied = 'access_denied';
    SlowDown = 'slow_down';
    ExpiredToken = 'expired_token';
    InvalidTarget = 'invalid_target';
  end;

  TokenRequestTypes = class
  public const
    Bearer = 'bearer';
  end;

  TokenTypes = class
  public const
    AccessToken = 'access_token';
    IdentityToken = 'id_token';
    RefreshToken = 'refresh_token';
  end;

  GrantTypes = class //fi:C104
  public const
    Password = 'password';
    AuthorizationCode = 'authorization_code';
    ClientCredentials = 'client_credentials';
    RefreshToken = 'refresh_token';
    Implicit = 'implicit';
    Hybrid = 'hybrid';
  end;

  ResponseTypes = class //fi:C104
  public const
    Code = 'code';
    Token = 'token';
    IdToken = 'id_token';
    IdTokenToken = 'id_token token';
    CodeIdToken = 'code id_token';
    CodeToken = 'code token';
    CodeIdTokenToken = 'code id_token token';
  end;

  ResponseModes = class //fi:C104
  public const
    FormPost = 'form_post';
    Query = 'query';
    Fragment = 'fragment';
  end;

  CodeChallengeMethods = class //fi_C104
  public const
    Plain = 'plain';
    Sha256 = 'S256';
  end;

  StorageItemTypes = class //fi:C104
  public const
    AuthorizationCode = 'authorization_code';
    ReferenceToken = 'reference_token';
    RefreshToken = 'refresh_token';
    UserConsent = 'user_consent';
    DeviceCode = 'device_code';
    UserCode = 'user_code';
    LoginTransaction = 'login_transaction';
  end;

  StandardScopes = class //fi:C104
  public const
    OpenId = 'openid';
    Profile = 'profile';
    Email = 'email';
    Address = 'address';
    Phone = 'phone';
    OfflineAccess = 'offline_access';
    AllSupported = 'openid profile email address phone';
  end;

  JwtClaimNames = class //fi:C104
  public const
    // JWT standard claims (RFC 7519)
    Issuer = 'iss';
    Subject = 'sub';
    Audience = 'aud';
    Expiration = 'exp';
    NotBefore = 'nbf';
    IssuedAt = 'iat';
    JwtId = 'jti';

    // OpenID standard claims (OIDC Core 1.0, section 5.1)
    Name = 'name';
    GivenName = 'given_name';
    FamilyName = 'family_name';
    MiddleName = 'middle_name';
    NickName = 'nickname';
    PreferredUserName = 'preferred_username';
    Profile = 'profile';
    Picture = 'picture';
    WebSite = 'website';
    Email = 'email';
    EmailVerified = 'email_verified';
    Gender = 'gender';
    BirthDate = 'birthdate';
    ZoneInfo = 'zoneinfo';
    Locale = 'locale';
    PhoneNumber = 'phone_number';
    PhoneNumberVerified = 'phone_number_verified';
    Address = 'address';
    UpdatedAt = 'updated_at';

    // OpenID standard claims (OIDC Core 1.0, section 2)
    AuthenticationTime = 'auth_time';
    Nonce = 'nonce';
    AuthenticationContextClassReference = 'acr';
    AuthenticationMethod = 'amr';
    AuthorizedParty = 'azp';

    // OpenID standard claims (OIDC Core 1.0, section 3.3.2.11 and other flows)
    AccessTokenHash = 'at_hash';
    AuthorizationCodeHash = 'c_hash';

    // OpenID claims (OIDC Financial-grade API security 1.0, section 5.1.1)
    StateHash = 's_hash';

    // RFC 8693 (https://www.rfc-editor.org/rfc/rfc8693.html#name-json-web-token-claims-and-i)
    Actor = 'act';
    Scope = 'scope';
    ClientId = 'client_id';
    MayAct = 'may_act';

    // Sphinx-specific claims
    SphinxAuthMethod = 'https://tmssoftware.com/sphinx/authmethod';
  end;

  MetadataProperties = class //fi:C104
  public const
    Issuer = 'issuer';
    AuthorizationEndpoint = 'authorization_endpoint';
    TokenEndpoint = 'token_endpoint';
    UserInfoEndpoint = 'userinfo_endpoint';
    JwksUri = 'jwks_uri';
    RegistrationEndpoint = 'registration_endpoint';
    ScopesSupported = 'scopes_supported';
    ResponseTypesSupported = 'response_types_supported';
    ResponseModesSupported = 'response_modes_supported';
    GrantTypesSupported = 'grant_types_supported';
    AcrValuesSupported = 'acr_values_supported';
    SubjectTypesSupported = 'subject_types_supported';
    IdTokenSigningAlgorithmsSupported = 'id_token_signing_alg_values_supported';
    IdTokenEncryptionAlgorithmsSupported = 'id_token_encryption_alg_values_supported';
    IdTokenEncryptionEncValuesSupported = 'id_token_encryption_enc_values_supported';
    UserInfoSigningAlgorithmsSupported = 'userinfo_signing_alg_values_supported';
    UserInfoEncryptionAlgorithmsSupported = 'userinfo_encryption_alg_values_supported';
    UserInfoEncryptionEncValuesSupported = 'userinfo_encryption_enc_values_supported';
    RequestObjectSigningAlgorithmsSupported = 'request_object_signing_alg_values_supported';
    RequestObjectEncryptionAlgorithmsSupported = 'request_object_encryption_alg_values_supported';
    RequestObjectEncryptionEncValuesSupported = 'request_object_encryption_enc_values_supported';
    TokenEndpointAuthenticationMethodsSupported = 'token_endpoint_auth_methods_supported';
    TokenEndpointAuthSigningAlgorithmsSupported = 'token_endpoint_auth_signing_alg_values_supported';
    DisplayValuesSupported = 'display_values_supported';
    ClaimTypesSupported = 'claim_types_supported';
    ClaimsSupported = 'claims_supported';
    ServiceDocumentation = 'service_documentation';
    ClaimsLocalesSupported = 'claims_locales_supported';
    UILocalesSupported = 'ui_locales_supported';
    ClaimsParameterSupported = 'claims_parameter_supported';
    RequestParameterSupported = 'request_parameter_supported';
    RequestUriParameterSupported = 'request_uri_parameter_supported';
    RequireRequestUriRegistration = 'require_request_uri_registration';
    OpPolicyUri = 'op_policy_uri';
    OpTosUri = 'op_tos_uri';
  end;

  ErrorCodes = class //fi:C104
  public const
    InvalidUserId = 'InvalidUserId';
    ValidationError = 'ValidationError';
    ValidationFailed = 'ValidationFailed';
    InvalidUserRegistrationInfo = 'InvalidUserRegistrationInfo';

    ConcurrencyFailure = 'ConcurrencyFailure';
    DefaultError = 'DefaultError';
    EmailAlreadyConfirmed = 'EmailAlreadyConfirmed';
    EmailAlreadyAssociated = 'EmailAlreadyAssociated';
    EmailEmpty = 'EmailEmpty';
    InvalidEmail = 'InvalidEmail';
    InvalidPassword = 'InvalidPassword';
    InvalidToken = 'InvalidToken';
    InvalidUserName = 'InvalidUserName';
    UserNotAllowed = 'UserNotAllowed';
    InvalidPasswordRetries = 'InvalidPasswordRetries';
    PasswordRequiresDigit = 'PasswordRequiresDigit';
    PasswordRequiresLower = 'PasswordRequiresLower';
    PasswordRequiresNonAlphanumeric = 'PasswordRequiresNonAlphanumeric';
    PasswordRequiresUpper = 'PasswordRequiresUpper';
    PasswordTooShort = 'PasswordTooShort';
    PhoneNumberAlreadyConfirmed = 'PhoneNumberAlreadyConfirmed';
    PhoneNumberAlreadyAssociated = 'PhoneNumberAlreadyAssociated';
    PhoneNumberEmpty = 'PhoneNumberEmpty';
    EmailNotConfirmed = 'EmailNotConfirmed';
    PhoneNumberNotConfirmed = 'PhoneNumberNotConfirmed';
    TokenBuilderRequired = 'TokenBuilderRequired';
    UserAlreadyHasPassword = 'UserAlreadyHasPassword';
    UserLockedOut = 'UserLockedOut';
    UserNameAlreadyInUse = 'UserNameAlreadyInUse';
    UserNameEmpty = 'UserNameEmpty';
    UserNotFound = 'UserNotFound';
    InvalidTransactionId = 'InvalidTransactionId';
    TransactionExpired = 'TransactionExpired';
    MissingTransactionStorage = 'MissingTransactionStorage';
    SelfRegistrationForbidden = 'SelfRegistrationForbidden';
    TwoFactorRequired = 'TwoFactorRequired';

    GenerateEmailConfirmedRequired = 'GenerateEmailConfirmedRequired';
    GeneratePhoneNumberConfirmedRequired = 'GeneratePhoneNumberConfirmedRequired';
    GeneratePasswordResetTokenRequired = 'GeneratePasswordResetTokenRequired';
  end;

  Defaults = class //fi:C104
  public const
    AuthorizationCodeLifetime = 300; // 5 minutes
    AccessTokenLifetime = 7200; // 2 hours
    IdTokenLifetime = 300; // 5 minutes
    LoginTimeout = 5 * 60; // 5 minutes
    LockoutTimeout = 5 * 60; // 5 minutes
    LoginSessionLifetime = 3600 * 24; // 24 hours
  end;

  CookieNames = class //fi:C104
  public const
    AuthSession = 'sphinx';
    AuthTwoFactor = 'sphinx.twofactor';
    AuthTwoFactorRemember = 'sphinx.twofactor.remember';
    AuthExternal = 'sphinx.external';
  end;

//  ClaimNames = class //fi:C104
//  public const
//  end;

const
  // Global constants
  cSphinxModelName = 'Biz.Sphinx';

resourcestring
  // Internal exceptions
  SInvalidConfigClass = 'Class %s does not inherit from %s';

  // Error messages
  SUserValidationFailed = 'Validation failed for user "%s"';
  SPasswordValidationFailed = 'Password for user "%s" is not valid';
  SSigningKeyNotProvided = 'Could not sign JWT: signing key not provided';
  SSigningKeyNotProvidedExplanation = 'Signing key not provided. ' +
    'Use event TSphinxServer.OnGetSigningKey to provide a key used to sign tokens.';
  SInvalidUserId = 'Invalid user identification';
  SInvalidUserRegistrationInfo = 'Invalid user registration information';

  // Error from error describer
  SConcurrencyFailure = 'Optimistic concurrency failure, object has been modified';
  SDefaultError = 'An unknown failure has occurred';
  SEmailAlreadyConfirmed = 'User email "%s" is already confirmed';
  SEmailAlreadyAssociated = 'Email "%s" is already in use';
  SEmailEmpty = 'User email must have a value';
  SInvalidEmail = 'Email "%s" is invalid';
  SInvalidPassword = 'Invalid password';
  SInvalidToken = 'Invalid token';
  SInvalidUserName = 'User name "%s" is invalid';
  SUserNotAllowed = 'User is not allowed to login';
  SInvalidPasswordRetries = 'Your password is incorrect. You have %d more attempt(s) before your account gets locked out';
  SPasswordRequiresDigit = 'Passwords must have at least one digit (0-9)';
  SPasswordRequiresLower = 'Passwords must have at least one lowercase (a-z)';
  SPasswordRequiresNonAlphanumeric = 'Passwords must have at least one non alphanumeric character';
  SPasswordRequiresUpper = 'Passwords must have at least one uppercase (A-Z)';
  SPasswordTooShort = 'Passwords must be at least %d characters';
  SPhoneNumberAlreadyConfirmed = 'User phone number "%s" is already confirmed';
  SPhoneNumberAlreadyAssociated = 'Phone number "%s" is already in use';
  SPhoneNumberEmpty = 'User phone number must have a value';
  SEmailNotConfirmed = 'User email is not confirmed';
  SPhoneNumberNotConfirmed = 'User phone number is not confirmed';
  STokenBuilderRequired = 'Internal token builder is required';
  SUserAlreadyHasPassword = 'User already has a password set';
  SUserLockedOut = 'Your account has been locked out for %d minute(s) due to multiple ' +
    'use of invalid credentials. Try again in %d minute(s)';
  SUserNameAlreadyInUse = 'User name "%s" is already in use';
  SUserNameEmpty= 'User name must have a value';
  SUserNotFound = 'User "%s" not found';
  SSelfRegistrationForbidden = 'Self registration is not allowed';

  // general strings
  SEmptySecurityStamp = 'User security stamp cannot be empty';
  SPasswordCannotBeEmpty = 'Password for user "%s" cannot be empty';
  SMissingDBConnection = 'Database connection not available';

  // Authorization service strings
  SMissingParameter = 'Missing required parameter: %s';
  SMissingClientProvider = 'Client provider is missing';
  SMissingTokenBuilder = 'Token builder is missing';
  SMissingLoginAppConfig = 'Login app config is missing';
  SMissingTransactionStorage = 'Transaction storage is missing';
  SMissingOpenIdScope = 'Missing openid scope';
  SInvalidParameter = 'Parameter %s is invalid: %s';

  SInvalidTransactionId = 'Invalid transaction id';
  STransactionExpired = 'Transaction is expired';
  SInvalidResponseModeForResponseType = 'Invalid response_mode for response_type';
  SUnknownClient = 'Unknown client: %s';
  SUnsupportedResponseType = 'Unsupported response type: %s';
  SUnsupportedGrantType = 'Unsupported grant type: %s';
  SInvalidClientCredentials = 'Invalid client credentials';
  SGrantTypeNotAllowed = 'Client not authorized for grant type %s';
  SClientScopeNotGranted = 'Client has not been granted scopes: %s';
  STokenNotGenerated = 'Token not generated';
  SIdTokenNotGenerated = 'Identity token not generated';
  SGenerateEmailConfirmedRequired = 'Event handler OnGenerateEmailConfirmationRequired is required';
  SGeneratePhoneNumberConfirmedRequired = 'Event handler OnGeneratePhoneNumberConfirmationRequired is required';
  SGeneratePasswordResetTokenRequired = 'Event handler OnGeneratePasswordResetTokenRequired is required';

  // Token endpoint strings
  SInvalidAuthorizationCode = 'Invalid authorization_code';
  SAuthorizationCodeExpired = 'Authorization code expired';
  SRedirectUriMismatch = 'Redirect URI mismatch';

  // Discourse Sso
  SInvalidSignature = 'Invalid signature';

  // Web client
  SAuthStateNotExpected = 'OAuth callback not expected';
  SInvalidCallbackUrl = 'Invalid OAuth callback URL';
  SMissingNonceInState = 'Missing nonce in state';
  SInvalidClaim = 'Claim "%s" is invalid: %s';
  SMissingClaim = 'Missing claim "%s"';
  SIdTokenInvalidPayload = 'Invalid payload in id_token';
  SIdTokenExpired = 'Id token is expired';
  SInvalidState = 'Invalid state: %s';
  SOAuthError = 'OAuth error: %s - %s';
  SOAuthErrorWithoutDescription = 'OAuth error: %s';

  // Desktop client
  SApplicationAuthorized = 'Application successfully authorized';
  SBrowserCanBeClosed = 'You can close this browser window';
  SApplicationNotAuthorized = 'Application authorization failed';


implementation

end.

