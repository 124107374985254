Unit Payment.Utils;

Interface

Uses
  Cyrus.Enumerations;

Type

  TPaymentUtils = Class
    Class Procedure PaymentFailed(AProvider: TPaymentProvider; Const AAmount: Double; AReason: TPaymentState;
      Const AReference: String; Const Outcome: String; Const APaymentLevel: TPaymentLevel);
    Class Procedure PaymentSuccess(AProvider: TPaymentProvider; Const AAmount: Double;
      Const AReference, Outcome: String; Const APaymentLevel: TPaymentLevel);
    Class Function BookingStateStage(Const AProvider: TPaymentProvider): TBookingState;

    Class Procedure WritePaymentLevel(const  APaymentLevel: TPaymentLevel);
    Class Function ReadPaymentLevel: TPaymentLevel;
    Class Procedure ClearPaymentLevel;
  End;

Implementation

Uses
  MainDataModule;

{ TPaymentUtils }

Class Function TPaymentUtils.BookingStateStage(Const AProvider: TPaymentProvider): TBookingState;
Begin
  Case AProvider Of
    TPaymentProvider.None:
      ;
    TPaymentProvider.PayPal, TPaymentProvider.Revolut:
      Begin
        If MainData.BookingDatasetBalanceDue.Value = 0 Then
          Result := TBookingState.PaidInFull
        Else If MainData.BookingDatasetDepositDue.Value = MainData.BookingDatasetTotalPaid.Value Then
          Result := TBookingState.DepositPaid
        Else
          Result := TBookingState.PartPaid;
      End;
    TPaymentProvider.BACS, TPaymentProvider.Cheque:
      Result := TBookingState.Confirmed;
  End;
End;

class procedure TPaymentUtils.ClearPaymentLevel;
begin
  MainData.SessionValue['PayLevel'] := '';
end;

Class Procedure TPaymentUtils.PaymentFailed(AProvider: TPaymentProvider; Const AAmount: Double; AReason: TPaymentState;
  Const AReference: String; Const Outcome: String; Const APaymentLevel: TPaymentLevel);
Var
  lPayProvider, lLevel: String;
Begin
  MainData.PaymentProvider := AProvider;
  lPayProvider := Payment_Provider[AProvider];
  lLevel := Payment_Level[APaymentLevel];

  If MainData.ActivateDataset(MainData.Payments) Or MainData.Payments.IsEmpty Then
    MainData.Payments.Edit
  Else
    MainData.Payments.Insert;
  MainData.PaymentsProvider.Value := Payment_Provider[AProvider];
  MainData.PaymentsPaymentType.Value := Payment_Type[TPaymentType.Payment];
  MainData.PaymentsPaymentLevel.Value := Payment_Level[APaymentLevel];
  MainData.PaymentsAmount.Value := AAmount;
  MainData.PaymentsCurrencyCode.Value := 'GBP';
  MainData.PaymentsState.Value := Payment_State[AReason];
  MainData.PaymentsReference.Value := AReference;
  MainData.PaymentsOutcome.Value := Outcome;
  MainData.Payments.Post;
  MainData.Payments.ApplyUpdates;
End;

Class Procedure TPaymentUtils.PaymentSuccess(AProvider: TPaymentProvider; Const AAmount: Double;
  Const AReference, Outcome: String; Const APaymentLevel: TPaymentLevel);
Var
  lPayProvider, lLevel: String;
Begin
  MainData.PaymentProvider := AProvider;
  lPayProvider := Payment_Provider[AProvider];
  lLevel := Payment_Level[APaymentLevel];

  If MainData.ActivateDataset(MainData.Payments) Or MainData.Payments.IsEmpty Then
    MainData.Payments.Edit
  Else
    MainData.Payments.Insert;

  MainData.PaymentsProvider.Value := Payment_Provider[AProvider];
  MainData.PaymentsPaymentType.Value := Payment_Type[TPaymentType.Payment];
  MainData.PaymentsPaymentLevel.Value := Payment_Level[APaymentLevel];
  MainData.PaymentsAmount.Value := AAmount;
  MainData.PaymentsCurrencyCode.Value := 'GBP'; // JS.ToString(FOrder['currency']);
  MainData.PaymentsState.Value := Payment_State[TPaymentState.Success];
  MainData.PaymentsReference.Value := AReference;
  MainData.PaymentsOutcome.Value := Outcome;
  // MainData.Payments.Post; //This is done on the Datamodule, but maybe we can do it all here
End;

class function TPaymentUtils.ReadPaymentLevel: TPaymentLevel;
var s: String;
begin
  s := MainData.SessionValue['PayLevel'];
  if s = Payment_Level[TPaymentLevel.Deposit] then
     Result := TPaymentLevel.Deposit
  else if s = Payment_Level[TPaymentLevel.Balance] then
     Result := TPaymentLevel.Balance
  else
     Result := TPaymentLevel.Other;

end;

class procedure TPaymentUtils.WritePaymentLevel(const APaymentLevel: TPaymentLevel);
begin
  MainData.SessionValue['PayLevel'] := Payment_Level[APaymentLevel];
end;

End.
