Unit App.Types;

Interface

Uses
  JS,
  System.SysUtils,
  WEBLib.Dialogs,
  WEBLib.Forms,
  XData.Web.Connection,
  XData.Web.Client;

Type

  TErrorType = (etApplication, etXDataClient, etXDataConnection);

  TProc = reference To Procedure;

  TUserChangedProc = Procedure(Const LastUserId, NewUserId: Integer) Of Object;
  TBeforeLogoutEvent = Procedure(Const IsUserInitiated: boolean) Of Object;

  TSuccessProc = reference To Procedure;
  TLogoutProc = reference To Procedure(AMessage: String = ''; Const LoggingOut: boolean = False);
  TUnauthorizedAccessProc = reference To Procedure(AMessage: String);

  TListProc = reference To Procedure;
  TEditProc = reference To Procedure(AId: JSValue);

  TShowMessageProc = Procedure(Const AMessage: String; DlgType: TMsgDlgType) Of Object;
  TErrorMessageProc = Procedure(AMessage: String) Of Object;
  TXDataErrorProc = Procedure(Value: TApplicationError; Var AHandled: boolean) Of Object;

  EGeneralXDataException = Class(Exception)
  Private
    FRequestId: String;
    FRequestUrl: String;
    FErrorCode: String;
    FStatusCode: Integer;
    FErrorType: TErrorType;
    FColNumber: Integer;
    FStack: String;
    FFile: String;
    FLineNumber: Integer;
    FStatusReason: String;
  Public
    Constructor Create(AnError: TXDataClientError); Overload;
    Constructor Create(AnError: TApplicationError); Overload;
    Constructor Create(AnError: TXDataWebConnectionError); Overload;
    Property StatusCode: Integer Read FStatusCode;
    Property RequestUrl: String Read FRequestUrl;
    Property RequestId: String Read FRequestId;
    Property ErrorCode: String Read FErrorCode;
    Property ErrorType: TErrorType Read FErrorType;
    Property AFile: String Read FFile;
    Property LineNumber: Integer Read FLineNumber;
    Property ColNumber: Integer Read FColNumber;
    Property Stack: String Read FStack;
    Property StatusReason: String Read FStatusReason;
  End;

  EXDataClientException = Class(Exception)
  Private
    FRequestId: String;
    FRequestUrl: String;
    FErrorCode: String;
    FStatusCode: Integer;
  Public
    Constructor Create(AnError: TXDataClientError);
    Property StatusCode: Integer Read FStatusCode;
    Property RequestUrl: String Read FRequestUrl;
    Property RequestId: String Read FRequestId;
    Property ErrorCode: String Read FErrorCode;
  End;

  EApplicationException = Class(Exception)
  Private
    FColNumber: Integer;
    FStack: String;
    FFile: String;
    FLineNumber: Integer;
    FErrorCount: Integer;
  Public
    Constructor Create(AnError: TApplicationError; Const AErrorCount: Integer = 1);
    Property AFile: String Read FFile;
    Property LineNumber: Integer Read FLineNumber;
    Property ColNumber: Integer Read FColNumber;
    Property Stack: String Read FStack;
    Property ErrorCount: Integer Read FErrorCount;
  End;

  EXDataConnectionError = Class(Exception)
  Private
    FRequestUrl: String;
    FStatusReason: String;
    FStatusCode: Integer;
  Public
    Constructor Create(AnError: TXDataWebConnectionError);
    Property RequestUrl: String Read FRequestUrl;
    Property StatusCode: Integer Read FStatusCode;
    Property StatusReason: String Read FStatusReason;
  End;

Implementation

{ EXDataException }

Constructor EXDataClientException.Create(AnError: TXDataClientError);
Begin
  FStatusCode := AnError.StatusCode;
  FRequestUrl := AnError.RequestUrl;
  FRequestId := AnError.RequestId;
  FErrorCode := AnError.ErrorCode;
  Message := AnError.ErrorMessage;
  // property Response: IHttpResponse read FResponse;
End;

{ EApplicationException }

Constructor EApplicationException.Create(AnError: TApplicationError; Const AErrorCount: Integer);
Begin
  // property Error: TJSObject;
  FErrorCount := AErrorCount;
  FColNumber := AnError.AColNumber;
  FStack := AnError.AStack;
  Message := AnError.AMessage;
  FFile := AnError.AFile;
  FLineNumber := AnError.ALineNumber;
End;

{ EXDataConnectionError }

Constructor EXDataConnectionError.Create(AnError: TXDataWebConnectionError);
Begin
  Message := AnError.ErrorMessage;
  FRequestUrl := AnError.RequestUrl;
  FStatusCode := AnError.Response.StatusCode;
  FStatusReason := AnError.Response.StatusReason;
End;

{ EGeneralXDataException }

Constructor EGeneralXDataException.Create(AnError: TXDataClientError);
Begin
  FErrorType := TErrorType.etXDataClient;
  FStatusCode := AnError.StatusCode;
  FRequestUrl := AnError.RequestUrl;
  FRequestId := AnError.RequestId;
  FErrorCode := AnError.ErrorCode;
  Message := AnError.ErrorMessage;
End;

Constructor EGeneralXDataException.Create(AnError: TApplicationError);
Begin
  FErrorType := TErrorType.etApplication;
  FColNumber := AnError.AColNumber;
  FStack := AnError.AStack;
  Message := AnError.AMessage;
  FFile := AnError.AFile;
  FLineNumber := AnError.ALineNumber;
End;

Constructor EGeneralXDataException.Create(AnError: TXDataWebConnectionError);
Begin
  FErrorType := TErrorType.etXDataConnection;
  Message := AnError.ErrorMessage;
  FRequestUrl := AnError.RequestUrl;
  FStatusCode := AnError.Response.StatusCode;
  FStatusReason := AnError.Response.StatusReason;
End;

End.
