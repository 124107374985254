{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.PayPal;

{$DEFINE NOPP}

interface

uses
  Classes, Web, JS, WEBLib.Controls, WEBLib.Graphics, WEBLib.JSON;

const
  MAJ_VER = 2; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  // version history
  // 1.0.0.0 : First release
  // 1.0.0.1 : Fixed: Issue when TPayPalItem.Tax <> 0
  // 1.0.0.2 : Fixed: Issue with events in Release mode
  // 2.0.0.0 : New: Migration to PayPal JavaScript SDK

type
  TPayPal = class;
  TPayPalPayment = class;
  TPayPalItems = class;

  TPayPalPaymentEventArgs = class(TPersistent)
  private
    FPayerID: string;
    FPaymentID: string;
    FOrderID: string;
    FState: string;
    FEmail: string;
    FCustomText: string;
    FInvoiceNumber: string;
    FLastName: string;
    FFirstName: string;
    FPostalCode: string;
    FPaymentState: string;
    FRecipientName: string;
    FCity: string;
    FCountryCode: string;
    FPhone: string;
    FAddress2: string;
    FAddress1: string;
    FTotal: string;
    FSaleID: string;
    FDescription: string;
    FCurrency: string;
  public
    property Description: string read FDescription write FDescription;
    property PaymentID: string read FPaymentID write FPaymentID;
    property PayerID: string read FPayerID write FPayerID;
    property OrderID: string read FOrderID write FOrderID;
    property SaleID: string read FSaleID write FSaleID;
    property PaymentState: string read FPaymentState write FPaymentState;
    property Email: string read FEmail write FEmail;
    property Phone: string read FPhone write FPhone;
    property CustomText: string read FCustomText write FCustomText;
    property InvoiceNumber: string read FInvoiceNumber write FInvoiceNumber;
    property FirstName: string read FFirstName write FFirstName;
    property LastName: string read FLastName write FLastName;
    property Address1: string read FAddress1 write FAddress1;
    property Address2: string read FAddress2 write FAddress2;
    property City: string read FCity write FCity;
    property PostalCode: string read FPostalCode write FPostalCode;
    property CountryCode: string read FCountryCode write FCountryCode;
    property State: string read FState write FState;
    property RecipientName: string read FRecipientName write FRecipientName;
    property Total: string read FTotal write FTotal;
    property Currency: string read FCurrency write FCurrency;
  end;

  TPayPalPaymentEvent = procedure(Sender: TObject; Args: TPayPalPaymentEventArgs) of object;

  TPayPalErrorEventArgs = class(TPersistent)
  private
    FErrorDetails: TStringList;
    FErrorName: string;
  public
    property ErrorName: string read FErrorName write FErrorName;
    property ErrorDetails: TStringList read FErrorDetails write FErrorDetails;
  end;

  TPayPalErrorEvent = procedure(Sender: TObject; Args: TPayPalErrorEventArgs) of object;

  TPayPalCurrency = (pcAUD, pcBRL, pcCAD, pcCZK, pcDKK, pcEUR, pcHKD, pcHUF,
    pcILS, pcJPY, pcMYR, pcMXN, pcTWD, pcNZD, pcNOK, pcPHP, pcPLN, pcGBP, pcRUB,
    pcSGD, pcSEK, pcCHF, pcTHB, pcUSD);
  TPayPalLocale = (plDefault, plEN_US, plDE_DE, plNL_NL, plFR_FR, plPT_BR, plFR_CA, plZH_CN,
    plDA_DK, plRU_RU, plIT_IT, plNO_NO, plPL_PL, plPT_PT, plES_ES, plSV_SE,
    plEN_GB, plJA_JP);

  TPayPalItem = class(TCollectionItem)
  private
    FOwner: TPayPalPayment;
    FName: string;
    FPrice: Double;
    FQuantity: Integer;
    FDescription: string;
    FSKU: String;
    FTax: Double;
    FTag: Integer;
    FTagObject: TObject;
    procedure SetName(const Value: string);
    procedure SetPrice(const Value: Double);
    procedure SetQuantity(const Value: Integer);
    procedure SetDescription(const Value: string);
    procedure SetSKU(const Value: String);
    procedure SetTax(const Value: Double);
    procedure SetTag(const Value: Integer);
    procedure SetTagObject(const Value: TObject);
  public
    constructor Create(Collection: TCollection); override;
    destructor Destroy; override;
  published
    property Name: string read FName write SetName;
    property Price: Double read FPrice write SetPrice;
    property Quantity: Integer read FQuantity write SetQuantity default 1;
    property Description: string read FDescription write SetDescription;
    property Tax: Double read FTax write SetTax default 0;
    property SKU: String read FSKU write SetSKU;
    property Tag: Integer read FTag write SetTag default 0;
    property TagObject: TObject read FTagObject write SetTagObject;
  end;

  TPayPalItems = class(TOwnedCollection)
  private
    FOwner: TPayPalPayment;
    FOnChange: TNotifyEvent;
    function GetItems(Index: integer): TPayPalItem;
    procedure SetItems(Index: integer; const Value: TPayPalItem);
  public
    constructor Create(AOwner: TPayPalPayment); overload;
    function GetOwner: TPersistent; override;
    function Add: TPayPalItem; reintroduce;
    function Insert(Index: Integer):TPayPalItem; reintroduce;
    property Items[Index: integer]: TPayPalItem read GetItems write SetItems; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TPayPalPayment = class(TPersistent)
  private
    FOwner: TPayPal;
    FInsurance: Double;
    FOnChange: TNotifyEvent;
    FTax: Double;
    FShipping: Double;
    FInvoiceNumber: string;
    FDescription: string;
    FCustomText: string;
    FCurrency: TPayPalCurrency;
    FLocale: TPayPalLocale;
    FItems: TPayPalItems;
    FShippingDiscount: Double;
    FHandlingFee: Double;
    FPostalCode: string;
    FState: string;
    FPhone: string;
    FAddress2: string;
    FAddress1: string;
    FRecipientName: string;
    FCity: string;
    FCountryCode: string;
    procedure SetInsurance(const Value: Double);
    procedure SetShipping(const Value: Double);
    procedure SetTax(const Value: Double);
    procedure SetCustomText(const Value: string);
    procedure SetDescription(const Value: string);
    procedure SetInvoiceNumber(const Value: string);
    procedure SetCurrency(const Value: TPayPalCurrency);
    procedure SetLocale(const Value: TPayPalLocale);
    procedure SetItems(const Value: TPayPalItems);
    procedure SetHandlingFee(const Value: Double);
    procedure SetShippingDiscount(const Value: Double);
    procedure SetAddress1(const Value: string);
    procedure SetAddress2(const Value: string);
    procedure SetCity(const Value: string);
    procedure SetCountryCode(const Value: string);
    procedure SetPhone(const Value: string);
    procedure SetPostalCode(const Value: string);
    procedure SetRecipientName(const Value: string);
    procedure SetState(const Value: string);
  public
    constructor Create(AOwner: TPayPal); overload;
    destructor Destroy; override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Assign(Source: TPersistent); override;
  published
    property Tax: Double read FTax write SetTax default 0;
    property Shipping: Double read FShipping write SetShipping default 0;
    property ShippingDiscount: Double read FShippingDiscount write SetShippingDiscount default 0;
    property HandlingFee: Double read FHandlingFee write SetHandlingFee default 0;
    property Insurance: Double read FInsurance write SetInsurance default 0;
    property CustomText: string read FCustomText write SetCustomText;
    property InvoiceNumber: string read FInvoiceNumber write SetInvoiceNumber;
    property Description: string read FDescription write SetDescription;
    property Currency: TPayPalCurrency read FCurrency write SetCurrency default pcUSD;
    property Locale: TPayPalLocale read FLocale write SetLocale default plDefault;
    property Items: TPayPalItems read FItems write SetItems;
    property Address1: string read FAddress1 write SetAddress1;
    property Address2: string read FAddress2 write SetAddress2;
    property City: string read FCity write SetCity;
    property PostalCode: string read FPostalCode write SetPostalCode;
    property CountryCode: string read FCountryCode write SetCountryCode;
    property State: string read FState write SetState;
    property Phone: string read FPhone write SetPhone;
    property RecipientName: string read FRecipientName write SetRecipientName;
  end;

  TPayPal = class(TCustomControl)
  private
    FScriptAdded: boolean;
    FScriptLoaded: boolean;
    FScriptLoadedPtr: pointer;
    FAPIKey: string;
    FOnPaymentDone: TPayPalPaymentEvent;
    FOnPaymentCancelled: TNotifyEvent;
    FOnPaymentError: TPayPalErrorEvent;
    FPayment: TPayPalPayment;
    procedure SetAPIKey(const Value: string);
    procedure SetPayment(const Value: TPayPalPayment);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure UpdateScript;
    procedure AddScript(Script: string);
    function CurrencyToStr(ACurrency: TPayPalCurrency): string;
    procedure AddScriptLinkLoaded(const link: string);
    procedure ScriptLinkLoaded(Event: TJSEvent);
    function HandlePaymentDone(Event, PaymentDetails: TJSObject): Boolean;
    function HandlePaymentCancelled(): Boolean;
    function HandlePaymentError(Error: TJSObject): Boolean;
  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
  published
    property Enabled;
    property ElementClassName;
    property ElementID;

    property APIKey: string read FAPIKey write SetAPIKey;
    property Payment: TPayPalPayment read FPayment write SetPayment;

    property OnPaymentDone: TPayPalPaymentEvent read FOnPaymentDone write FOnPaymentDone;
    property OnPaymentCancelled: TNotifyEvent read FOnPaymentCancelled write FOnPaymentCancelled;
    property OnPaymentError: TPayPalErrorEvent read FOnPaymentError write FOnPaymentError;
  end;

  TWebPayPal = class(TPayPal);

implementation

uses
  SysUtils, TypInfo;

{ TPayPal }

procedure TPayPal.AddScript(Script: string);
begin
  asm
    var scrObj = document.createElement('script');
    scrObj.innerHTML = Script;
    document.head.appendChild(scrObj);
  end;
end;

function TPayPal.CreateElement: TJSElement;
var
  LDiv: TJSHTMLElement;
begin
  Result := document.createElement('DIV');

  if (csDesigning in ComponentState) then
  begin
    LDiv := TJSHTMLElement(Result);
    Color := $39C1E6;
    LDiv.style.setProperty('background-color','slategray');
    LDiv.style.setProperty('background-repeat','no-repeat');
    LDiv.style.setProperty('background-position-y','center');
    LDiv.style.setProperty('background-image',

      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMTAwIDMyIiBwcmVzZ'+
      'XJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0IiB4bWxucz0iaHR0cDomI3gyRjsmI3gyRjt3d3cudzMub3JnJiN4MkY7MjAwMCYje'+
      'DJGO3N2ZyI+PHBhdGggZmlsbD0iIzAwMzA4NyIgZD0iTSAxMi4yMzcgMi40NDQgTCA0LjQzNyAyLjQ0NCBDIDMuOTM3IDIuNDQ0IDMuN'+
      'DM3IDIuODQ0IDMuMzM3IDMuMzQ0IEwgMC4yMzcgMjMuMzQ0IEMgMC4xMzcgMjMuNzQ0IDAuNDM3IDI0LjA0NCAwLjgzNyAyNC4wNDQgT'+
      'CA0LjUzNyAyNC4wNDQgQyA1LjAzNyAyNC4wNDQgNS41MzcgMjMuNjQ0IDUuNjM3IDIzLjE0NCBMIDYuNDM3IDE3Ljc0NCBDIDYuNTM3I'+
      'DE3LjI0NCA2LjkzNyAxNi44NDQgNy41MzcgMTYuODQ0IEwgMTAuMDM3IDE2Ljg0NCBDIDE1LjEzNyAxNi44NDQgMTguMTM3IDE0LjM0N'+
      'CAxOC45MzcgOS40NDQgQyAxOS4yMzcgNy4zNDQgMTguOTM3IDUuNjQ0IDE3LjkzNyA0LjQ0NCBDIDE2LjgzNyAzLjE0NCAxNC44MzcgM'+
      'i40NDQgMTIuMjM3IDIuNDQ0IFogTSAxMy4xMzcgOS43NDQgQyAxMi43MzcgMTIuNTQ0IDEwLjUzNyAxMi41NDQgOC41MzcgMTIuNTQ0I'+
      'EwgNy4zMzcgMTIuNTQ0IEwgOC4xMzcgNy4zNDQgQyA4LjEzNyA3LjA0NCA4LjQzNyA2Ljg0NCA4LjczNyA2Ljg0NCBMIDkuMjM3IDYuO'+
      'DQ0IEMgMTAuNjM3IDYuODQ0IDExLjkzNyA2Ljg0NCAxMi42MzcgNy42NDQgQyAxMy4xMzcgOC4wNDQgMTMuMzM3IDguNzQ0IDEzLjEzN'+
      'yA5Ljc0NCBaIj48L3BhdGg+PHBhdGggZmlsbD0iIzAwMzA4NyIgZD0iTSAzNS40MzcgOS42NDQgTCAzMS43MzcgOS42NDQgQyAzMS40M'+
      'zcgOS42NDQgMzEuMTM3IDkuODQ0IDMxLjEzNyAxMC4xNDQgTCAzMC45MzcgMTEuMTQ0IEwgMzAuNjM3IDEwLjc0NCBDIDI5LjgzNyA5L'+
      'jU0NCAyOC4wMzcgOS4xNDQgMjYuMjM3IDkuMTQ0IEMgMjIuMTM3IDkuMTQ0IDE4LjYzNyAxMi4yNDQgMTcuOTM3IDE2LjY0NCBDIDE3L'+
      'jUzNyAxOC44NDQgMTguMDM3IDIwLjk0NCAxOS4zMzcgMjIuMzQ0IEMgMjAuNDM3IDIzLjY0NCAyMi4xMzcgMjQuMjQ0IDI0LjAzNyAyN'+
      'C4yNDQgQyAyNy4zMzcgMjQuMjQ0IDI5LjIzNyAyMi4xNDQgMjkuMjM3IDIyLjE0NCBMIDI5LjAzNyAyMy4xNDQgQyAyOC45MzcgMjMuN'+
      'TQ0IDI5LjIzNyAyMy45NDQgMjkuNjM3IDIzLjk0NCBMIDMzLjAzNyAyMy45NDQgQyAzMy41MzcgMjMuOTQ0IDM0LjAzNyAyMy41NDQgM'+
      'zQuMTM3IDIzLjA0NCBMIDM2LjEzNyAxMC4yNDQgQyAzNi4yMzcgMTAuMDQ0IDM1LjgzNyA5LjY0NCAzNS40MzcgOS42NDQgWiBNIDMwL'+
      'jMzNyAxNi44NDQgQyAyOS45MzcgMTguOTQ0IDI4LjMzNyAyMC40NDQgMjYuMTM3IDIwLjQ0NCBDIDI1LjAzNyAyMC40NDQgMjQuMjM3I'+
      'DIwLjE0NCAyMy42MzcgMTkuNDQ0IEMgMjMuMDM3IDE4Ljc0NCAyMi44MzcgMTcuODQ0IDIzLjAzNyAxNi44NDQgQyAyMy4zMzcgMTQuN'+
      'zQ0IDI1LjEzNyAxMy4yNDQgMjcuMjM3IDEzLjI0NCBDIDI4LjMzNyAxMy4yNDQgMjkuMTM3IDEzLjY0NCAyOS43MzcgMTQuMjQ0IEMgM'+
      'zAuMjM3IDE0Ljk0NCAzMC40MzcgMTUuODQ0IDMwLjMzNyAxNi44NDQgWiI+PC9wYXRoPjxwYXRoIGZpbGw9IiMwMDMwODciIGQ9Ik0gN'+
      'TUuMzM3IDkuNjQ0IEwgNTEuNjM3IDkuNjQ0IEMgNTEuMjM3IDkuNjQ0IDUwLjkzNyA5Ljg0NCA1MC43MzcgMTAuMTQ0IEwgNDUuNTM3I'+
      'DE3Ljc0NCBMIDQzLjMzNyAxMC40NDQgQyA0My4yMzcgOS45NDQgNDIuNzM3IDkuNjQ0IDQyLjMzNyA5LjY0NCBMIDM4LjYzNyA5LjY0N'+
      'CBDIDM4LjIzNyA5LjY0NCAzNy44MzcgMTAuMDQ0IDM4LjAzNyAxMC41NDQgTCA0Mi4xMzcgMjIuNjQ0IEwgMzguMjM3IDI4LjA0NCBDI'+
      'DM3LjkzNyAyOC40NDQgMzguMjM3IDI5LjA0NCAzOC43MzcgMjkuMDQ0IEwgNDIuNDM3IDI5LjA0NCBDIDQyLjgzNyAyOS4wNDQgNDMuM'+
      'TM3IDI4Ljg0NCA0My4zMzcgMjguNTQ0IEwgNTUuODM3IDEwLjU0NCBDIDU2LjEzNyAxMC4yNDQgNTUuODM3IDkuNjQ0IDU1LjMzNyA5L'+
      'jY0NCBaIj48L3BhdGg+PHBhdGggZmlsbD0iIzAwOWNkZSIgZD0iTSA2Ny43MzcgMi40NDQgTCA1OS45MzcgMi40NDQgQyA1OS40MzcgM'+
      'i40NDQgNTguOTM3IDIuODQ0IDU4LjgzNyAzLjM0NCBMIDU1LjczNyAyMy4yNDQgQyA1NS42MzcgMjMuNjQ0IDU1LjkzNyAyMy45NDQgN'+
      'TYuMzM3IDIzLjk0NCBMIDYwLjMzNyAyMy45NDQgQyA2MC43MzcgMjMuOTQ0IDYxLjAzNyAyMy42NDQgNjEuMDM3IDIzLjM0NCBMIDYxL'+
      'jkzNyAxNy42NDQgQyA2Mi4wMzcgMTcuMTQ0IDYyLjQzNyAxNi43NDQgNjMuMDM3IDE2Ljc0NCBMIDY1LjUzNyAxNi43NDQgQyA3MC42M'+
      'zcgMTYuNzQ0IDczLjYzNyAxNC4yNDQgNzQuNDM3IDkuMzQ0IEMgNzQuNzM3IDcuMjQ0IDc0LjQzNyA1LjU0NCA3My40MzcgNC4zNDQgQ'+
      'yA3Mi4yMzcgMy4xNDQgNzAuMzM3IDIuNDQ0IDY3LjczNyAyLjQ0NCBaIE0gNjguNjM3IDkuNzQ0IEMgNjguMjM3IDEyLjU0NCA2Ni4wM'+
      'zcgMTIuNTQ0IDY0LjAzNyAxMi41NDQgTCA2Mi44MzcgMTIuNTQ0IEwgNjMuNjM3IDcuMzQ0IEMgNjMuNjM3IDcuMDQ0IDYzLjkzNyA2L'+
      'jg0NCA2NC4yMzcgNi44NDQgTCA2NC43MzcgNi44NDQgQyA2Ni4xMzcgNi44NDQgNjcuNDM3IDYuODQ0IDY4LjEzNyA3LjY0NCBDIDY4L'+
      'jYzNyA4LjA0NCA2OC43MzcgOC43NDQgNjguNjM3IDkuNzQ0IFoiPjwvcGF0aD48cGF0aCBmaWxsPSIjMDA5Y2RlIiBkPSJNIDkwLjkzN'+
      'yA5LjY0NCBMIDg3LjIzNyA5LjY0NCBDIDg2LjkzNyA5LjY0NCA4Ni42MzcgOS44NDQgODYuNjM3IDEwLjE0NCBMIDg2LjQzNyAxMS4xN'+
      'DQgTCA4Ni4xMzcgMTAuNzQ0IEMgODUuMzM3IDkuNTQ0IDgzLjUzNyA5LjE0NCA4MS43MzcgOS4xNDQgQyA3Ny42MzcgOS4xNDQgNzQuM'+
      'TM3IDEyLjI0NCA3My40MzcgMTYuNjQ0IEMgNzMuMDM3IDE4Ljg0NCA3My41MzcgMjAuOTQ0IDc0LjgzNyAyMi4zNDQgQyA3NS45MzcgM'+
      'jMuNjQ0IDc3LjYzNyAyNC4yNDQgNzkuNTM3IDI0LjI0NCBDIDgyLjgzNyAyNC4yNDQgODQuNzM3IDIyLjE0NCA4NC43MzcgMjIuMTQ0I'+
      'EwgODQuNTM3IDIzLjE0NCBDIDg0LjQzNyAyMy41NDQgODQuNzM3IDIzLjk0NCA4NS4xMzcgMjMuOTQ0IEwgODguNTM3IDIzLjk0NCBDI'+
      'Dg5LjAzNyAyMy45NDQgODkuNTM3IDIzLjU0NCA4OS42MzcgMjMuMDQ0IEwgOTEuNjM3IDEwLjI0NCBDIDkxLjYzNyAxMC4wNDQgOTEuM'+
      'zM3IDkuNjQ0IDkwLjkzNyA5LjY0NCBaIE0gODUuNzM3IDE2Ljg0NCBDIDg1LjMzNyAxOC45NDQgODMuNzM3IDIwLjQ0NCA4MS41MzcgM'+
      'jAuNDQ0IEMgODAuNDM3IDIwLjQ0NCA3OS42MzcgMjAuMTQ0IDc5LjAzNyAxOS40NDQgQyA3OC40MzcgMTguNzQ0IDc4LjIzNyAxNy44N'+
      'DQgNzguNDM3IDE2Ljg0NCBDIDc4LjczNyAxNC43NDQgODAuNTM3IDEzLjI0NCA4Mi42MzcgMTMuMjQ0IEMgODMuNzM3IDEzLjI0NCA4N'+
      'C41MzcgMTMuNjQ0IDg1LjEzNyAxNC4yNDQgQyA4NS43MzcgMTQuOTQ0IDg1LjkzNyAxNS44NDQgODUuNzM3IDE2Ljg0NCBaIj48L3Bhd'+
      'Gg+PHBhdGggZmlsbD0iIzAwOWNkZSIgZD0iTSA5NS4zMzcgMi45NDQgTCA5Mi4xMzcgMjMuMjQ0IEMgOTIuMDM3IDIzLjY0NCA5Mi4zM'+
      'zcgMjMuOTQ0IDkyLjczNyAyMy45NDQgTCA5NS45MzcgMjMuOTQ0IEMgOTYuNDM3IDIzLjk0NCA5Ni45MzcgMjMuNTQ0IDk3LjAzNyAyM'+
      'y4wNDQgTCAxMDAuMjM3IDMuMTQ0IEMgMTAwLjMzNyAyLjc0NCAxMDAuMDM3IDIuNDQ0IDk5LjYzNyAyLjQ0NCBMIDk2LjAzNyAyLjQ0N'+
      'CBDIDk1LjYzNyAyLjQ0NCA5NS40MzcgMi42NDQgOTUuMzM3IDIuOTQ0IFoiPjwvcGF0aD48L3N2Zz4=)');

    LDiv.style.setProperty('background-position-x','center');
    LDiv.style.setProperty('background-size','128px');
  end;
end;

procedure TPayPal.CreateInitialize;
begin
  inherited;
  FScriptAdded := False;
  FScriptLoaded := False;
  FAPIKey := '';
  FPayment := TPayPalPayment.Create(Self);
end;

destructor TPayPal.Destroy;
begin
  FPayment.Free;
  inherited;
end;

procedure TPayPal.ScriptLinkLoaded(Event: TJSEvent);
begin
  (Event.Target as TJSHTMLScriptElement).Title := 'tmswebpaypalloaded';
  if not FScriptLoaded then
  begin
    FScriptLoaded := True;
    UpdateScript;
  end;
end;

procedure TPayPal.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
  begin
    if FScriptAdded then
    begin
      raise Exception.Create('Error: API Key can not be updated.');
    end
    else
    begin
      FAPIKey := Value;
      UpdateElement;
    end;
  end;
end;

procedure TPayPal.SetPayment(const Value: TPayPalPayment);
begin
  FPayment.Assign(Value);
end;

function TPayPal.HandlePaymentCancelled: Boolean;
begin
  if Assigned(OnPaymentCancelled) then
    OnPaymentCancelled(Self);
  Result := True;
end;

function TPayPal.HandlePaymentDone(Event, PaymentDetails: TJSObject): Boolean;
var
  Args: TPayPalPaymentEventArgs;
  transactions, captures: TJSArray;
  custom, description, invoice_number: JSValue;
  payer, payer_info, shipping, shipping_name, shipping_address, payments, transaction, amount, sale: TJSObject;
begin
  if Assigned(OnPaymentDone) then
  begin
    Args := TPayPalPaymentEventArgs.Create;
    Args.OrderID := string(PaymentDetails.Properties['id']);
    Args.PaymentState := string(PaymentDetails.Properties['status']);

    payer := TJSObject(PaymentDetails.Properties['payer']);
    if Assigned(payer) then
    begin
      Args.Email := string(payer.Properties['email_address']);
      Args.PayerID := string(Event.Properties['payer_id']);

      payer_info := TJSObject(payer.Properties['name']);
      if Assigned(payer_info) then
      begin
        Args.FirstName := string(payer_info.Properties['given_name']);
        Args.LastName := string(payer_info.Properties['surname']);
      end;
    end;

    transactions := TJSArray(PaymentDetails.Properties['purchase_units']);
    if Assigned(transactions) then
    begin
      if transactions.Length > 0 then
      begin
        transaction := TJSObject(transactions[0]);

        custom := transaction.Properties['custom_id'];
        if Assigned(custom) then
          Args.CustomText := string(custom);

        description := transaction.Properties['description'];
        if Assigned(description) then
          Args.Description := string(description);

        invoice_number := transaction.Properties['invoice_id'];
        if Assigned(invoice_number) then
          Args.InvoiceNumber := string(invoice_number);

        amount := TJSObject(transaction.Properties['amount']);
        if Assigned(amount) then
        begin
          Args.Total := string(amount.Properties['value']);
          Args.Currency := string(amount.Properties['currency_code']);
        end;

        shipping := TJSObject(transaction.Properties['shipping']);
        if Assigned(shipping) then
        begin
          shipping_name := TJSObject(shipping.Properties['name']);
          if Assigned(shipping_name) then
            Args.RecipientName := string(shipping_name.Properties['full_name']);

          shipping_address := TJSObject(shipping.Properties['address']);
          if Assigned(shipping_address) then
          begin
            Args.Phone := string(shipping_address.Properties['phone']);
            Args.Address1 := string(shipping_address.Properties['address_line_1']);
            Args.Address2 := string(shipping_address.Properties['address_line_2']);
            Args.City := string(shipping_address.Properties['admin_area_2']);
            Args.State := string(shipping_address.Properties['admin_area_1']);
            Args.CountryCode := string(shipping_address.Properties['country_code']);
            Args.PostalCode := string(shipping_address.Properties['postal_code'] );
          end;
        end;

        payments := TJSObject(transaction.Properties['payments']);
        if Assigned(payments) then
        begin
          captures := TJSArray(payments.Properties['captures']);
          if Assigned(captures) then
          begin
            if captures.Length > 0 then
            begin
              sale := TJSObject(captures[0]);
              if Assigned(sale) then
                Args.PaymentID := string(sale.Properties['id']);
            end;
          end;
        end;
      end;
    end;

    OnPaymentDone(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TPayPal.HandlePaymentError(Error: TJSObject): Boolean;
  function LastCharPos(const S: string; const Chr: char): integer;
  var
    i: Integer;
  begin
    result := 0;
    for i := length(S) downto 1 do
      if S[i] = Chr then
        Exit(i);
  end;

var
  Args: TPayPalErrorEventArgs;
  sError: string;
  jserror, detail: TJSObject;
  details: TJSArray;
  istart, iend: Integer;
begin
  if Assigned(OnPaymentError) then
  begin
    Args := TPayPalErrorEventArgs.Create;

    Args.ErrorDetails := TStringList.Create;

    sError := Error.toString;

    istart := pos('{', sError);
    iend := LastCharPos(sError, '}');
    sError := Copy(sError, istart, iend - istart + 1);

    if sError <> '' then
    begin
      jserror := TJSObject(TJSJSON.parse(sError));

      Args.ErrorName := string(jserror.Properties['name']);

      details := TJSArray(jserror.Properties['details']);
      if Assigned(details) then
      begin
        if details.Length > 0 then
        begin
          detail := TJSObject(details[0]);
          Args.ErrorDetails.Add(string(detail.Properties['field']) + ': ' + string(detail.Properties['issue']));
        end;
      end;
    end;

    OnPaymentError(Self, Args);
  end;
  Result := True;
end;

{ TPayPalPayment }

procedure TPayPalPayment.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TPayPalPayment then
  begin
    FTax := (Source as TPayPalPayment).Tax;
    FShipping := (Source as TPayPalPayment).Shipping;
    FShippingDiscount := (Source as TPayPalPayment).ShippingDiscount;
    FHandlingFee := (Source as TPayPalPayment).HandlingFee;
    FInsurance := (Source as TPayPalPayment).Insurance;
    FDescription := (Source as TPayPalPayment).Description;
    FCustomText := (Source as TPayPalPayment).CustomText;
    FInvoiceNumber := (Source as TPayPalPayment).InvoiceNumber;
    FCurrency := (Source as TPayPalPayment).Currency;
    FLocale := (Source as TPayPalPayment).Locale;
    FAddress1 := (Source as TPayPalPayment).Address1;
    FAddress2 := (Source as TPayPalPayment).Address2;
    FCity := (Source as TPayPalPayment).City;
    FCountryCode := (Source as TPayPalPayment).CountryCode;
    FPhone := (Source as TPayPalPayment).Phone;
    FPostalCode := (Source as TPayPalPayment).PostalCode;
    FRecipientName := (Source as TPayPalPayment).RecipientName;
    FState := (Source as TPayPalPayment).State;
    FItems.Assign((Source as TPayPalPayment).Items);
  end;
end;

constructor TPayPalPayment.Create(AOwner: TPayPal);
begin
  FTax := 0;
  FShipping := 0;
  FShippingDiscount := 0;
  FHandlingFee := 0;
  FInsurance := 0;
  FDescription := '';
  FCustomText := '';
  FInvoiceNumber := '';
  FCurrency := pcUSD;
  FLocale := plDefault;
  FAddress1 := '';
  FAddress2 := '';
  FCity := '';
  FCountryCode := '';
  FPhone := '';
  FPostalCode := '';
  FRecipientName := '';
  FState := '';
  FItems := TPayPalItems.Create(Self);
  FItems.PropName := 'Items';
  FOwner := AOwner;
end;

destructor TPayPalPayment.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TPayPalPayment.SetAddress1(const Value: string);
begin
  FAddress1 := Value;
end;

procedure TPayPalPayment.SetAddress2(const Value: string);
begin
  FAddress2 := Value;
end;

procedure TPayPalPayment.SetCity(const Value: string);
begin
  FCity := Value;
end;

procedure TPayPalPayment.SetCountryCode(const Value: string);
begin
  if FCountryCode <> Value then
  begin
    FCountryCode := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetCurrency(const Value: TPayPalCurrency);
begin
  if FCurrency <> Value then
  begin
    if FOwner.FScriptAdded then
    begin
      raise Exception.Create('Error: Currency can not be updated.');
    end
    else
    begin
      FCurrency := Value;
      FOwner.UpdateElement;
    end;
  end;
end;

procedure TPayPalPayment.SetCustomText(const Value: string);
begin
  if FCustomText <> Value then
  begin
    FCustomText := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetDescription(const Value: string);
begin
  if FDescription <> Value then
  begin
    FDescription := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetHandlingFee(const Value: Double);
begin
  if FHandlingFee <> Value then
  begin
    FHandlingFee := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetInsurance(const Value: Double);
begin
  if FInsurance <> Value then
  begin
    FInsurance := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetInvoiceNumber(const Value: string);
begin
  if FInvoiceNumber <> Value then
  begin
    FInvoiceNumber := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetItems(const Value: TPayPalItems);
begin
  FItems.Assign(Value);
end;

procedure TPayPalPayment.SetLocale(const Value: TPayPalLocale);
begin
  if FLocale <> Value then
  begin
    FLocale := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetPhone(const Value: string);
begin
  if FPhone <> Value then
  begin
    FPhone := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetPostalCode(const Value: string);
begin
  if FPostalCode <> Value then
  begin
    FPostalCode := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetRecipientName(const Value: string);
begin
  if FRecipientName <> Value then
  begin
    FRecipientName := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetShipping(const Value: Double);
begin
  if FShipping <> Value then
  begin
    FShipping := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetShippingDiscount(const Value: Double);
begin
  if FShippingDiscount <> Value then
  begin
    FShippingDiscount := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetState(const Value: string);
begin
  if FState <> Value then
  begin
    FState := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TPayPalPayment.SetTax(const Value: Double);
begin
  if FTax <> Value then
  begin
    FTax := Value;
    FOwner.UpdateElement;
  end;
end;

{$HINTS OFF}

procedure TPayPal.AddScriptLinkLoaded(const link: string);
var
  id, eltitle, eltitleloaded: string;
  script, sel: TJSHTMLScriptElement;
  jsel: TJSHTMLElement;
  I: Integer;

begin
  if not FScriptAdded then
  begin
    FScriptAdded := True;

    eltitle := 'tmswebpaypalloading';
    eltitleloaded := 'tmswebpaypalloaded';

    script := TJSHTMLScriptElement(document.createElement('script'));
    script.src := link;
    script.type_ := 'text/javascript';
    script.title := eltitle;

    if not Assigned(FScriptLoadedPtr) then
      FScriptLoadedPtr := @ScriptLinkLoaded;

    script.addEventListener('load', FScriptLoadedPtr);
    document.head.appendChild(script);
  end;
end;

function TPayPal.CurrencyToStr(ACurrency: TPayPalCurrency): string;
begin
  case ACurrency of
    pcAUD: Result := 'AUD';
    pcBRL: Result := 'BRL';
    pcCAD: Result := 'CAD';
    pcCZK: Result := 'CZK';
    pcDKK: Result := 'DKK';
    pcEUR: Result := 'EUR';
    pcHKD: Result := 'HKD';
    pcHUF: Result := 'HUF';
    pcILS: Result := 'ILS';
    pcJPY: Result := 'JPY';
    pcMYR: Result := 'MYR';
    pcMXN: Result := 'MXN';
    pcTWD: Result := 'TWD';
    pcNZD: Result := 'NZD';
    pcNOK: Result := 'NOK';
    pcPHP: Result := 'PHP';
    pcPLN: Result := 'PLN';
    pcGBP: Result := 'GBP';
    pcRUB: Result := 'RUB';
    pcSGD: Result := 'SGD';
    pcSEK: Result := 'SEK';
    pcCHF: Result := 'CHF';
    pcTHB: Result := 'THB';
    pcUSD: Result := 'USD';
  end;
end;

procedure TPayPal.UpdateElement;
var
  locale: string;
begin
  inherited;

  if (csDesigning in ComponentState) then
    Exit;

  if not Visible then
    Exit;

  if IsUpdating then
    Exit;

  if APIKey = '' then
    Exit;

  if not Assigned(ElementHandle) then
    Exit;

  ElementHandle.innerHTML := '';

  //Fix: When running in release mode with Optimization set to True
  //Event handlers are only referenced inside an asm block
  if 1 < 0 then
  begin
    HandlePaymentDone(nil, nil);
    HandlePaymentCancelled;
    HandlePaymentError(nil);
  end;

  if APIKey <> '' then
  begin
    case Payment.Locale of
      plDefault: locale := '';
      plEN_US: locale := 'en_US';
      plDE_DE: locale := 'de_DE';
      plNL_NL: locale := 'nl_NL';
      plFR_FR: locale := 'fr_FR';
      plPT_BR: locale := 'pt_BR';
      plFR_CA: locale := 'fr_CA';
      plZH_CN: locale := 'zh_CN';
      plDA_DK: locale := 'da_DK';
      plRU_RU: locale := 'ru_RU';
      plIT_IT: locale := 'it_IT';
      plNO_NO: locale := 'no_NO';
      plPL_PL: locale := 'pl_PL';
      plPT_PT: locale := 'pt_PT';
      plES_ES: locale := 'es_ES';
      plSV_SE: locale := 'sv_SE';
      plEN_GB: locale := 'en_GB';
      plJA_JP: locale := 'ja_JP';
    end;

    if locale <> '' then
      locale := '&locale=' + locale;

    AddScriptLinkLoaded('https://www.paypal.com/sdk/js?client-id=' + APIKey + locale + '&disable-funding=card,sofort,bancontact&currency=' + CurrencyToStr(Payment.Currency));
  end;

  UpdateScript;
end;

procedure TPayPal.UpdateScript;
  function PriceToStr(APrice: double): string;
  begin
    if Payment.Currency in [pcJPY, pcTWD] then
      Result := FormatFloat('0', APrice)
    else
       Result := StringReplace(FormatFloat('0.00', APrice), FormatSettings.DecimalSeparator, '.', []);
  end;

var
  apiS, apiP, env, ssubtotal, staxtotal, stotal, locale,
  spayment, sitems: string;
  jspayment: TJSObject;
  I: Integer;
  total, subtotal, taxtotal: Double;
begin
  sitems := '';
  subtotal := 0;
  for I := 0 to Payment.Items.Count - 1 do
  begin

    if not FScriptLoaded then
      exit;

    if Payment.Items[I].Quantity > 0 then
    begin
      subtotal := subtotal
        + ((Payment.Items[I].Price) * Payment.Items[I].Quantity);

      taxtotal := taxtotal
        + ((Payment.Items[I].Tax) * Payment.Items[I].Quantity);

      if I > 0 then
        sitems := sitems + ', ' + #13;
      sitems := sitems +
        #13 +
        '   {' + #13 +
        '     "name": "' + Payment.Items[I].Name + '"' + #13 +
        '     , "description": "' + Payment.Items[I].Description + '"' + #13 +
        '     , "unit_amount": {' +
        '        "value": "' + PriceToStr(Payment.Items[I].Price) + '"' + #13 +
        '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
        '      }' + #13 +
        '     , "tax": {' +
        '        "value": "' + PriceToStr(Payment.Items[I].Tax) + '"' + #13 +
        '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
        '      }' + #13 +
        '     , "quantity": "' + IntToStr(Payment.Items[I].Quantity) + '"' + #13;

      if (Payment.Items[I].SKU <> '') then
        sitems := sitems +  '     , "sku": "' + Payment.Items[I].SKU + '"' + #13;

      sitems := sitems +
        '   }' + #13;
    end;
  end;

  if subtotal <= 0 then
    Exit;

  ssubtotal := PriceToStr(subtotal);
  total := subtotal + Payment.Shipping - Payment.ShippingDiscount
    + taxtotal + Payment.Insurance + Payment.HandlingFee;
  stotal := PriceToStr(total);
  staxtotal := PriceToStr(taxtotal);

  ElementHandle.innerHTML := '<div id="paypal-button-container"></div>';

  spayment := '{' +
    '"purchase_units": [{' + #13 +
    '  "description": "' + Payment.Description + '"' + #13 +
    '   , "custom_id": "' + Payment.CustomText + '"' + #13 +
    '   , "invoice_id": "' + Payment.InvoiceNumber + '"' + #13 +
    '  , "amount": {' + #13 +
    '    "value": "' + stotal + '"' + #13 +
    '     ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '    , "breakdown": {' + #13 +
    '      "item_total": {' + #13 +
    '        "value": "' + ssubtotal + '"' + #13 +
    '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '      }' + #13 +
    '      ,"tax_total": {' +
    '        "value": "' + staxtotal + '"' + #13 +
    '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '      }' + #13 +
    '      ,"shipping": {' +
    '        "value": "' + PriceToStr(Payment.Shipping) + '"' + #13 +
    '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '      }' + #13 +
    '      ,"shipping_discount": {' +
    '        "value": "' + PriceToStr(Payment.ShippingDiscount) + '"' + #13 +
    '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '      }' + #13 +
    '      ,"insurance": {' +
    '        "value": "' + PriceToStr(Payment.Insurance) + '"' + #13 +
    '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '      }' + #13 +
    '      ,"handling": {' +
    '        "value": "' + PriceToStr(Payment.HandlingFee) + '"' + #13 +
    '        ,"currency_code": "' + CurrencyToStr(Payment.Currency) + '"' + #13 +
    '      }' + #13 +
    '    }' + #13 +
    '  }' + #13 +
    '   , "items": [' + sitems + ']' + #13;

  if (Payment.Phone <> '') then
  begin
    spayment := spayment +
    '     , "payer": {' + #13 +
    '     "phone": {' + #13 +
    '        "phone_type": "MOBILE"' + #13 +
    '        ,"phone_number": "' + Payment.Phone + '"' + #13 +
    '      }' + #13 +
    '    }' + #13;
  end;

  if (Payment.CountryCode <> '') and (Payment.City <> '') then
  begin
    spayment := spayment +
    '   , "shipping": {' + #13 +
    '     "address": {' + #13 +
    '        "address_line_1": "' + Payment.Address1 + '"' + #13 +
    '        ,"address_line_2": "' + Payment.Address2 + '"' + #13 +
    '        ,"admin_area_2": "' + Payment.City + '"' + #13 +
    '        ,"admin_area_1": "' + Payment.State + '"' + #13 +
    '        ,"country_code": "' + Payment.CountryCode + '"' + #13 +
    '        ,"postal_code": "' + Payment.PostalCode + '"' + #13 +
    '      }' + #13 +
    '    }' + #13;
  end;

    spayment := spayment +
    '}]' + #13 +
    '}';

  jspayment := TJSJSON.parseObject(spayment);

  asm
    var PObj = this;

    if (window.paypal)
    {
      window.paypal.Buttons({
        createOrder: function(data, actions) {
          return actions.order.create(jspayment);
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
            PObj.HandlePaymentDone(data, details)
          });
        },
        onCancel: function(data) {
          PObj.HandlePaymentCancelled();
        },
        onError: function (err) {
          PObj.HandlePaymentError(err);
        }
      }).render('#paypal-button-container');
    }
    else
    {
      console.log("PayPal SDK JS lib not ready");
    }

  end;
end;

{$HINTS ON}

{ TPayPalItem }

constructor TPayPalItem.Create(Collection: TCollection);
begin
  inherited;
  FName := '';
  FPrice := 0;
  FQuantity := 1;
  FDescription := '';
  FTax := 0;
  FSKU := '';
  FTag := 0;
  FTagObject := nil;
  FOwner := Collection.Owner as TPayPalPayment;
end;

destructor TPayPalItem.Destroy;
begin
  inherited;
end;

procedure TPayPalItem.SetDescription(const Value: string);
begin
  if FDescription <> Value then
  begin
    FDescription := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TPayPalItem.SetName(const Value: string);
begin
  if FName <> Value then
  begin
    FName := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TPayPalItem.SetPrice(const Value: Double);
begin
  if FPrice <> Value then
  begin
    FPrice := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TPayPalItem.SetQuantity(const Value: Integer);
begin
  if FQuantity <> Value then
  begin
    FQuantity := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TPayPalItem.SetSKU(const Value: String);
begin
  if FSKU <> Value then
  begin
    FSKU := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TPayPalItem.SetTag(const Value: Integer);
begin
  FTag := Value;
end;

procedure TPayPalItem.SetTagObject(const Value: TObject);
begin
  FTagObject := Value;
end;

procedure TPayPalItem.SetTax(const Value: Double);
begin
  if FTax <> Value then
  begin
    FOwner.FOwner.UpdateElement;
    FTax := Value;
  end;
end;

{ TPayPalItems }

function TPayPalItems.Add: TPayPalItem;
begin
  Result := TPayPalItem(inherited Add);
end;

constructor TPayPalItems.Create(AOwner: TPayPalPayment);
begin
  inherited Create(AOwner, TPayPalItem);
  FOwner := AOwner;
end;

function TPayPalItems.GetItems(Index: integer): TPayPalItem;
begin
  Result := TPayPalItem(inherited Items[Index]);
end;

function TPayPalItems.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TPayPalItems.Insert(Index: Integer): TPayPalItem;
begin
  Result := TPayPalItem(inherited Insert(Index));
end;

procedure TPayPalItems.SetItems(Index: integer; const Value: TPayPalItem);
begin
  inherited Items[Index] := Value;
end;

end.


