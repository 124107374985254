unit AccountBookingPayment;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  AccountBaseForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.PayPal,
  Booking.Web.Shared,
  Cyrus.Enumerations;

type
  TPaymentAmounts = array [TPaymentLevel] of Double;

  TAccountBookingPaymentPage = class(TAccountBasePage)
    HolidayBookingCosts: THTMLSpan;
    PayPalButton: TPayPal;
    CreditCardButton: TButton;
    ChequeButton: TButton;
    BACSButton: TButton;
    AgreeReadTerms: TCheckBox;
    PaymentOption: TRadioGroup;
    procedure AgreeReadTermsClick(Sender: TObject);
    procedure BACSButtonClick(Sender: TObject);
    procedure BalanceAmountClick(Sender: TObject);
    procedure ChequeButtonClick(Sender: TObject);
    procedure CreditCardButtonClick(Sender: TObject);
    procedure DepositAmountClick(Sender: TObject);
    procedure PaymentOptionChange(Sender: TObject);
  private
    { Private declarations }
    FPaymentProvider: TPaymentProvider;
    FAmounts: TPaymentAmounts;
    FAmountPaid: Double;
    FBalanceIdx: Integer;
    FDepositIdx: Integer;
    [async]
    procedure CreatePayPalOrder; async;
    [async]
    procedure CreateRevolutOrder; async;
    procedure OpenRevolutPopup(const Token, Mode: string);
    [async]
    procedure RevolutSuccess; async;
    [async]
    procedure RevolutError(aMessage: string); async;
    [JSInvokable]
    procedure RevolutSuccessCallback;
    [JSInvokable]
    procedure RevolutErrorCallback(aMessage: string);
    procedure AfterPaymentProcessed(const PaymentState: TPaymentState);
    [async]
    procedure ShowConfirmation; async;
    procedure BookingAllSaved(const CanProgress: Boolean);
    procedure CalcPaymentAmounts;
    procedure EnablePaymentButtons;
    procedure ResetPayPalItem;
    procedure UpdatePayPalItem;
    function GetSelectedAmount: TPaymentLevel;
    procedure SetSelectedAmount(const Value: TPaymentLevel);
  protected
    procedure InitForm; override;
    property SelectedAmount: TPaymentLevel read GetSelectedAmount write SetSelectedAmount;
  public
    { Public declarations }
  published
    [async]
    procedure PayPalButtonPaymentCancelled(Sender: TObject); async;
    [async]
    procedure PayPalButtonPaymentDone(Sender: TObject; Args: TPayPalPaymentEventArgs); async;
    [async]
    procedure PayPalButtonPaymentError(Sender: TObject; Args: TPayPalErrorEventArgs); async;

  protected procedure LoadDFMValues; override; end;

var
  AccountBookingPaymentPage: TAccountBookingPaymentPage;

implementation

{$R *.dfm}

uses
  System.DateUtils,
  System.StrUtils,
  XData.Web.Client,
  Holiday.Utils,
  Payment.Utils,
  MainDataModule,
  Holiday.ReturnTypes,
  DTO_Payments,
  AccountPayConfirmation,
  smx.webcore.types;

{ TBookingPaymentPage }

procedure TAccountBookingPaymentPage.AfterPaymentProcessed(const PaymentState: TPaymentState);
begin
  if PaymentState = TPaymentState.Success then
  begin
    MainData.OnReadyToProgess := BookingAllSaved;
    MainData.SaveBookingDatasets(TPaymentUtils.BookingStateStage(FPaymentProvider));
  end;
end;

procedure TAccountBookingPaymentPage.AgreeReadTermsClick(Sender: TObject);
begin
  EnablePaymentButtons;
end;

procedure TAccountBookingPaymentPage.BACSButtonClick(Sender: TObject);
begin
  FPaymentProvider := TPaymentProvider.BACS;
  ShowConfirmation;
end;

procedure TAccountBookingPaymentPage.BalanceAmountClick(Sender: TObject);
begin
  SelectedAmount := TPaymentLevel.Balance;
end;

procedure TAccountBookingPaymentPage.BookingAllSaved(const CanProgress: Boolean);
begin
  MainData.OnReadyToProgess := nil;
  HolidayBookingCosts.HTML.Text := THolidayUtils.BookingCostsTable;
  CalcPaymentAmounts;
  if FPaymentProvider = TPaymentProvider.PayPal then
  begin
    MainData.DeletePayPalStorage;
    ResetPayPalItem;
  end;
  EnablePaymentButtons;
  ShowConfirmation;
end;

procedure TAccountBookingPaymentPage.CalcPaymentAmounts;
var
  lBalance: Double;
begin
  FAmounts[TPaymentLevel.Other] := 0;
  PaymentOption.BeginUpdate;
  try
    PaymentOption.Items.Clear;

    lBalance := MainData.BookingDatasetBalanceDue.Value;
    FAmounts[TPaymentLevel.Balance] := MainData.BookingDatasetBalanceDue.Value;

    if (MainData.BookingDatasetFinalBalanceDueOn.Value > Now) and (MainData.BookingDatasetTotalPaid.Value = 0) then
      FAmounts[TPaymentLevel.Deposit] := MainData.BookingDatasetDepositDue.Value
    else
      FAmounts[TPaymentLevel.Deposit] := 0;

    if (FAmounts[TPaymentLevel.Balance] > 0) and (MainData.HolidayDataConfirmed.Value) then
    begin
      FBalanceIdx := PaymentOption.Items.Add('Balance: ' + FormatFloat('#,##0.00', FAmounts[TPaymentLevel.Balance]));
      SelectedAmount := TPaymentLevel.Balance;
      PaymentOption.ItemIndex := FBalanceIdx;
    end
    else
      FBalanceIdx := -1;

    if FAmounts[TPaymentLevel.Deposit] > 0 then
    begin
      FDepositIdx := PaymentOption.Items.Add('Deposit: ' + FormatFloat('#,##0.00', FAmounts[TPaymentLevel.Deposit]));
      SelectedAmount := TPaymentLevel.Deposit;
      PaymentOption.ItemIndex := FDepositIdx;
    end;

    PaymentOption.Visible := PaymentOption.Items.Count > 0;

  finally
    PaymentOption.EndUpdate;
  end;

end;

procedure TAccountBookingPaymentPage.ChequeButtonClick(Sender: TObject);
begin
  FPaymentProvider := TPaymentProvider.Cheque;
  ShowConfirmation;
end;

procedure TAccountBookingPaymentPage.CreatePayPalOrder;
var
  PayPalItem: TPayPalItem;
begin
  MainData.PaymentProvider := TPaymentProvider.PayPal;

  PayPalButton.BeginUpdate;
  PayPalButton.APIKey := Await(MainData.PaymentAPIKey(TPaymentProvider.PayPal));
  PayPalButton.Payment.Currency := pcGBP;
  PayPalButton.Payment.Shipping := 0.00;
  PayPalButton.Payment.Description := MainData.BookingDatasetBookingId.AsString;

  ResetPayPalItem;

  PayPalButton.EndUpdate;
  PayPalButton.Visible := True;

end;

procedure TAccountBookingPaymentPage.CreateRevolutOrder;
var
  ClientResponse: TXDataClientResponse;
  Payment: THolidayPayment;
  theToken: string;
  lRevolutOrder: TJSObject;
  lOrder: string;
const
  IPaymentService_Order = 'IPaymentService.CreateOrder';
begin

  MainData.PaymentProvider := TPaymentProvider.Revolut;
  Payment := THolidayPayment.Create;
  Payment.Description := 'REVOLUT';

  Payment.TotalAmount := FAmounts[SelectedAmount];

  Payment.CurrencyCode := 'GBP';

  ClientResponse := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IPaymentService_Order, [Payment]));

  lRevolutOrder := ClientResponse.ResultAsObject;
  lOrder := TJSJSON.stringify(lRevolutOrder);
  MainData.SessionValue['RevolutOrder'] := lOrder;
  theToken := string(lRevolutOrder['token']); // JS.ToString(FOrder['token']);

  OpenRevolutPopup(theToken, MainData.PaymentKeys.Revolut_Mode); { TODO : or whatever it should be }

  Payment.Free;
end;

procedure TAccountBookingPaymentPage.CreditCardButtonClick(Sender: TObject);
begin
  FPaymentProvider := TPaymentProvider.Revolut;
  CreateRevolutOrder;
end;

procedure TAccountBookingPaymentPage.DepositAmountClick(Sender: TObject);
begin
  inherited;
  SelectedAmount := TPaymentLevel.Deposit;
end;

procedure TAccountBookingPaymentPage.EnablePaymentButtons;
var
  lEnabled: Boolean;
begin
  if not PaymentOption.Visible then
  begin
    ChequeButton.Visible := False;
    PayPalButton.Visible := False;
    CreditCardButton.Visible := False;
    BACSButton.Visible := False;
    Exit;
  end;

  lEnabled := AgreeReadTerms.Checked;
  ChequeButton.Enabled := ChequeButton.Visible and lEnabled;
  PayPalButton.Enabled := lEnabled;
  CreditCardButton.Enabled := lEnabled;
  BACSButton.Enabled := lEnabled;
end;

function TAccountBookingPaymentPage.GetSelectedAmount: TPaymentLevel;
begin
  Result := TPaymentUtils.ReadPaymentLevel;
end;

procedure TAccountBookingPaymentPage.InitForm;
var
  lLatestChequeDate: TDateTime;
begin
  inherited;
  CreatePayPalOrder;
  HolidayBookingCosts.HTML.Text := THolidayUtils.BookingCostsTable;
  MainData.AfterPaymentProcessed := AfterPaymentProcessed;
  FAmountPaid := 0;

  lLatestChequeDate := IncDay(MainData.BookingDatasetDepartureDate.Value, -MainData.BankDetails.ChequeLimit);
  ChequeButton.Visible := lLatestChequeDate >= Date;

  CalcPaymentAmounts;
end;

procedure TAccountBookingPaymentPage.OpenRevolutPopup(const Token, Mode: string);
var
  ModeValue: string;
  Email: string;
begin
  Email := MainData.CustomerEmailAddress;
  ModeValue := Mode.ToLower;
{$IFDEF PAS2JS}
  asm
    PopUp(event, Token, Mode, Email)
  end;
{$ENDIF}
end;

procedure TAccountBookingPaymentPage.PaymentOptionChange(Sender: TObject);
begin
  if PaymentOption.ItemIndex = FBalanceIdx then
    SelectedAmount := TPaymentLevel.Balance
  else if PaymentOption.ItemIndex = FDepositIdx then
    SelectedAmount := TPaymentLevel.Deposit;
  UpdatePayPalItem;
end;

procedure TAccountBookingPaymentPage.PayPalButtonPaymentCancelled(Sender: TObject);
begin
  inherited;
  TPaymentUtils.PaymentFailed(TPaymentProvider.PayPal, 0.00, TPaymentState.Cancelled, 'Cancelled', '', SelectedAmount);
end;

procedure TAccountBookingPaymentPage.PayPalButtonPaymentDone(Sender: TObject; Args: TPayPalPaymentEventArgs);
begin
  FPaymentProvider := TPaymentProvider.PayPal;
  FAmountPaid := Args.Total.ToDouble;
  TPaymentUtils.PaymentSuccess(TPaymentProvider.PayPal, FAmountPaid, Args.PaymentID, Args.PaymentState, SelectedAmount);
  MainData.ProcessPayment(TPaymentProvider.PayPal, False); // now wait for callback AfterPaymentProcessed
end;

procedure TAccountBookingPaymentPage.PayPalButtonPaymentError(Sender: TObject; Args: TPayPalErrorEventArgs);
begin
  TPaymentUtils.PaymentFailed(TPaymentProvider.PayPal, 0.00, TPaymentState.Failed, Args.ErrorName,
    Args.ErrorDetails.Text, SelectedAmount);
end;

procedure TAccountBookingPaymentPage.ResetPayPalItem;
var
  PayPalItem: TPayPalItem;
begin
  if PayPalButton.Payment.Items.Count > 0 then
    PayPalButton.Payment.Items.Clear;

  PayPalItem := PayPalButton.Payment.Items.Add;
  PayPalItem.Name := MainData.BookingDatasetBookingReference.Value;
  PayPalItem.Quantity := 1;
  UpdatePayPalItem;

end;

procedure TAccountBookingPaymentPage.RevolutError(aMessage: string);
var
  lOrder: TJSObject;
begin
  lOrder := TJSJSON.parseObject(MainData.SessionValue['RevolutOrder']);
  TPaymentUtils.PaymentFailed(TPaymentProvider.Revolut, Double(lOrder['amount']), TPaymentState.Failed,
    string(lOrder['id']), '', SelectedAmount);
  MainData.SessionValue['RevolutOrder'] := '';
end;

procedure TAccountBookingPaymentPage.RevolutErrorCallback(aMessage: string);
begin
  RevolutError(aMessage);
end;

procedure TAccountBookingPaymentPage.RevolutSuccess;
var
  lOrder: TJSObject;
begin
  lOrder := TJSJSON.parseObject(MainData.SessionValue['RevolutOrder']);
  FAmountPaid := Double(lOrder['amount']);
  TPaymentUtils.PaymentSuccess(TPaymentProvider.Revolut, FAmountPaid, string(lOrder['id']), '', SelectedAmount);
  MainData.SessionValue['RevolutOrder'] := '';
  MainData.ProcessPayment(TPaymentProvider.Revolut, False);
end;

procedure TAccountBookingPaymentPage.RevolutSuccessCallback;
begin
  RevolutSuccess;
end;

procedure TAccountBookingPaymentPage.SetSelectedAmount(const Value: TPaymentLevel);
begin
  TPaymentUtils.WritePaymentLevel(Value);
end;

procedure TAccountBookingPaymentPage.ShowConfirmation;
var
  AForm: TAccountPayConfirmationPage;
begin
  CloseButton.Enabled := False;
  AForm := TAccountPayConfirmationPage.Create(self);
  try
    AForm.PopUp := True;
    AForm.ElementClassName := POPUP_FORM_CLASS;
    AForm.PopupOpacity := 1;
    Await(TForm, AForm.Load());
    AForm.AmountToPay := FAmounts[SelectedAmount];
    AForm.PaymentProvider := FPaymentProvider;
    Await(TModalResult, AForm.Execute);
    // ModalResult := mrOK;
  finally
    AForm.Free;
    AForm := nil;
    CloseButton.Enabled := True;
  end;

end;

procedure TAccountBookingPaymentPage.UpdatePayPalItem;
var
  PayPalItem: TPayPalItem;
begin
  PayPalItem := PayPalButton.Payment.Items[0];
  PayPalItem.Price := FAmounts[SelectedAmount];
end;

procedure TAccountBookingPaymentPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HolidayBookingCosts := THTMLSpan.Create('HolidayBookingCosts');
  PayPalButton := TPayPal.Create('PayPalButton');
  CreditCardButton := TButton.Create('CreditCardButton');
  ChequeButton := TButton.Create('ChequeButton');
  BACSButton := TButton.Create('BACSButton');
  AgreeReadTerms := TCheckBox.Create('AgreeReadTerms');
  PaymentOption := TRadioGroup.Create('PaymentOption');

  HolidayBookingCosts.BeforeLoadDFMValues;
  PayPalButton.BeforeLoadDFMValues;
  CreditCardButton.BeforeLoadDFMValues;
  ChequeButton.BeforeLoadDFMValues;
  BACSButton.BeforeLoadDFMValues;
  AgreeReadTerms.BeforeLoadDFMValues;
  PaymentOption.BeforeLoadDFMValues;
  try
    HolidayBookingCosts.SetParentComponent(Self);
    HolidayBookingCosts.Name := 'HolidayBookingCosts';
    HolidayBookingCosts.Left := 64;
    HolidayBookingCosts.Top := 96;
    HolidayBookingCosts.Width := 145;
    HolidayBookingCosts.Height := 89;
    HolidayBookingCosts.HeightStyle := ssAuto;
    HolidayBookingCosts.WidthStyle := ssAuto;
    HolidayBookingCosts.ChildOrder := 2;
    HolidayBookingCosts.ElementPosition := epIgnore;
    HolidayBookingCosts.ElementFont := efCSS;
    HolidayBookingCosts.Role := '';
    PayPalButton.SetParentComponent(Self);
    PayPalButton.Name := 'PayPalButton';
    PayPalButton.Left := 40;
    PayPalButton.Top := 309;
    PayPalButton.Width := 209;
    PayPalButton.Height := 49;
    PayPalButton.HeightStyle := ssAuto;
    PayPalButton.WidthStyle := ssAuto;
    PayPalButton.Enabled := False;
    SetEvent(PayPalButton, Self, 'OnPaymentDone', 'PayPalButtonPaymentDone');
    SetEvent(PayPalButton, Self, 'OnPaymentCancelled', 'PayPalButtonPaymentCancelled');
    SetEvent(PayPalButton, Self, 'OnPaymentError', 'PayPalButtonPaymentError');
    CreditCardButton.SetParentComponent(Self);
    CreditCardButton.Name := 'CreditCardButton';
    CreditCardButton.Left := 255;
    CreditCardButton.Top := 256;
    CreditCardButton.Width := 209;
    CreditCardButton.Height := 48;
    CreditCardButton.Caption := 'Card';
    CreditCardButton.ChildOrder := 1;
    CreditCardButton.ElementClassName := 'btn btn-primary';
    CreditCardButton.ElementFont := efCSS;
    CreditCardButton.ElementPosition := epIgnore;
    CreditCardButton.Enabled := False;
    CreditCardButton.HeightStyle := ssAuto;
    CreditCardButton.HeightPercent := 100.000000000000000000;
    CreditCardButton.WidthStyle := ssAuto;
    CreditCardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CreditCardButton, Self, 'OnClick', 'CreditCardButtonClick');
    ChequeButton.SetParentComponent(Self);
    ChequeButton.Name := 'ChequeButton';
    ChequeButton.Left := 255;
    ChequeButton.Top := 310;
    ChequeButton.Width := 209;
    ChequeButton.Height := 48;
    ChequeButton.Caption := 'Cheque';
    ChequeButton.ChildOrder := 1;
    ChequeButton.ElementClassName := 'btn btn-primary';
    ChequeButton.ElementFont := efCSS;
    ChequeButton.ElementPosition := epIgnore;
    ChequeButton.Enabled := False;
    ChequeButton.HeightStyle := ssAuto;
    ChequeButton.HeightPercent := 100.000000000000000000;
    ChequeButton.WidthStyle := ssAuto;
    ChequeButton.WidthPercent := 100.000000000000000000;
    SetEvent(ChequeButton, Self, 'OnClick', 'ChequeButtonClick');
    BACSButton.SetParentComponent(Self);
    BACSButton.Name := 'BACSButton';
    BACSButton.Left := 40;
    BACSButton.Top := 256;
    BACSButton.Width := 209;
    BACSButton.Height := 48;
    BACSButton.Caption := 'BACS/Bank Transfer';
    BACSButton.ChildOrder := 1;
    BACSButton.ElementClassName := 'btn btn-primary';
    BACSButton.ElementFont := efCSS;
    BACSButton.ElementPosition := epIgnore;
    BACSButton.Enabled := False;
    BACSButton.HeightStyle := ssAuto;
    BACSButton.HeightPercent := 100.000000000000000000;
    BACSButton.WidthStyle := ssAuto;
    BACSButton.WidthPercent := 100.000000000000000000;
    SetEvent(BACSButton, Self, 'OnClick', 'BACSButtonClick');
    AgreeReadTerms.SetParentComponent(Self);
    AgreeReadTerms.Name := 'AgreeReadTerms';
    AgreeReadTerms.Left := 40;
    AgreeReadTerms.Top := 218;
    AgreeReadTerms.Width := 113;
    AgreeReadTerms.Height := 22;
    AgreeReadTerms.ChildOrder := 6;
    AgreeReadTerms.ElementClassName := 'form-check-input';
    AgreeReadTerms.ElementButtonClassName := 'custom-control-input';
    AgreeReadTerms.ElementLabelClassName := 'custom-control-label';
    AgreeReadTerms.ElementFont := efCSS;
    AgreeReadTerms.ElementPosition := epIgnore;
    AgreeReadTerms.HeightStyle := ssAuto;
    AgreeReadTerms.HeightPercent := 100.000000000000000000;
    AgreeReadTerms.WidthStyle := ssAuto;
    AgreeReadTerms.WidthPercent := 100.000000000000000000;
    SetEvent(AgreeReadTerms, Self, 'OnClick', 'AgreeReadTermsClick');
    PaymentOption.SetParentComponent(Self);
    PaymentOption.Name := 'PaymentOption';
    PaymentOption.Left := 304;
    PaymentOption.Top := 80;
    PaymentOption.Width := 160;
    PaymentOption.Height := 80;
    PaymentOption.ElementClassName := 'custom-control custom-radio';
    PaymentOption.HeightStyle := ssAuto;
    PaymentOption.WidthStyle := ssAuto;
    PaymentOption.Caption := 'I would like to pay';
    PaymentOption.ChildOrder := 10;
    PaymentOption.Columns := 1;
    PaymentOption.ControlPosition := epIgnore;
    PaymentOption.ElementButtonClassName := 'custom-control-input';
    PaymentOption.ElementLabelClassName := 'custom-control-label';
    PaymentOption.ElementLegendClassName := 'h6';
    PaymentOption.ElementFont := efCSS;
    PaymentOption.ElementPosition := epIgnore;
    PaymentOption.ItemIndex := 0;
    PaymentOption.Role := '';
    PaymentOption.TabOrder := 7;
    SetEvent(PaymentOption, Self, 'OnChange', 'PaymentOptionChange');
  finally
    HolidayBookingCosts.AfterLoadDFMValues;
    PayPalButton.AfterLoadDFMValues;
    CreditCardButton.AfterLoadDFMValues;
    ChequeButton.AfterLoadDFMValues;
    BACSButton.AfterLoadDFMValues;
    AgreeReadTerms.AfterLoadDFMValues;
    PaymentOption.AfterLoadDFMValues;
  end;
end;

end.
