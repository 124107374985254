unit Holiday.Utils;

interface

uses
  Data.DB,
  Cyrus.Enumerations,
  Booking.Web.Shared;

type

  TAddress = record
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    Town: string;
    County: string;
    PostCode: string;
    CountryCode: string;
  end;

  THolidayUtils = class
  private
  public
    class function AddressFromDataset(ADataset: TDataset): TAddress;
    class procedure AddressToDataset(const AAddress: TAddress; ADataset: TDataset);
    class function HolidayDescription(const ADataset: TDataset): string;
    class function FormatAddress(const ADataset: TDataset; const ALineBreak: string = '<br />'): string;
    class function CountryNameFromCode(const Value: string): string;
    class function BookingStateCalc(const ABalance, APrice: Double): TBookingState;
    class function BookingCostsTable: string;
    class function RoomsAvailable: TRoomsAvailableOutCome;
    class function CreateBookingRef(const AHolidayRef: string; const ACustomerId: Integer): string;
    class function HolidayRefFromBookingRef(const ABookingRef: string): string;
    class function StrToBookingState(const Value: string): TBookingState;
    class function StrToHolidayType(const Value: string): THolidayType;
  end;

implementation

uses
  System.Rtti,
  System.SysUtils,
  System.DateUtils,
  System.StrUtils,
  WebLib.Country,
  MainDataModule,
  SMX.Web.Utils,
  SMX.Web.Table.Simple;

{ TBookingUtils }

class function THolidayUtils.AddressFromDataset(ADataset: TDataset): TAddress;
begin
  Result.AddressLine1 := ADataset.FieldByName('AddressLine1').AsString;
  Result.AddressLine2 := ADataset.FieldByName('AddressLine2').AsString;
  Result.AddressLine3 := ADataset.FieldByName('AddressLine3').AsString;
  Result.Town := ADataset.FieldByName('Town').AsString;
  Result.County := ADataset.FieldByName('County').AsString;
  Result.PostCode := ADataset.FieldByName('PostCode').AsString;
  Result.CountryCode := ADataset.FieldByName('CountryCode').AsString;
end;

class procedure THolidayUtils.AddressToDataset(const AAddress: TAddress; ADataset: TDataset);
begin
  ADataset.FieldByName('AddressLine1').AsString := AAddress.AddressLine1;
  ADataset.FieldByName('AddressLine2').AsString := AAddress.AddressLine2;
  ADataset.FieldByName('AddressLine3').AsString := AAddress.AddressLine3;
  ADataset.FieldByName('Town').AsString := AAddress.Town;
  ADataset.FieldByName('County').AsString := AAddress.County;
  ADataset.FieldByName('PostCode').AsString := AAddress.PostCode;
  ADataset.FieldByName('CountryCode').AsString := AAddress.CountryCode;
end;

class function THolidayUtils.BookingCostsTable: string;
var
  lTable: TsmwSimpleTable;
  Row, RowCount: Integer;
  lTotal, lDeposit, lPayments, lBalance, lAdjustedBalance: Double;
  lDescription: string;
begin
  Row := -1;
  lTotal := 0;
  RowCount := MainData.BookingCosts.RecordCount;
  lTable := TsmwSimpleTable.Create(RowCount + 8, 2, 'bookingcosts', 'bookingcosts');
  try
    Inc(Row);
    lTable.AddCell(Row, 0, 'Number of Guests', 'guestcount caption');
    lTable.AddCell(Row, 1, MainData.HolidayBooking.GuestCount.ToString, 'guestcount value');

    MainData.BookingCosts.First;

    while not MainData.BookingCosts.Eof do
    begin
      if MainData.BookingCostsTotalCost.Value <> 0 then
      begin
        Inc(Row);
        lDescription := MainData.BookingCostsFormattedDescription.Value;
        lTable.AddCell(Row, 0, lDescription.Replace('£', '&pound', [rfReplaceAll]), 'item caption');
        lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', MainData.BookingCostsTotalCost.Value),
          'item amount');
      end;

      MainData.BookingCosts.Next;
    end;

    lTotal := MainData.BookingDatasetTotalHolidayPrice.Value;
    lPayments := MainData.BookingDatasetTotalPaid.Value;
    lBalance := lTotal - lPayments;

    Inc(Row);
    lTable.AddCell(Row, 0, 'Total Price', 'total caption');
    lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', lTotal), 'total amount');

    if lPayments > 0 then
    begin
      Inc(Row);
      lTable.AddCell(Row, 0, 'Paid', 'total paid');
      lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', lPayments), 'paid amount');
    end;

    if lBalance = 0 then
    begin
      Inc(Row);
      lTable.AddCell(Row, 0, 'Balance', 'balance caption');
      lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', lBalance), 'balance amount');
    end
    else if (MainData.HolidayBooking.BalanceDueDate > Today) then
    begin

      lDeposit := MainData.BookingDatasetDepositDue.Value;

      if lPayments < MainData.BookingDatasetDepositDue.Value then
      begin
        Inc(Row);
        lTable.AddCell(Row, 0, 'Deposit Due Now', 'deposit caption');
        lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', lDeposit), 'deposit amount');
        lAdjustedBalance := lBalance - lDeposit;
      end
      else
        lAdjustedBalance := lBalance;

      Inc(Row);
      lTable.AddCell(Row, 0, 'Balance Due on ' + FormatDateTime('dd mmm yyyy',
        MainData.BookingDatasetFinalBalanceDueOn.Value), 'balance caption');
      lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', lAdjustedBalance), 'balance amount');
    end
    else
    begin
      Inc(Row);
      lTable.AddCell(Row, 0, 'Balance due today', 'balance caption');
      lTable.AddCell(Row, 1, '&pound;' + FormatFloat('#,##0.00', lBalance), 'balance amount');
    end;

    Inc(Row);
    lTable.AddCell(Row, 0, 'Arrival Date', 'arrival date caption');
    lTable.AddCell(Row, 1, FormatDateTime('dd mmm yyyy', MainData.BookingDatasetArrivaldate.Value),
      'arrival date value');

    Inc(Row);
    lTable.AddCell(Row, 0, 'Departure Date', 'departure date caption');
    lTable.AddCell(Row, 1, FormatDateTime('dd mmm yyyy', MainData.BookingDatasetDepartureDate.Value),
      'departure date value');

    Inc(Row);
    lTable.AddCell(Row, 0, 'Number of nights', 'nightcount caption');
    lTable.AddCell(Row, 1, MainData.BookingDatasetTotalNumberOfNights.AsString, 'nightcount value');

    Result := lTable.Table;

  finally
    lTable.Free;
  end;

end;

class function THolidayUtils.BookingStateCalc(const ABalance, APrice: Double): TBookingState;
begin
  if ABalance = 0 then
    Result := TBookingState.PaidInFull
  else if ABalance = APrice then
    Result := TBookingState.Confirmed
  else
    Result := TBookingState.DepositPaid;
end;

class function THolidayUtils.CountryNameFromCode(const Value: string): string;
begin
  if Value = 'GB' then
    Result := 'United Kingdom'
  else
    Result := GetCountryByCode(Value);
end;

class function THolidayUtils.CreateBookingRef(const AHolidayRef: string; const ACustomerId: Integer): string;
var
  lCustId: string;
begin
  Result := AHolidayRef;
  lCustId := ACustomerId.ToString;
  if not Result.EndsWith(lCustId) then
    Result := Result + '/' + lCustId;
end;

class function THolidayUtils.FormatAddress(const ADataset: TDataset; const ALineBreak: string): string;
begin
  Result := ADataset.FieldByName('AddressLine1').AsString;

  if ADataset.FieldByName('AddressLine2').AsString <> '' then
    Result := Result + ALineBreak + ADataset.FieldByName('AddressLine2').AsString;
  if ADataset.FieldByName('AddressLine3').AsString <> '' then
    Result := Result + ALineBreak + ADataset.FieldByName('AddressLine3').AsString;

  Result := Result + ALineBreak + ADataset.FieldByName('Town').AsString;
  if ADataset.FieldByName('County').AsString <> '' then
    Result := Result + ALineBreak + ADataset.FieldByName('County').AsString;
  Result := Result + ALineBreak + ADataset.FieldByName('PostCode').AsString;
  Result := Result + ALineBreak + CountryNameFromCode(ADataset.FieldByName('CountryCode').AsString);
end;

class function THolidayUtils.HolidayDescription(const ADataset: TDataset): string;
var
  lStartDate, lEndDate: TDate;
  lEndYear: Word;
  lTutor: string;
begin
  lStartDate := ADataset.FieldByName('StartDate').AsDateTime;
  lEndDate := ADataset.FieldByName('EndDate').AsDateTime;
  lEndYear := YearOf(lEndDate);
  lTutor := ADataset.FieldByName('Tutor').AsString;

  Result := TUtils.PrettyDateRange(lStartDate, lEndDate) + ': ' + ADataset.FieldByName('Title').AsString +
    IfThen(lTutor <> '', ' with ') + lTutor;
end;

class function THolidayUtils.HolidayRefFromBookingRef(const ABookingRef: string): string;
var
  lastDelim, lId: Integer;
  lastValue: string;
begin
  lastDelim := ABookingRef.LastIndexOf('/');
  lastValue := ABookingRef.Substring(lastDelim + 1);
  if not TryStrToInt(lastValue, lId) then
    Result := ABookingRef
  else
    Result := ABookingRef.Substring(0, lastDelim);
end;

class function THolidayUtils.RoomsAvailable: TRoomsAvailableOutCome;
var
  RoomCount: Integer;
  rt1, rt2: string;
  Retval: Boolean;
begin
  Result := TRoomsAvailableOutCome.raoYes;

  if MainData.RoomsAvailableCount = 0 then
     Exit(TRoomsAvailableOutCome.raoNone);

  RoomCount := 1;
  rt1 := Room_Type_Description[MainData.HolidayBooking.RoomType];
  rt2 := Room_Type_Description[MainData.HolidayBooking.RoomType2];

  if (MainData.HolidayBooking.GuestCount = 2) and (not MainData.HolidayBooking.RoomShare) and
    (MainData.HolidayBooking.RoomType2 = MainData.HolidayBooking.RoomType) then
    RoomCount := 2;

  Retval := MainData.RoomAvailable(MainData.HolidayBooking.RoomType, RoomCount);

  if (not Retval) or (RoomCount = 2) then
    Exit(TRoomsAvailableOutCome.raoType);

  if (MainData.HolidayBooking.GuestCount = 2) and (MainData.HolidayBooking.RoomType2 <> TRoomType.None) then
  begin
    if not MainData.RoomAvailable(MainData.HolidayBooking.RoomType, 1) then
       Result := TRoomsAvailableOutCome.raoType;
  end;

end;

class function THolidayUtils.StrToBookingState(const Value: string): TBookingState;
begin
  Result := TRttiEnumerationType.GetValue<TBookingState>(Value);
end;

class function THolidayUtils.StrToHolidayType(const Value: string): THolidayType;
var
  lValue: string;
begin
  lValue := Value.Replace(' ', '', [rfReplaceAll]).Replace('&', '', []);
  Result := TRttiEnumerationType.GetValue<THolidayType>(lValue);
end;

end.
