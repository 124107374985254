unit smx.webcore.types;

interface

uses
  System.Generics.Collections,
  WEBLib.Forms,
  MainForm.Base;

type

  TShowAPopUpProc = procedure(AFormClass: TFormClass) of object;

  TSortDirection = (sdAsc, sdDesc);
  TSortPair = TPair<integer, TSortDirection>;
  TRefStatus = (RefEmpty, RefNotChecked, RefChecking, RefUnique, RefInUse);
  TViewOption = (voNone, voReadOnly, voEdit, voCreateAndEdit);
  TValueChangeState = (vcsNoChange, vcsValid, vcsInvalid);

const
  POPUP_FORM_CLASS = 'PopUpForm PopUp';

var
  TheMainPage: TMainBasePage;

implementation

end.
