unit Confirmation;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseBooking,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  Booking.Web.Shared,
  WEBLib.ExtCtrls;

type
  TConfirmationForm = class(TBaseBookingForm)
    PaymentInstructions: THTMLSpan;
    LinkTimer: TTimer;
    ConfirmationMessage: THTMLSpan;
    ConfirmationAccountLink: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure ConfirmationAccountLinkClick(Sender: TObject);
    procedure LinkTimerTimer(Sender: TObject);
  private
    { Private declarations }
  protected
  public
    { Public declarations }
    [async]
    procedure InitForm; async; override;
    function CanCloseBookingPage(const ANextPage: boolean = True): TCanProgress; override;
    [async]
    procedure LoadBooking; async; override;
  protected procedure LoadDFMValues; override; end;

var
  ConfirmationForm: TConfirmationForm;

implementation

{$R *.dfm}

uses
  MainDataModule,
  Cyrus.Enumerations,
  Holiday.ReturnTypes,
  MainBookingForm;

procedure TConfirmationForm.WebFormCreate(Sender: TObject);
begin
  inherited;

end;

{ TConfirmationForm }

function TConfirmationForm.CanCloseBookingPage(const ANextPage: boolean): TCanProgress;
begin
  Result := TCanProgress.ccNo;
end;

procedure TConfirmationForm.ConfirmationAccountLinkClick(Sender: TObject);
begin
  MainData.SwitchMainPage(TMainAppPage.mpMyAccount);
end;

procedure TConfirmationForm.InitForm;
begin
  inherited;
  ConfirmationAccountLink.Enabled := False;
  MainBookingPage.MyAccountLink.Enabled := False;

  UpdateMainForm(TButtonState.wbsHidden, TButtonState.wbsHidden, Crumb_Confirmation);

  case MainData.PaymentProvider of
    TPaymentProvider.PayPal:
      PaymentInstructions.HTML.Text := 'Thanks for paying with PayPal';
    TPaymentProvider.Revolut:
      PaymentInstructions.HTML.Text := 'Thanks for paying by Credit Card';
    TPaymentProvider.BACS:
      begin
        PaymentInstructions.HTML.Text :=
          Format('Please make your BACS payment to: <br /><br />Account Name: %s<br /><br />Account Number: %s<br /><br />Sort Code: %s<br /><br />IBAN: %s<br /><br />SWIFT: %s<br />',
          [MainData.BankDetails.AccountName, MainData.BankDetails.AccountNumber, MainData.BankDetails.SortCode,
          MainData.BankDetails.IBAN, MainData.BankDetails.Swift]);
      end;
    TPaymentProvider.Cheque:
      PaymentInstructions.HTML.Text := 'Please make your cheque payable to <b>' + MainData.BankDetails.TradingName +
        '</b> and send it to<br />' + MainData.MailingAddress.AsHTML;
  end;
end;

procedure TConfirmationForm.LinkTimerTimer(Sender: TObject);
begin

  if MainData.IsActiveBooking then
    Exit;

  LinkTimer.Enabled := False;
  ConfirmationAccountLink.Enabled := True;
  MainBookingPage.MyAccountLink.Enabled := True;
end;

procedure TConfirmationForm.LoadBooking;
begin
  inherited;

  if MainData.IsFirstRegistration then
  begin
//    ConfirmationMessage.HTML.Text :=
//      'As part of registration process you will need to log back in to book another holiday or view this one.';
    ConfirmationAccountLink.Caption := 'Click here to sign in and manage your bookings';
    ConfirmationMessage.Visible := True;
  end
  else
  begin
    ConfirmationMessage.Visible := False;
    ConfirmationAccountLink.Caption := 'Click here to see and manage your bookings';
  end;

  MainData.CloseBookingData(False);
  if MainData.IsFirstRegistration then
     MainData.Logout(TLogoutMethod.lomRegistration);
  LinkTimer.Enabled := True;
end;

procedure TConfirmationForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PaymentInstructions := THTMLSpan.Create('PaymentInstructions');
  ConfirmationMessage := THTMLSpan.Create('ConfirmationMessage');
  ConfirmationAccountLink := TButton.Create('ConfirmationAccountLink');
  LinkTimer := TTimer.Create(Self);

  PaymentInstructions.BeforeLoadDFMValues;
  ConfirmationMessage.BeforeLoadDFMValues;
  ConfirmationAccountLink.BeforeLoadDFMValues;
  LinkTimer.BeforeLoadDFMValues;
  try
    PaymentInstructions.SetParentComponent(Self);
    PaymentInstructions.Name := 'PaymentInstructions';
    PaymentInstructions.Left := 223;
    PaymentInstructions.Top := 248;
    PaymentInstructions.Width := 417;
    PaymentInstructions.Height := 193;
    PaymentInstructions.HeightStyle := ssAuto;
    PaymentInstructions.WidthStyle := ssAuto;
    PaymentInstructions.ChildOrder := 1;
    PaymentInstructions.ElementPosition := epIgnore;
    PaymentInstructions.ElementFont := efCSS;
    PaymentInstructions.Role := '';
    ConfirmationMessage.SetParentComponent(Self);
    ConfirmationMessage.Name := 'ConfirmationMessage';
    ConfirmationMessage.Left := 223;
    ConfirmationMessage.Top := 88;
    ConfirmationMessage.Width := 100;
    ConfirmationMessage.Height := 41;
    ConfirmationMessage.HeightStyle := ssAuto;
    ConfirmationMessage.WidthStyle := ssAuto;
    ConfirmationMessage.ChildOrder := 4;
    ConfirmationMessage.ElementPosition := epIgnore;
    ConfirmationMessage.ElementFont := efCSS;
    ConfirmationMessage.Role := '';
    ConfirmationMessage.Visible := False;
    ConfirmationAccountLink.SetParentComponent(Self);
    ConfirmationAccountLink.Name := 'ConfirmationAccountLink';
    ConfirmationAccountLink.Left := 32;
    ConfirmationAccountLink.Top := 232;
    ConfirmationAccountLink.Width := 161;
    ConfirmationAccountLink.Height := 25;
    ConfirmationAccountLink.Caption := 'ConfirmationAccountLink';
    ConfirmationAccountLink.ChildOrder := 5;
    ConfirmationAccountLink.ElementClassName := 'btn btn-primary';
    ConfirmationAccountLink.ElementFont := efCSS;
    ConfirmationAccountLink.ElementPosition := epIgnore;
    ConfirmationAccountLink.HeightStyle := ssAuto;
    ConfirmationAccountLink.HeightPercent := 100.000000000000000000;
    ConfirmationAccountLink.WidthStyle := ssAuto;
    ConfirmationAccountLink.WidthPercent := 100.000000000000000000;
    SetEvent(ConfirmationAccountLink, Self, 'OnClick', 'ConfirmationAccountLinkClick');
    LinkTimer.SetParentComponent(Self);
    LinkTimer.Name := 'LinkTimer';
    LinkTimer.Enabled := False;
    LinkTimer.Interval := 3000;
    SetEvent(LinkTimer, Self, 'OnTimer', 'LinkTimerTimer');
    LinkTimer.Left := 88;
    LinkTimer.Top := 144;
  finally
    PaymentInstructions.AfterLoadDFMValues;
    ConfirmationMessage.AfterLoadDFMValues;
    ConfirmationAccountLink.AfterLoadDFMValues;
    LinkTimer.AfterLoadDFMValues;
  end;
end;

end.
