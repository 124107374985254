unit OptionalExtras;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseBooking,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  Booking.Web.Shared,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  Cyrus.Enumerations;

type
  TOptionalExtrasForm = class(TBaseBookingForm)
    BookingSource: TDataSource;
    NumberOfDaysBefore: TSpinEdit;
    NumberOfDaysAfter: TSpinEdit;
    ArrivalDate: TDBLabel;
    DepartureDate: TDBLabel;
    FirstRoomUpgrade: TDBEdit;
    HearAbout: TDBLookupComboBox;
    HearAboutSource: TDataSource;
    DogAllowed: TPanel;
    HasADog: TDBLookupComboBox;
    ExtraNightsGroup: TPanel;
    procedure HasADogChange(Sender: TObject);
    procedure HearAboutChange(Sender: TObject);
    procedure NumberOfDaysAfterChange(Sender: TObject);
    procedure NumberOfDaysBeforeChange(Sender: TObject);
    procedure TravelMethodChange(Sender: TObject);
  private
    { Private declarations }
  protected
    function GetBookingStateStage: TBookingState; override;
    procedure SetDisplayingAs(const Value: TBookingPage); override;
    function IsValid(const HighlightControls: Boolean = True): Boolean; override;
  public
    { Public declarations }
    function CanCloseBookingPage(const ANextPage: Boolean = True): TCanProgress; override;
    [async]
    procedure InitForm; async; override;
    [async]
    procedure LoadBooking; async; override;
    procedure SaveBooking; override;
    function NextPage: TBookingPage; override;
    function PreviousPage: TBookingPage; override;
  protected procedure LoadDFMValues; override; end;

var
  OptionalExtrasForm: TOptionalExtrasForm;

implementation

{$R *.dfm}

uses
  MainDataModule,
  SMX.Web.Utils, SMX.Web.Document.Utils;

function TOptionalExtrasForm.CanCloseBookingPage(const ANextPage: Boolean = True): TCanProgress;
begin
  if not ANextPage then
    Result := TCanProgress.ccImmediate
  else if IsValid then
    Result := TCanProgress.ccWait
  else
    Result := TCanProgress.ccNo;

end;

function TOptionalExtrasForm.GetBookingStateStage: TBookingState;
begin
  Result := TBookingState.OptionalExtrasComplete;
end;

  procedure TOptionalExtrasForm.HasADogChange(Sender: TObject);
  begin
    if not MainData.HotelDatasetDogsallowed.Value then
    begin
      DisplayMessage('We''re sorry , but dogs are not allowed on this holiday', TMsgDlgType.mtWarning);
      Exit;
    end;
    MainData.UpdateDogCost(HasADog.Value = 'True');
    UpdateHolidayBookingCosts;
  end;

procedure TOptionalExtrasForm.HearAboutChange(Sender: TObject);
begin
  UpdateMainForm(wbsEnabled, GetAButtonState(IsValid), Crumb_Optional_Extras);
end;

  procedure TOptionalExtrasForm.InitForm;
  var
    lWebButtonState: TButtonState;
    lDogAllowed: Boolean;
  begin
    inherited;

    HasADog.LookupValues.AddPair('True', 'Yes');
    HasADog.LookupValues.AddPair('False', 'No');

    lDogAllowed := MainData.HotelDatasetDogsallowed.Value;
    DogAllowed.Visible := lDogAllowed;

    MainData.HearAboutOptions.First;
    while not MainData.HearAboutOptions.Eof do
    begin
      HearAbout.LookupValues.AddPair(MainData.HearAboutOptionsHearAboutId.AsString,
        MainData.HearAboutOptionsDescription.Value);
      MainData.HearAboutOptions.Next;
    end;

    ExtraNightsGroup.Visible := MainData.HolidayDataExtraNightOption.Value <> Extra_Night_Option[TExtraNightOption.UnAvailable];


    if not(MainData.BookingDataset.State in dsEditModes) then
      MainData.BookingDataset.Edit;

    NumberOfDaysBefore.Value := MainData.BookingDatasetFirstRoomExtraNightsBefore.Value;
    NumberOfDaysAfter.Value := MainData.BookingDatasetFirstRoomExtraNightsAfter.Value;

    lWebButtonState := GetAButtonState(IsValid);
    UpdateMainForm(wbsEnabled, lWebButtonState, Crumb_Optional_Extras);

    UpdateHolidayBookingCosts;

  end;

  function TOptionalExtrasForm.IsValid(const HighlightControls: Boolean = True): Boolean;
  var ControlValid: Boolean;
  begin
    Result := inherited IsValid;
    ControlValid := MainData.BookingDatasetWheredidyouhearaboutus.Value > 0;
    Result := Result and ControlValid;
    if HighlightControls then
       TDocUtils.setControlValidity(HearAbout.ElementID, Valid_Check[ControlValid]);

  end;

  procedure TOptionalExtrasForm.LoadBooking;
  begin
    inherited;
    MainData.EditDataset(BookingSource.DataSet);
  end;

  function TOptionalExtrasForm.NextPage: TBookingPage;
  begin
    Result := TBookingPage.bpReview;
  end;

  procedure TOptionalExtrasForm.NumberOfDaysAfterChange(Sender: TObject);
  begin
    if PageLoading then
      Exit;
    MainData.PostDepatureDays(NumberOfDaysAfter.Value);
    UpdateHolidayBookingCosts;
  end;

  procedure TOptionalExtrasForm.NumberOfDaysBeforeChange(Sender: TObject);
  begin
    if PageLoading then
      Exit;
    MainData.PreArrivalDays(NumberOfDaysBefore.Value);
    UpdateHolidayBookingCosts;
  end;

  function TOptionalExtrasForm.PreviousPage: TBookingPage;
  begin
    if (MainData.HolidayBooking.GuestCount = 2) then
      Result := TBookingPage.bpGuestRequirements
    else
      Result := TBookingPage.bpCustomerRequirements;

  end;

  procedure TOptionalExtrasForm.SaveBooking;
  begin
    inherited;
  end;

  procedure TOptionalExtrasForm.SetDisplayingAs(const Value: TBookingPage);
  begin
    inherited;

  end;

  procedure TOptionalExtrasForm.TravelMethodChange(Sender: TObject);
  var
    lWebButtonState: TButtonState;
  begin
    lWebButtonState := GetAButtonState(IsValid);
    UpdateMainForm(wbsEnabled, lWebButtonState, Crumb_Optional_Extras);
  end;

procedure TOptionalExtrasForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ArrivalDate := TDBLabel.Create('ArrivalDate');
  DepartureDate := TDBLabel.Create('DepartureDate');
  FirstRoomUpgrade := TDBEdit.Create('FirstRoomUpgrade');
  HearAbout := TDBLookupComboBox.Create('HearAbout');
  DogAllowed := TPanel.Create('DogAllowed');
  HasADog := TDBLookupComboBox.Create('HasDog');
  ExtraNightsGroup := TPanel.Create('ExtraNightsGroup');
  NumberOfDaysAfter := TSpinEdit.Create('NumberOfDaysAfter');
  NumberOfDaysBefore := TSpinEdit.Create('NumberOfDaysBefore');
  BookingSource := TDataSource.Create(Self);
  HearAboutSource := TDataSource.Create(Self);

  ArrivalDate.BeforeLoadDFMValues;
  DepartureDate.BeforeLoadDFMValues;
  FirstRoomUpgrade.BeforeLoadDFMValues;
  HearAbout.BeforeLoadDFMValues;
  DogAllowed.BeforeLoadDFMValues;
  HasADog.BeforeLoadDFMValues;
  ExtraNightsGroup.BeforeLoadDFMValues;
  NumberOfDaysAfter.BeforeLoadDFMValues;
  NumberOfDaysBefore.BeforeLoadDFMValues;
  BookingSource.BeforeLoadDFMValues;
  HearAboutSource.BeforeLoadDFMValues;
  try
    Width := 696;
    Height := 550;
    ArrivalDate.SetParentComponent(Self);
    ArrivalDate.Name := 'ArrivalDate';
    ArrivalDate.Left := 120;
    ArrivalDate.Top := 304;
    ArrivalDate.Width := 70;
    ArrivalDate.Height := 18;
    ArrivalDate.Caption := 'ArrivalDate';
    ArrivalDate.ElementFont := efCSS;
    ArrivalDate.ElementPosition := epIgnore;
    ArrivalDate.HeightStyle := ssAuto;
    ArrivalDate.HeightPercent := 100.000000000000000000;
    ArrivalDate.WidthStyle := ssAuto;
    ArrivalDate.WidthPercent := 100.000000000000000000;
    ArrivalDate.DataField := 'ArrivalDate';
    ArrivalDate.DataSource := BookingSource;
    DepartureDate.SetParentComponent(Self);
    DepartureDate.Name := 'DepartureDate';
    DepartureDate.Left := 120;
    DepartureDate.Top := 328;
    DepartureDate.Width := 96;
    DepartureDate.Height := 18;
    DepartureDate.Caption := 'DepartureDate';
    DepartureDate.ElementFont := efCSS;
    DepartureDate.ElementPosition := epIgnore;
    DepartureDate.HeightStyle := ssAuto;
    DepartureDate.HeightPercent := 100.000000000000000000;
    DepartureDate.WidthStyle := ssAuto;
    DepartureDate.WidthPercent := 100.000000000000000000;
    DepartureDate.DataField := 'DepartureDate';
    DepartureDate.DataSource := BookingSource;
    FirstRoomUpgrade.SetParentComponent(Self);
    FirstRoomUpgrade.Name := 'FirstRoomUpgrade';
    FirstRoomUpgrade.Left := 120;
    FirstRoomUpgrade.Top := 400;
    FirstRoomUpgrade.Width := 209;
    FirstRoomUpgrade.Height := 22;
    FirstRoomUpgrade.ChildOrder := 6;
    FirstRoomUpgrade.ElementClassName := 'form-control';
    FirstRoomUpgrade.ElementFont := efCSS;
    FirstRoomUpgrade.ElementPosition := epIgnore;
    FirstRoomUpgrade.HeightStyle := ssAuto;
    FirstRoomUpgrade.HeightPercent := 100.000000000000000000;
    FirstRoomUpgrade.TabOrder := 4;
    FirstRoomUpgrade.Text := 'FirstRoomUpgrade';
    FirstRoomUpgrade.WidthStyle := ssAuto;
    FirstRoomUpgrade.WidthPercent := 100.000000000000000000;
    FirstRoomUpgrade.DataField := 'Firstroomupgrade';
    FirstRoomUpgrade.DataSource := BookingSource;
    HearAbout.SetParentComponent(Self);
    HearAbout.Name := 'HearAbout';
    HearAbout.Left := 352;
    HearAbout.Top := 325;
    HearAbout.Width := 145;
    HearAbout.Height := 22;
    HearAbout.ElementClassName := 'form-select';
    HearAbout.ElementFont := efCSS;
    HearAbout.ElementPosition := epIgnore;
    HearAbout.HeightStyle := ssAuto;
    HearAbout.HeightPercent := 100.000000000000000000;
    HearAbout.TabOrder := 6;
    HearAbout.WidthStyle := ssAuto;
    HearAbout.WidthPercent := 100.000000000000000000;
    SetEvent(HearAbout, Self, 'OnChange', 'HearAboutChange');
    HearAbout.DataField := 'Wheredidyouhearaboutus';
    HearAbout.DataSource := BookingSource;
    HearAbout.ListSource := MainData.DummyLookUpSource;
    DogAllowed.SetParentComponent(Self);
    DogAllowed.Name := 'DogAllowed';
    DogAllowed.Left := 368;
    DogAllowed.Top := 416;
    DogAllowed.Width := 249;
    DogAllowed.Height := 60;
    DogAllowed.ElementClassName := 'card';
    DogAllowed.HeightStyle := ssAuto;
    DogAllowed.WidthStyle := ssAuto;
    DogAllowed.BorderColor := clNone;
    DogAllowed.BorderStyle := bsNone;
    DogAllowed.ChildOrder := 11;
    DogAllowed.Color := clWindow;
    DogAllowed.ElementBodyClassName := 'card-body';
    DogAllowed.ElementFont := efCSS;
    DogAllowed.ElementPosition := epIgnore;
    DogAllowed.TabOrder := 2;
    DogAllowed.Visible := False;
    HasADog.SetParentComponent(DogAllowed);
    HasADog.Name := 'HasADog';
    HasADog.Left := 16;
    HasADog.Top := 14;
    HasADog.Width := 145;
    HasADog.Height := 22;
    HasADog.ElementClassName := 'form-select';
    HasADog.ElementFont := efCSS;
    HasADog.ElementPosition := epIgnore;
    HasADog.HeightStyle := ssAuto;
    HasADog.HeightPercent := 100.000000000000000000;
    HasADog.TabOrder := 2;
    HasADog.WidthStyle := ssAuto;
    HasADog.WidthPercent := 100.000000000000000000;
    SetEvent(HasADog, Self, 'OnChange', 'HasADogChange');
    HasADog.DataField := 'HasDog';
    HasADog.DataSource := BookingSource;
    HasADog.ListSource := MainData.DummyLookUpSource;
    ExtraNightsGroup.SetParentComponent(Self);
    ExtraNightsGroup.Name := 'ExtraNightsGroup';
    ExtraNightsGroup.Left := 48;
    ExtraNightsGroup.Top := 216;
    ExtraNightsGroup.Width := 249;
    ExtraNightsGroup.Height := 82;
    ExtraNightsGroup.ElementClassName := 'card';
    ExtraNightsGroup.HeightStyle := ssAuto;
    ExtraNightsGroup.WidthStyle := ssAuto;
    ExtraNightsGroup.BorderColor := clNone;
    ExtraNightsGroup.BorderStyle := bsNone;
    ExtraNightsGroup.ChildOrder := 11;
    ExtraNightsGroup.Color := clWindow;
    ExtraNightsGroup.ElementBodyClassName := 'card-body';
    ExtraNightsGroup.ElementFont := efCSS;
    ExtraNightsGroup.ElementPosition := epIgnore;
    ExtraNightsGroup.TabOrder := 4;
    ExtraNightsGroup.Visible := False;
    NumberOfDaysAfter.SetParentComponent(ExtraNightsGroup);
    NumberOfDaysAfter.Name := 'NumberOfDaysAfter';
    NumberOfDaysAfter.Left := 3;
    NumberOfDaysAfter.Top := 33;
    NumberOfDaysAfter.Width := 150;
    NumberOfDaysAfter.Height := 25;
    NumberOfDaysAfter.ElementClassName := 'form-control';
    NumberOfDaysAfter.HeightStyle := ssAuto;
    NumberOfDaysAfter.WidthStyle := ssAuto;
    NumberOfDaysAfter.AutoSize := False;
    NumberOfDaysAfter.BorderStyle := bsSingle;
    NumberOfDaysAfter.ChildOrder := 1;
    NumberOfDaysAfter.Color := clWhite;
    NumberOfDaysAfter.ElementFont := efCSS;
    NumberOfDaysAfter.ElementPosition := epIgnore;
    NumberOfDaysAfter.Increment := 1;
    NumberOfDaysAfter.MaxValue := 100;
    NumberOfDaysAfter.MinValue := 0;
    NumberOfDaysAfter.Role := '';
    NumberOfDaysAfter.TabOrder := 1;
    NumberOfDaysAfter.Value := 0;
    SetEvent(NumberOfDaysAfter, Self, 'OnChange', 'NumberOfDaysAfterChange');
    NumberOfDaysBefore.SetParentComponent(ExtraNightsGroup);
    NumberOfDaysBefore.Name := 'NumberOfDaysBefore';
    NumberOfDaysBefore.Left := 3;
    NumberOfDaysBefore.Top := 3;
    NumberOfDaysBefore.Width := 150;
    NumberOfDaysBefore.Height := 24;
    NumberOfDaysBefore.ElementClassName := 'form-control';
    NumberOfDaysBefore.HeightStyle := ssAuto;
    NumberOfDaysBefore.WidthStyle := ssAuto;
    NumberOfDaysBefore.AutoSize := False;
    NumberOfDaysBefore.BorderStyle := bsSingle;
    NumberOfDaysBefore.ChildOrder := 1;
    NumberOfDaysBefore.Color := clWhite;
    NumberOfDaysBefore.ElementFont := efCSS;
    NumberOfDaysBefore.ElementPosition := epIgnore;
    NumberOfDaysBefore.Increment := 1;
    NumberOfDaysBefore.MaxValue := 100;
    NumberOfDaysBefore.MinValue := 0;
    NumberOfDaysBefore.Role := '';
    NumberOfDaysBefore.Value := 0;
    SetEvent(NumberOfDaysBefore, Self, 'OnChange', 'NumberOfDaysBeforeChange');
    BookingSource.SetParentComponent(Self);
    BookingSource.Name := 'BookingSource';
    BookingSource.DataSet := MainData.BookingDataset;
    BookingSource.Left := 304;
    BookingSource.Top := 112;
    HearAboutSource.SetParentComponent(Self);
    HearAboutSource.Name := 'HearAboutSource';
    HearAboutSource.DataSet := MainData.HearAboutOptions;
    HearAboutSource.Left := 408;
    HearAboutSource.Top := 112;
  finally
    ArrivalDate.AfterLoadDFMValues;
    DepartureDate.AfterLoadDFMValues;
    FirstRoomUpgrade.AfterLoadDFMValues;
    HearAbout.AfterLoadDFMValues;
    DogAllowed.AfterLoadDFMValues;
    HasADog.AfterLoadDFMValues;
    ExtraNightsGroup.AfterLoadDFMValues;
    NumberOfDaysAfter.AfterLoadDFMValues;
    NumberOfDaysBefore.AfterLoadDFMValues;
    BookingSource.AfterLoadDFMValues;
    HearAboutSource.AfterLoadDFMValues;
  end;
end;

end.
