unit DTO_Payments;

interface

{$IFDEF PAS2JS}

uses
  JS;
{$ENDIF}

type
  THolidayPayment = class
    PayerID: string;
    BookingID: string;
    PaymentID: string;
    OrderID: string;
    State: string;
    Email: string;
    CustomText: string;
    InvoiceNumber: string;
    LastName: string;
    // FirstName: string; //why is this the only InvalidJsonProperty??? BECAUSE IT STARTS WITH F
    GivenName: string;
    PostalCode: string;
    PaymentState: string;
    City: string;
    CountryCode: string;
    Phone: string;
    Address2: string;
    Address1: string;
    RecipientName: string;
    TotalAmount: Double;
    SaleID: string;
    Description: string;
    CurrencyCode: string;
    Error: string;
    ErrorDetails: string;
  end;

type
  THolidayOrder = class
    id: string;
    token: string;
    State: string;
    created_at: string;
    updated_at: string;
    amount: Double;
    CurrencyCode: string;
    outstanding_amount: Double;
    capture_mode: string;
    checkout_url: string;
    {$IFDEF PAS2JS}
    procedure Load(AHolidayOrder: TJSObject);
    {$ENDIF}
  end;
  {
    "id": "662b0870-44f5-ae0f-89dd-620a9ded3eab",
    "token":"cb3d5ae7-3159-43ee-9583-5a03ca0a3432",
    "type":"payment",
    "state":"pending",
    "created_at":"2024-04-26T01:50:40.625471Z",
    "updated_at":"2024-04-26T01:50:40.625471Z",
    "amount":100,
    "currency":"GBP",
    "outstanding_amount":100,
    "capture_mode":"automatic",
    "checkout_url":"https://sandbox-checkout.revolut.com/payment-link/cb3d5ae7-3159-43ee-9583-5a03ca0a3432", "enforce_challenge":"automatic"
  }

implementation

{ THolidayOrder }

{$IFDEF PAS2JS}
procedure THolidayOrder.Load(AHolidayOrder: TJSObject);
begin
  id := string(AHolidayOrder['id']);
  token := string(AHolidayOrder['token']);
  State := string(AHolidayOrder['State']);
  created_at := string(AHolidayOrder['created_at']);
  updated_at := string(AHolidayOrder['updated_at']);
  amount := Currency(AHolidayOrder['amount']);
  CurrencyCode := string(AHolidayOrder['CurrencyCode']);
  outstanding_amount := Currency(AHolidayOrder['outstanding_amount']);
  capture_mode := string(AHolidayOrder['capture_mode']);
  checkout_url := string(AHolidayOrder['checkout_url']);
end;
{$ENDIF}

end.
