unit AccountPayConfirmation;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, WEBLib.WebCtrls, Cyrus.Enumerations, Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TAccountPayConfirmationPage = class(TCoreWebForm)
    PaymentInstructions: THTMLSpan;
    ConfirmationCloseButton: TButton;
    procedure ConfirmationCloseButtonClick(Sender: TObject);
  private
    FAmountToPay: Double;
    FPaymentProvider: TPaymentProvider;
    procedure SetPaymentProvider(const Value: TPaymentProvider);
    procedure SetAmountToPay(const Value: Double);
    { Private declarations }
  public
    { Public declarations }
    property PaymentProvider: TPaymentProvider write SetPaymentProvider;
    property AmountToPay: Double read FAmountToPay write SetAmountToPay;
  protected procedure LoadDFMValues; override; end;

var
  AccountPayConfirmationPage: TAccountPayConfirmationPage;

implementation

{$R *.dfm}

uses MainDataModule;

procedure TAccountPayConfirmationPage.ConfirmationCloseButtonClick(Sender:
    TObject);
begin
  ModalResult := mrOk;
end;

{ TForm1 }

procedure TAccountPayConfirmationPage.SetAmountToPay(const Value: Double);
begin
  FAmountToPay := Value;
end;

procedure TAccountPayConfirmationPage.SetPaymentProvider(const Value: TPaymentProvider);
begin
  FPaymentProvider := Value;
  case FPaymentProvider of
    TPaymentProvider.PayPal:
      PaymentInstructions.HTML.Text := 'Many thank for paying with PayPal';
    TPaymentProvider.Revolut:
      PaymentInstructions.HTML.Text := 'Many thanks for paying by Credit Card';
    TPaymentProvider.BACS:
    begin
      PaymentInstructions.HTML.Text := Format(
        'Please make your BACS payment for <b>&pound;%s</b> to: <br />Account Name: %s<br /><br />Account Number: %s<br /><br />Sort Code: %s<br /><br />IBAN: %s<br /><br />SWIFT: %s<br />',
        [FormatFloat('#,##0.00', FAmountToPay),
        MainData.BankDetails.AccountName, MainData.BankDetails.AccountNumber, MainData.BankDetails.SortCode, MainData.BankDetails.IBAN, MainData.BankDetails.Swift]);
    end;
    TPaymentProvider.Cheque:
      PaymentInstructions.HTML.Text :=
        'Please make your cheque for &pound;' + FormatFloat('#,##0.00', FAmountToPay) + ' payable to <b>' + MainData.BankDetails.TradingName + '</b> and send it to<br />' +
        MainData.MailingAddress.AsHTML;
  end;
end;

procedure TAccountPayConfirmationPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PaymentInstructions := THTMLSpan.Create('PaymentInstructions');
  ConfirmationCloseButton := TButton.Create('ConfirmationCloseButton');

  PaymentInstructions.BeforeLoadDFMValues;
  ConfirmationCloseButton.BeforeLoadDFMValues;
  try
    PaymentInstructions.SetParentComponent(Self);
    PaymentInstructions.Name := 'PaymentInstructions';
    PaymentInstructions.Left := 119;
    PaymentInstructions.Top := 56;
    PaymentInstructions.Width := 417;
    PaymentInstructions.Height := 273;
    PaymentInstructions.HeightStyle := ssAuto;
    PaymentInstructions.WidthStyle := ssAuto;
    PaymentInstructions.ChildOrder := 1;
    PaymentInstructions.ElementPosition := epIgnore;
    PaymentInstructions.ElementFont := efCSS;
    PaymentInstructions.Role := '';
    ConfirmationCloseButton.SetParentComponent(Self);
    ConfirmationCloseButton.Name := 'ConfirmationCloseButton';
    ConfirmationCloseButton.Left := 480;
    ConfirmationCloseButton.Top := 384;
    ConfirmationCloseButton.Width := 96;
    ConfirmationCloseButton.Height := 25;
    ConfirmationCloseButton.Caption := 'Close';
    ConfirmationCloseButton.ChildOrder := 1;
    ConfirmationCloseButton.ElementClassName := 'btn btn-primary';
    ConfirmationCloseButton.ElementFont := efCSS;
    ConfirmationCloseButton.ElementPosition := epIgnore;
    ConfirmationCloseButton.HeightStyle := ssAuto;
    ConfirmationCloseButton.HeightPercent := 100.000000000000000000;
    ConfirmationCloseButton.WidthStyle := ssAuto;
    ConfirmationCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(ConfirmationCloseButton, Self, 'OnClick', 'ConfirmationCloseButtonClick');
  finally
    PaymentInstructions.AfterLoadDFMValues;
    ConfirmationCloseButton.AfterLoadDFMValues;
  end;
end;

end.